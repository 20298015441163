import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/thread-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../node_modules/resolve-url-loader/index.js!../../../node_modules/thread-loader/dist/cjs.js??ruleSet[1].rules[2].use[4]!../../../node_modules/sass-loader/dist/cjs.js!./pharmacy-holidays.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};