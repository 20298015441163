import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useRef, useState } from 'react';
import {
  Row, Col, Modal, Button, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducers';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getDashboardSections, getSectionEmbedUrls, unPinSectionReport } from '@http/requests/dashboard';

import styles from './dashboard.scss';
import globalStyle from '../../common/styles/globalStyle.scss';

export const Dashboard = () => {
  const tooltip = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [config, setConfig] = useState({});
  const [reports, setReports] = useState([]);
  const [badReports, setBadReports] = useState([]);
  const { sectionID } = useParams();
  const [show, setShow] = useState(false);
  const [unpinnedId, setUnpinId] = useState('');
  const [ sectionIdFirstPart, setSectionIdFirstPart ] = useState('');
  const [ sectionIdRemainingPart, setSectionIdRemainingPart ] = useState('');
  const role: string = useSelector(({ USER }: RootState) =>
    USER.userRole) || 'User';

  useEffect(() => {
    if (history.location.pathname.split('/').length == 3 && history.location.pathname.split('/')[1] == 'dashboard') {

    } else {
      // if(store.USER.userRole&&store.USER.userRole!='NA'){
      getDashboardSections().then((res) => {
        if (res[0] && res[0].sectionID) {
          history.push('/dashboard/' + res[0].sectionID + '_' + res[0].sectionName);
        } else {
          // history.push('/dashboard/0_nosectionfound');
          history.push('/reports');
        }
      }).catch((res) => {
        console.log(res);
        history.push('/reports');
      });
      // }
    }
  }, []);

  useEffect(() => {

    if (sectionID) {
      const firstUnderscoreIndex = sectionID.indexOf('_');
      const firstUnderscorePart = sectionID.slice(0, firstUnderscoreIndex);
      const secondUnderscorePart = sectionID.slice(firstUnderscoreIndex + 1);
      setSectionIdFirstPart(firstUnderscorePart);
      setSectionIdRemainingPart(secondUnderscorePart);
      getDashboardSections().then((res) => {
        const sections:any=res;
      
        const filtered = res.filter(r => r.sectionName == secondUnderscorePart);
        if (filtered.length > 0) {
          getSectionEmbedUrls(firstUnderscorePart).then((res) => {
            if(res.length > 0){
              let reports: any = [];
              let badReports: any = [];
              res.forEach((report:any)=>{
                if(report.error){
                  badReports.push(report)
                } else{
                  reports.push(report)
                }
              })
              
              setReports(reports);
              setBadReports(badReports);
            }
            else{
              if (sections.length>1 &&  sections[0] && sections[0].sectionID ) {
                if(sections[0].sectionID!= firstUnderscorePart){
                  history.push('/dashboard/' + sections[0].sectionID + '_' + sections[0].sectionName);
                }
                else{
                  history.push('/dashboard/' + sections[1].sectionID + '_' + sections[1].sectionName);
                }
                
              } else {
                // history.push('/dashboard/0_nosectionfound');
                history.push('/reports');
              }
            }
              
          });
        } else {
          if (res[0] && res[0].sectionID) {
            history.push('/dashboard/' + res[0].sectionID + '_' + res[0].sectionName);
          } else {
            // history.push('/dashboard/0_nosectionfound');
            history.push('/reports');
          }
        }
      }).catch((res) => {
        console.log(res);
        history.push('/reports');
      });
      setReports([]);
      setBadReports([]);
    }
  }, [sectionID]);

  const renderTooltip = (props: any) =>
  (
    <Tooltip className={styles.tooltip} {...props}>
      <div>
        Remove the report from Dashboard
      </div>
    </Tooltip>
  );

  const unpin = (id: any) => {
    setUnpinId(id);
    setShow(true);
  };
  const openPowerBI = (id: any) => {
    history.push('/report/' + id);
  };
  const handleClose = () => {
    setUnpinId('');
    setShow(false);
  };


  const unpinById = () => {
    if (unpinnedId) {
      setShow(false);
      unPinSectionReport(unpinnedId).then(() => {
        getSectionEmbedUrls(sectionIdFirstPart).then((res) => {
          let reports: any = [];
          let badReports: any = [];
          res.forEach((report:any)=>{
            if(report.error){
              badReports.push(report)
            } else{
              reports.push(report)
            }
          })
          setReports(reports);
          setBadReports(badReports);
          if(reports.length==0){
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  return (
    sectionID ? (
      <>
        <div className={styles.dashboard}>
          <Row>
            <Col>
            {
              sectionIdRemainingPart=='nosectionfound'?
              <p className={styles.section_name}>No dashboard found. Please create new dashboard.</p>:
              <p className={styles.section_name}>{sectionIdRemainingPart}</p>
            }
            </Col>
          </Row>
          <Row>
            {
              badReports.length?
              <div>
              <p className={styles.bad_request_heading}>Following reports are not available. Please contact system admin</p>
              <ul>
                {
                  badReports.map((r,i)=>(
                    <li key={i} className={styles.bad_request}>{r.reportName} - {r.departmentName} &nbsp;&nbsp;
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        // container={tooltip}
                        overlay={renderTooltip}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          <img
                            onClick={() =>
                              unpin(r.sectionReportID)}
                            className={styles.icon}
                            src={require('@icons/close.svg')}
                            alt=""
                          />
                        </span>
                      </OverlayTrigger>
                    </li>
                  ))
                }
              </ul>
              </div>
              :null
            }
          </Row>
          <Row>
            {
              reports.map((r,i) =>
              (
                <Col key={i} sm={6}>
                  <div className={styles.card}>
                    <div className={styles.card_head}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          openPowerBI(r.departmentReportID)}
                        className={styles.name}
                      >{r.embedUrlData.embedReports[0].reportName} - {r.departmentName}</div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        // container={tooltip}
                        overlay={renderTooltip}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          <img
                            onClick={() =>
                              unpin(r.sectionReportID)}
                            className={styles.icon}
                            src={require('@icons/close.svg')}
                            alt=""
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div style={{ height: '100%' }} >
                      <PowerBIEmbed
                        embedConfig={{
                          type: 'report', // Supported types: report, dashboard, tile, visual and qna
                          id: r.embedUrlData.embedReports[0].reportId,
                          embedUrl: r.embedUrlData.embedReports[0].embedUrl,
                          accessToken: r.embedUrlData.embedToken.token, // Keep as empty string, null or undefined
                          tokenType: models.TokenType.Embed,
                        }}
                        cssClassName={styles.powerbi}
                      />
                    </div>
                  </div>
                </Col>
              ))
            }

          </Row>
        </div>
        <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Unpin Report</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modal_body}>
            <div className={styles.modal_body}>
              <div>
                <img src={require('../../common/icons/warning.svg')} alt="" />
              </div>
              <div className={styles.modal_text}>
                Are you sure you want to remove this report from the dashboard ?
              </div>
            
            </div>
          </Modal.Body>

          <Modal.Footer bsPrefix={globalStyle.footerDelete}>
            <div>
              <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
            </div>
            <div>
              <Button onClick={unpinById} className={globalStyle.footerAdd_btn} variant="primary">
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
      :
      <h1>No section found</h1>




  );
};
