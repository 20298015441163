import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

export const getAllException = (id: number, ndc: string, type: number): Promise<any> =>

httpRequest({
    url: `${Setting.ItemMaster}/getAllException`,
    method: HttpMethod.POST,
    data: {
        type: type,
        programId: id,
        ndc: ndc,
    }
});

export const addException = (payload: any): Promise<any> =>

httpRequest({
    url: `${Setting.ItemMaster}/addException`,
    method: HttpMethod.POST,
    data: payload
});

export const deleteException = (id: any): Promise<any> =>

httpRequest({
    url: `${Setting.ItemMaster}/deleteException/${id}`,
    method: HttpMethod.DELETE
});