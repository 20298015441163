import {
  ACCESS_TOKEN,
  ACCOUNT,
  EXPIRES_ON,
  TIME_OF_AUTO_LOGOUT
} from '@constants';

import { removeLocalStorageItem, setLocalStorageItem } from './local-storage';


export const cleanAccessData = () => {
  removeLocalStorageItem(ACCESS_TOKEN);
  removeLocalStorageItem(ACCOUNT);
  removeLocalStorageItem(EXPIRES_ON);
  removeLocalStorageItem(TIME_OF_AUTO_LOGOUT);
};

export const setTimeOfAutoLogout = () => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + +window?.env?.LOGOUT_AFTER_MINUTES || 5);

  setLocalStorageItem(TIME_OF_AUTO_LOGOUT, String(date.getTime()));
};
