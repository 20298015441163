import { Action } from "redux";

interface ItemMasterProgramObj {
    programId: number | null;
    itemName: string;
    ndc: string;
}
  
interface SetItemMasterProgramAction extends Action<"SET_ITEMMASTERPROGRAM"> {
    payload: ItemMasterProgramObj;
}
  
export const setItemMasterProgram = (programObj: ItemMasterProgramObj): SetItemMasterProgramAction => ({
    type: "SET_ITEMMASTERPROGRAM",
    payload: programObj,
});
