import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Form, Button, InputGroup, Modal
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { ClientModal } from '@components/modals/client-modal';
import { USER_ROLE } from '@constants';

import {
  getClientList, addClient,
  editClient, deleteClient,
  getActiveUsersList, genericRecordStatus
} from './../../../http/requests';
import styles from './client.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

function Client({ activeTab, roleChange }) {
  const [ modalShow, setModalShow ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ departmentList, setDepartmentList ] = useState([]);
  const [ _departmentList, set_departmentList ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ showUpdatePopup, setShowUpdatePopup ] = useState(false);
  const [ updateData, setupdateData ] = useState(null);
  const [ deleteId, setDeleteId ] = useState('');
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ globalSearchField, setGlobalSearch ] = useState('');



  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);
  
  useEffect(() => {

      setIsAdmin((role == 'Super Admin') ? true : false);
      if(activeTab==='client'){
        fetchDepartmentList();
      }
      // getActiveUsersList().then((res: any) => {
      //   setActiceUserList(res);
      // });
  }, [activeTab, roleChange]);


  useEffect(()=>{
    if(globalSearchField){
      globalSearch(globalSearchField);
    }
  },[_departmentList]);

  useEffect(() => {
    if (activeTab != 'client') {
      setGlobalSearch('');
      setSearchboxError('');
      globalSearch('');
    }
  }, [ activeTab ]);

  const customTotal = (from: any, to: any, size: any) =>
    (
      <span style={{ marginLeft: '20px' }} className="react-bootstrap-table-pagination-total">
        {from} - {to} of {size}
      </span>
    );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }: {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any
  }) =>
    (
      <span className={styles.listPage}>
        <span>Rows per page &nbsp;&nbsp;
          {
          (
            <Form.Select
              className={styles.perPageSelect}
              onChange={e =>
                onSizePerPageChange(e.target.value)}
              aria-label="Default select example"
            >
              {
                options.map((val: any) =>
                  <option key={val.text} value={val.page}>{val.text}</option>)
              }
            </Form.Select>
          )
        }

        </span>
      </span>
    );


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always deleteId next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    // firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    // lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    // pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageRenderer,
    sizePerPageList: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 } ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const isEnable = (row: any) => {
    const style: any = {};
    if (!row.active) {
      style.pointerEvents = 'none';
    }
    return style;
  };

  const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) =>
    (
      <div className={isAdmin ? styles.table_actions : styles.table_actions2}>
        <div
          style={isEnable(row)}
          onClick={() =>
          view(row)}
        >
          {row.active ? <img src={require('../../../common/icons/view.svg')} alt="" /> :
          <img src={require('../../../common/icons/view-dull.svg')} alt="" />}
        </div>
        {
        isAdmin ? (
          <>
            <div
              style={isEnable(row)}
              onClick={() =>
              edit(row)}
            >
              {row.active ? <img src={require('../../../common/icons/pencil.svg')} alt="" /> :
              <img src={require('../../../common/icons/edit-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
              copyDepartment(row)}
            >
              {row.active ? <img src={require('../../../common/icons/copy.svg')} alt="" /> :
              <img src={require('../../../common/icons/copy-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
              deleteClientItem(row.id)}
            >
              {row.active ? <img src={require('../../../common/icons/delete.svg')} alt="" /> :
              <img src={require('../../../common/icons/delete-dull.svg')} alt="" />}
            </div>
            <div>
              <Form.Check
                className={styles.table_actions_switch}
                checked={row.active}
                type="switch"
                id="custom-switch"
                onChange={e =>
                activeDepartment(e.target.checked, row)}
              />
            </div>
          </>
) : null
      }

      </div>
    );

  const columns = [
    {
      dataField: 'clientName',
      text: 'CLIENT NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12em' },
    },
    {
      dataField: 'clientPartner',
      text: 'CLIENT PARTNER/S',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
    },
    {
      style: { width: '12em' },
      dataField: 'action',
      text: 'ACTION',
      formatter: linkFollow,
      sort: true,
      headerAlign: 'center',
    },
  ];

  const rowStyle2 = (row: any, rowIndex: any) => {
    const style: any = {};
    if (!row.active) {
      style.color = 'rgba(114, 114, 114, 0.3)';
      //   style.pointerEvents = 'none'
    }

    return style;
  };

  const fetchDepartmentList = () => {
    getClientList(role).then(((res: any) => {
      const data: any = [];
      res.forEach((r: any) => {
        let name = '';
        const clientPartnerList: any = [];
        r.clientPartnerList.forEach((d: any) =>{
          [
            name += d.partnerName + ', ',
          ]
          clientPartnerList.push({...d,userType:''});
        });
        if (name.length > 0) {
          name = name.substring(0, name.length - 2);
        }
        if(name=="undefined"){
          name = "No Client Partner exists for the Client";
        }
        data.push({
          ...r,
          clientPartner: name,
          clientPartnerList: clientPartnerList,
        });
      });

      set_departmentList(_.cloneDeep(data));
      setDepartmentList(_.cloneDeep(data));
    })).catch((err)=>{
      console.log(err);
    });
  };

  const submit = (data: any) => {
    setModalShow(false);
    if (data.type == 'add' || data.type == 'copy') {
      setGlobalSearch('');
      setSearchboxError('');
      addClient(data.modalData).then((res) => {
        setShowSuccess(true);
        data.type == 'add' ? setShowSuccessMsg('Client Created Successfully') : setShowSuccessMsg('Client Copied Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    } else if (data.type == 'update') {
      setupdateData(data);
      setShowUpdatePopup(true);
    }
  };

  const view = (currentRow: any) => {
    setModalData({
      header: 'View Client',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const activeDepartment = (value: any, department: any) => {
    const data = {
      recordType: 'Client',
      status: value,
    };
    genericRecordStatus(department.id, data).then(() => {
      getClientList(role).then(((res: any) => {
        const data: any = [];
        res.forEach((r: any) => {
          let name = '';
          r.clientPartnerList.forEach((d: any) =>
            [
              name += d.partnerName + ', ',
            ]);
          if (name.length > 0) {
            name = name.substring(0, name.length - 2);
          }
          if(name=="undefined"){
            name = "No Client Partner exists for the Client";
          }
          data.push({
            ...r,
            clientPartner: name,
          });
        });
        set_departmentList(_.cloneDeep(data));
        setDepartmentList(_.cloneDeep(data));
        // setTimeout(()=>{        
        //   setGlobalSearch('');
        //   setSearchboxError('');
        //   globalSearch('')
        // }, 1000);
      })).catch((err)=>{
        console.log(err);
      });
    })
    .catch((error) => {
      // Handle the error here
      fetchDepartmentList();
    });
  };

  const copyDepartment = (currentRow: any) => {
    setModalData({
      header: 'Copy Client',
      currentRow,
      submit,
    });
    setModalShow(true);
  };
  const add = () => {
    setModalData({
      header: 'Add Client',
      submit,
    });
    setModalShow(true);
  };
  const edit = (currentRow: any) => {
    setModalData({
      header: 'Edit Client',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const deleteClientItem = (id: string) => {
    setDeleteId(id);
    setShow(true);
  };

  const deleteClientById = () => {
    if (deleteId) {
      setShow(false);
      setGlobalSearch('');
      setSearchboxError('');
      deleteClient(deleteId).then((res) => {
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }
  };

  const updateOnPopup = ()=>{
    setShowUpdatePopup(false);
    if(updateData && updateData.id){
      setGlobalSearch('');
      setSearchboxError('');
      editClient(updateData.modalData, updateData.id).then((res) => {
        setShowSuccess(true);
        setShowSuccessMsg('Client Updated Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }
  };

  const globalSearch = (input: string) => {
    setGlobalSearch(input);
    const departmentListCopy = _.cloneDeep(_departmentList);
    if (input) {
      const list = departmentListCopy.filter((item: any) => {
        if (item.clientName.toLowerCase().includes(input.toLowerCase()) ||
          item.clientPartner.toLowerCase().includes(input.toLowerCase())) {
          return item;
        }
      });
      if (list.length == 0) {
        setSearchboxError(input + ' does not exist.');
      } else {
        setSearchboxError('');
      }
      setDepartmentList(list);
    } else {
      setSearchboxError('');
      setDepartmentList(departmentListCopy);
    }
  };

  const handleClose = () =>
    setShow(false);

  const handleUpdateClose = () => 
    setShowUpdatePopup(false);
  const handleSuccessClose = () =>
    setShowSuccess(false);

  return (
    <>
      <div id={styles.department}>
        <ClientModal
          data={modalData}
          show={modalShow}
          activeTab={activeTab}
          onHide={() =>
            setModalShow(false)}
        />
        <div className={styles.filter}>
          <div className={styles.search}>
            <InputGroup>
              <InputGroup.Text className={styles.search_logo}>
                <img src={require('../../../common/images/search.png')} alt="" />
              </InputGroup.Text>
              <Form.Control
                value={globalSearchField}
                id="global-search"
                onChange={e =>
                  globalSearch(e.target.value)}
                className={styles.search_field}
                type="text"
                placeholder="Search by Client Name, Client Partner"
              />
            </InputGroup>
            {
              searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
            }
          </div>
          <div className={styles.buttons}>
            
            {
              isAdmin ?
                <Button onClick={add} className={styles.add_btn} variant="primary">Add</Button>
                : null
            }
          </div>
        </div>
        <div className={styles.table}>
          <BootstrapTable
            rowStyle={rowStyle2}
            bootstrap5
            bordered={false}
            trClassName={styles.customRow}
            keyField="id"
            data={departmentList}
            columns={columns}
            pagination={paginationFactory(options)}
          />
        </div>
      </div>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will delete the Client from the application.
            </div>
            <div className={styles.modal_text}>
              Do you want to proceed
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={deleteClientById} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showUpdatePopup} onHide={handleUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will update the client. Are you sure you want to save the new changes ?
            </div>
          
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleUpdateClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={updateOnPopup} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Client;
