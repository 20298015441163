import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

const account = JSON.parse(localStorage.getItem('account'));


export const getProgramList = (role:string|null): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${Setting.PROGRAM}/getProgramList/${account.localAccountId}/${role}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};
  

export const getProgramNameList = (id:any): Promise<any> =>
  httpRequest({
    url: `${Setting.GETPROGRAMIDNAME}/getProgramIdName`,
    method: HttpMethod.POST,
    data:{ clientID:id },
  });

export const getAllClients = (): Promise<any> =>
  httpRequest({
    url: `${Setting.GETCLIENTIDNAME}/getAllClients`,
    method: HttpMethod.GET,
  });


export const addProgram = (data: any): Promise<any> =>
  httpRequest({
    url: `${Setting.PROGRAM}/postProgram`,
    method: HttpMethod.POST,
    data,
  });



export const editProgram = (data: any, id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.PROGRAM}/updateProgram/${id}`,
    method: HttpMethod.PUT,
    data,
  });

export const deleteProgram = (id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.PROGRAM}/deleteProgram/${id}`,
    method: HttpMethod.DELETE,
  });

export const getAvailableProgram = (): Promise<any> =>
  httpRequest({
    url: `${Setting.PROGRAM}/getAvailableProgram`,
    method: HttpMethod.GET,
  })

