import _ from 'lodash';
import {
  useEffect, useReducer, useRef, useState
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup, Dropdown
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';

import { getHolidayById } from '../../../http/requests';

import styles from './pharmacy-holidays-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';



import './pharmacy-holidays-modal.css';

export const PharmacyHolidaysModal = (props: any) => {

  const [ date, setDate ] = useState("");
  const [ holiday, setHoliday ] = useState("");
  const [ formError, setFormError] = useState({date: "", holiday: ""});
  const dateRegex = new RegExp("^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$")

  useEffect(() => {
    if(props.show) {
      setDate("");
      setHoliday("");
      if(props.mode == "Edit") getHoliday();
      setFormError({date: "", holiday: ""});
    }
  }, [props.show]);

  const getHoliday = () => {
    getHolidayById(props.id).then((res) => {
      setDate(res.date);
      setHoliday(res.holiday);
    }).catch((err) => {
      console.log(err);
      props.onHide();
    });
  } 

  const settingError = (key: string, keyValue: string) => {
    setFormError((prev) => ({
      ...prev,
      [key]: keyValue
    }));
    if(keyValue) return true;
    return false;
  }

  const validateFields = () => {
    let status = false
    if(!holiday) status = settingError("holiday", "This is required field");
    else settingError("holiday", "");
    if(!date) status = settingError("date", "This is required field");
    else if(!dateRegex.test(date)) status = settingError("date", "Invalid date");
    else if( props.holidaylist.some((item: any) => (props.mode !== "Edit" && item.date === date) || (props.mode === "Edit" && props.id !== item.id && item.date === date) )) status = settingError("date", "Date already exists");
    else settingError("date", "");
    return status;
  }

  const submit = () => {
    if(validateFields()) return;
    let payload: any = { date: date, holiday: holiday, ...props.id? {id: props.id}:{} };
    props.onSubmit(payload);  
    props.onHide();
  }

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          { props.mode == "Edit"? "Edit Holiday" : "Add Holiday" }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={styles.editable_form}>
          <Row className="mt-3">
            <Col>
              <Form.Label className={styles.department_heading}>Date
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className={styles.department_heading}>Holiday
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>

          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group className="mb-3" controlId="formBasicItem">
                <InputGroup>
                  <InputMask mask="99/99/9999" value={date} onChange={(e:any) => {setDate(e.target.value); settingError("date", ""); }}>
                    {() => (
                      <Form.Control
                        type="text"
                        placeholder="MM/DD/YYYY"
                        value={date}
                        style={{ height: '30px' }}
                      />
                    )}
                  </InputMask>        
                </InputGroup>
                {
                  formError.date ? <span className={styles.error}>{formError.date}</span> : ''
                }
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicItem">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Maximum 30 characters"
                    maxLength={30}
                    value={ holiday }
                    onChange={(e) => {setHoliday(e.target.value); settingError("holiday", "");}}
                    style={{ height: '30px' }}
                  />
                </InputGroup>
                {
                  formError.holiday ? <span className={styles.error}>{formError.holiday}</span> : ''
                }
              </Form.Group>
            </Col>
          </Row>
        </Form>

      </Modal.Body>
      <Modal.Footer bsPrefix={globalStyle.footer}>
        <div>
          <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
            { props.mode == "Edit" ? "Update": "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

