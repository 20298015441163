import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

export const getClientList = (role:string | null): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${Setting.CLIENT}/getClientList/${account.localAccountId}/${role}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};

  


export const addClient = (data: any): Promise<any> =>
  httpRequest({
    url: `${Setting.CLIENT}/postClient`,
    method: HttpMethod.POST,
    data,
  });

export const getClientName = (): Promise<any> =>
  httpRequest({
    url: `${Setting.GETCLIENTIDNAME}/getClientIdName`,
    method: HttpMethod.GET,
  });
export const editClient = (data: any, id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.CLIENT}/updateClient/${id}`,
    method: HttpMethod.PUT,
    data,
  });

export const deleteClient = (id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.CLIENT}/deleteClient/${id}`,
    method: HttpMethod.DELETE,
  });

