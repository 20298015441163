import { ExceptionDataType } from '@redux/actions';


export enum LocaleType {
  SWITCH_LOCALE = 'SWITCH_LOCALE',
}

export enum UserType {
  AUTHENTICATE_USER = 'AUTHENTICATE_USER',
  INITIALIZE_AUTHENTICATION = 'INITIALIZE_AUTHENTICATION',
  INITIALIZE_LOGIN = 'INITIALIZE_LOGIN',
  SET_USER_ROLE = 'SET_USER_ROLE',
  SET_USER_DATA = 'SET_USER_DATA',
  SHOW_LOGOUT_MODAL = 'SHOW_LOGOUT_MODAL',
  HIDE_LOGOUT_MODAL = 'HIDE_LOGOUT_MODAL',
	GET_ACTIVE_USER = 'GET_ACTIVE_USER'
}

export enum AppStateType {
  SHOW_SPINNER = 'SHOW_SPINNER',
  HIDE_SPINNER = 'HIDE_SPINNER',
  SHOW_EXCEPTIONS_MODAL = 'SHOW_EXCEPTIONS_MODAL',
  HIDE_EXCEPTIONS_MODAL = 'HIDE_EXCEPTIONS_MODAL',
  SET_FONT_SIZE = 'SET_FONT_SIZE',
}

export enum SiteStateType {
  SHOW_LOCATION_PICKER_MODAL = 'SHOW_LOCATION_PICKER_MODAL',
  HIDE_LOCATION_PICKER_MODAL = 'HIDE_LOCATION_PICKER_MODAL',
  SET_SITES = 'SET_SITES',
  GET_SITES = 'GET_SITES',
  GET_CURRENT_SITE = 'GET_CURRENT_SITE',
  SET_CURRENT_SITE = 'SET_CURRENT_SITE',
}

export type LocaleAction = {
  type: typeof LocaleType.SWITCH_LOCALE;
  payload: { locale: string };
};

export type UserAuthenticationAction = {
  type: typeof UserType.AUTHENTICATE_USER;
  payload: { isAuthenticated: boolean };
};

export type UserAuthenticationInitAction = {
  type: typeof UserType.INITIALIZE_AUTHENTICATION;
  payload: { isAuthenticationInitiated: boolean };
};

export type UserLoginInitAction = {
  type: typeof UserType.INITIALIZE_LOGIN;
  payload: { isLoginInitiated: boolean };
};

export type UserRoleAction = {
  type: typeof UserType.SET_USER_ROLE;
  payload: { userRole: string | null };
};


type GetActiveUserAction = {
  type: typeof UserType.GET_ACTIVE_USER;
}

type ShowLogoutModalAction = {
  type: typeof UserType.SHOW_LOGOUT_MODAL;
  payload: { isLogoutModalShown: boolean };
};

type HideLogoutModalAction = {
  type: typeof UserType.HIDE_LOGOUT_MODAL;
  payload: { isLogoutModalShown: boolean };
};


export type UserAction = UserAuthenticationAction |
  UserAuthenticationInitAction |
  UserLoginInitAction |
  UserRoleAction |
  ShowLogoutModalAction |
  HideLogoutModalAction |
	GetActiveUserAction;

type ShowSpinner = {
  type: typeof AppStateType.SHOW_SPINNER;
};

type HideSpinner = {
  type: typeof AppStateType.HIDE_SPINNER;
};

type ShowExceptionsModalAction = {
  type: typeof AppStateType.SHOW_EXCEPTIONS_MODAL;
  payload: ExceptionDataType;
};

type HideExceptionsModalAction = {
  type: typeof AppStateType.HIDE_EXCEPTIONS_MODAL;
};

type ShowLocationPickerModal = {
  type: typeof SiteStateType.SHOW_LOCATION_PICKER_MODAL;
};


type SetFontSize = {
  type: typeof AppStateType.SET_FONT_SIZE;
  payload: string;
}



export type AppStateAction = ShowSpinner |
  HideSpinner |
  ShowExceptionsModalAction |
  HideExceptionsModalAction |
  SetFontSize;

