import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAzureADAuth } from '@hooks';
import { AppDispatch } from '@redux/store';

function Logout() {
  const { logoutAzureADRedirect } = useAzureADAuth();
  const history = useHistory();

  const dispatch: AppDispatch = useDispatch();

  useEffect(()=>{
    const account = JSON.parse(localStorage.getItem('account'));
    if (account && account.localAccountId){
      let PortalLogout = false, signUp = false
      if(localStorage.getItem('PortalLogout')) PortalLogout = true;
      if(localStorage.getItem('signUp')) signUp = true;
      localStorage.clear();
      if(PortalLogout) localStorage.setItem('PortalLogout', 'true');
      if(signUp) localStorage.setItem('signUp', 'true');
      sessionStorage.clear();
      logoutAzureADRedirect();
    }
  }, []);
  return (
      <div className="spinner" />
  );
}

export default Logout;
