export const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // Trim any leading/trailing whitespace
      const eqPos = cookie.indexOf("=");
      
      if (eqPos > 0) {
        const name = cookie.substr(0, eqPos);
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
}

export const isCookiePresent = (cookieName: string) => {
    return document.cookie.split('; ').some(cookie => {
      return cookie.startsWith(cookieName + '=');
    });
  }