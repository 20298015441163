import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Form, Button, InputGroup, Modal
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { DepartmentModal } from '@components/modals/department-modal';
import { USER_ROLE } from '@constants';

import {
  getDepartmentList, addDepartment,
  editDepartment, deleteDepartment,
  getActiveUsersList, genericRecordStatus
} from './../../../http/requests';
import styles from './department.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

function Department({ activeTab, roleChange }) {
  const [ modalShow, setModalShow ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ departmentList, setDepartmentList ] = useState([]);
  const [ _departmentList, set_departmentList ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ deleteId, setDeleteId ] = useState('');
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ showUpdatePopup, setShowUpdatePopup ] = useState(false);
  const [ updateData, setupdateData ] = useState(null);

  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);
  
  useEffect(() => {

      setIsAdmin((role == 'Super Admin') ? true : false);
      if(activeTab==='department'){
        fetchDepartmentList();
      }
      
  }, [activeTab, roleChange]);

  useEffect(()=>{
    fetchDepartmentList();
  },[]);

  useEffect(()=>{
    if(globalSearchField){
      globalSearch(globalSearchField);
    }
  },[_departmentList]);

  useEffect(()=>{
    if(activeTab != 'department'){
      setGlobalSearch('');
      setSearchboxError('');
      globalSearch('');
    }
  }, [ activeTab ]);

  const customTotal = (from: any, to: any, size: any) =>
    (
      <span style={{ marginLeft: '20px' }} className="react-bootstrap-table-pagination-total">
        {from} - {to} of {size}
      </span>
    );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }: {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any
  }) =>
    (
      <span className={styles.listPage}>
        <span>Rows per page &nbsp;&nbsp;
          {
          (
            <Form.Select
              className={styles.perPageSelect}
              onChange={e =>
                onSizePerPageChange(e.target.value)}
              aria-label="Default select example"
            >
              {
                options.map((val: any) =>
                  <option key={val.text} value={val.page}>{val.text}</option>)
              }
            </Form.Select>
          )
        }

        </span>
      </span>
    );


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always deleteId next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    // firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    // lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    // pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageRenderer,
    sizePerPageList: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 } ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const isEnable = (row: any) => {
    const style: any = {};
    if (!row.active) {
      style.pointerEvents = 'none';
    }
    return style;
  };

  const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) =>
    (
      <div className={isAdmin ? styles.table_actions : styles.table_actions2}>
        <div
          style={isEnable(row)}
          onClick={() =>
          view(row)}
        >
          {row.active ? <img src={require('../../../common/icons/view.svg')} alt="" /> :
          <img src={require('../../../common/icons/view-dull.svg')} alt="" />}
        </div>
        {
        isAdmin ? (
          <>
            <div
              style={isEnable(row)}
              onClick={() =>
            edit(row)}
            >
              {row.active ? <img src={require('../../../common/icons/pencil.svg')} alt="" /> :
              <img src={require('../../../common/icons/edit-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
            copyDepartment(row)}
            >
              {row.active ? <img src={require('../../../common/icons/copy.svg')} alt="" /> :
              <img src={require('../../../common/icons/copy-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
            deleteDepartmentItem(row.id)}
            >
              {row.active ? <img src={require('../../../common/icons/delete.svg')} alt="" /> :
              <img src={require('../../../common/icons/delete-dull.svg')} alt="" />}
            </div>
            <div>
              <Form.Check
                className={styles.table_actions_switch}
                checked={row.active}
                type="switch"
                id="custom-switch"
                onChange={e =>
              activeDepartment(e.target.checked, row)}
              />
            </div>
          </>
        ) : null
        }
      </div>
    );

  const columns = [
    {
      dataField: 'departmentName',
      text: 'DEPARTMENT NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '14em' },
    },
    {
      dataField: 'departmentOwner',
      text: 'DEPARTMENT OWNER/S',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
    },
    {
      dataField: 'action',
      text: 'ACTION',
      formatter: linkFollow,
      sort: true,
      headerAlign: 'center',
      style: { width: '12em' },
    },

  ];

  const rowStyle2 = (row: any, rowIndex: any) => {
    const style: any = {};
    if (!row.active) {
      style.color = 'rgba(114, 114, 114, 0.3)';
      //   style.pointerEvents = 'none'
    }

    return style;
  };

  const fetchDepartmentList = () => {
    getDepartmentList(role).then((res) => {
      const data: any = [];
      res.forEach((r: any) => {
        let name = '';
        const departmentOwnerList: any = [];
        r.departmentOwnerList.forEach((d: any) =>{
          [
            name += d.ownerName + ', ',
          ]
          departmentOwnerList.push({...d,userType:''});
        });
        if (name.length > 0) {
          name = name.substring(0, name.length - 2);
        }
        if(name=="undefined"){
          name = "No Department Owner exists for the Department";
        }
        data.push({
          ...r,
          departmentOwner: name,
          departmentOwnerList: departmentOwnerList,
        });
      });
      set_departmentList(_.cloneDeep(data));
      setDepartmentList(_.cloneDeep(data));
    }).catch((err)=>{
      console.log(err);
    });
  };

  const submit = (data: any) => {
    setModalShow(false);
    if (data.type == 'add' || data.type == 'copy') {
      setGlobalSearch('');
      setSearchboxError('');
      addDepartment(data.departmentData).then((res) => {
        setShowSuccess(true);
        data.type == 'add' ? setShowSuccessMsg('Department Created Successfully') : setShowSuccessMsg('Department Copied Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    } else if (data.type == 'update') {
      setupdateData(data);
      setShowUpdatePopup(true);
    }
  };

  const view = (department: any) => {
    setModalData({
      header: 'View Department',
      department,
      submit,
    });
    setModalShow(true);
  };

  const activeDepartment = (value: any, department: any) => {
    const data = {
      recordType: 'Department',
      status: value,
    };
    genericRecordStatus(department.id, data).then(() => {
      getDepartmentList(role).then((res) => {
        const data: any = [];
        res.forEach((r: any) => {
          let name = '';
          r.departmentOwnerList.forEach((d: any) =>
            [
              name += d.ownerName + ', ',
            ]);
          if (name.length > 0) {
            name = name.substring(0, name.length - 2);
          }
          if(name=="undefined"){
            name = "No Department Owner exists for the Department";
          }
          data.push({
            ...r,
            departmentOwner: name,
          });
        });
        set_departmentList(_.cloneDeep(data));
        setDepartmentList(_.cloneDeep(data));
        // setTimeout(()=>{        
        //   setGlobalSearch('');
        //   setSearchboxError('');
        //   // globalSearch('')
        // }, 1000);
      }).catch((err)=>{
        console.log(err);
      });
    })
    .catch((error) => {
      // Handle the error here
      fetchDepartmentList();
    });
  };

  const copyDepartment = (department: any) => {
    setModalData({
      header: 'Copy Department',
      department,
      submit,
    });
    setModalShow(true);
  };
  const add = () => {
    setModalData({
      header: 'Add Department',
      submit,
    });
    setModalShow(true);
  };
  const edit = (department: any) => {
    setModalData({
      header: 'Edit Department',
      department,
      submit,
    });
    setModalShow(true);
  };

  const deleteDepartmentItem = (id: string) => {
    setDeleteId(id);
    setShow(true);
  };

  const deleteDepartmentById = () => {
    if (deleteId) {
      setShow(false);
      setGlobalSearch('');
      setSearchboxError('');
      deleteDepartment(deleteId).then((res) => {
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }
  };

  const globalSearch = (input: string) => {
    setGlobalSearch(input);
    const departmentListCopy = _.cloneDeep(_departmentList);
    if (input) {
      const list = departmentListCopy.filter((department: any) => {
        if (department.departmentName.toLowerCase().includes(input.toLowerCase()) ||
          department.departmentOwner.toLowerCase().includes(input.toLowerCase())) {
          return department;
        }
      });
      if(list.length == 0){
        setSearchboxError(input + ' does not exist.');
      } else{
        setSearchboxError('');
      }
      setDepartmentList(list);
    } else {
      setSearchboxError('');
      setDepartmentList(departmentListCopy);
    }
  };

  const updateOnPopup = ()=>{
    setShowUpdatePopup(false);
    if(updateData && updateData.id){
      setGlobalSearch('');
      setSearchboxError('');
      editDepartment(updateData.departmentData, updateData.id).then((res) => {
        setShowSuccess(true);
        setShowSuccessMsg('Department Updated Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }    
  };

  const handleClose = () =>
    setShow(false);
  const handleSuccessClose = () =>
    setShowSuccess(false);
  const handleUpdateClose = () => 
    setShowUpdatePopup(false);

  return (
    <>
      <div id={styles.department}>
        <DepartmentModal
          data={modalData}
          show={modalShow}
          activeTab={activeTab}
          onHide={() =>
            setModalShow(false)}
        />
        <div className={styles.filter}>
          <div className={styles.search}>
            <InputGroup>
              <InputGroup.Text className={styles.search_logo}>
                <img src={require('../../../common/images/search.png')} alt="" />
              </InputGroup.Text>
              <Form.Control
                value={globalSearchField}
                id="global-search"
                onChange={e =>
                  globalSearch(e.target.value)}
                className={styles.search_field}
                type="text"
                placeholder="Search by Department Name, Department Owner"
              />
            </InputGroup>
            {
              searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
            }
          </div>
          <div className={styles.buttons}>
           
            {
              isAdmin ?
                <Button onClick={add} className={styles.add_btn} variant="primary">Add</Button>
              : null
            }
          </div>
        </div>
        <div className={styles.table}>
          
          <BootstrapTable rowStyle={rowStyle2} bootstrap5 bordered={false} trClassName={styles.customRow} keyField="id" data={departmentList} columns={columns} pagination={paginationFactory(options)} />
        </div>
      </div>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Department</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will delete the Department from the application.
            </div>
            <div className={styles.modal_text}>
              Do you want to proceed
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={deleteDepartmentById} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showUpdatePopup} onHide={handleUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Department</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will update the department. Are you sure you want to save the new changes ?
            </div>
           
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleUpdateClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={updateOnPopup} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Department;
