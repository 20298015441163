// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--FvLgVtMM{display:flex}.left--001DAYTR{width:240px !important}", "",{"version":3,"sources":["webpack://./src/pages/collaboration-view/home/home.scss"],"names":[],"mappings":"AAAA,gBACI,YAAA,CAGJ,gBACI,sBAAA","sourcesContent":[".root{\r\n    display: flex;\r\n}\r\n\r\n.left{\r\n    width: 240px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--FvLgVtMM",
	"left": "left--001DAYTR"
};
export default ___CSS_LOADER_EXPORT___;
