import { ACCESS_TOKEN } from '@constants';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import { getLocalStorageItem } from './local-storage';
import { store } from '@redux/store';
import { setUserRole } from '@redux/actions';

export const httpRequest = async <T>(
  options: AxiosRequestConfig
): Promise<T | undefined> => {
  try {
    const accessToken = getLocalStorageItem(ACCESS_TOKEN);
    const authorization = accessToken ? `Bearer ${accessToken}` : '';
    const isOcpApimTrace = (window?.env?.OCP_APIM_TRACE || '').toLowerCase() === 'true';
    const headers = {
      'x-correlation-id': v4(),
      ...(isOcpApimTrace && { 'Ocp-Apim-Trace': 'True' }),
      Authorization: authorization,
    };
    const response: AxiosResponse<T> = await axios({
      ...options,
      headers: headers,
    });

    return response.data;
  } catch (error) {
    const { response } = error as AxiosError<T>;

    if (response?.data.errorMessage==="Role Validation Error") {
      store.dispatch(setUserRole(""));
      // await delay(2500);
      // window.location.reload();
    }

    return Promise.reject(response);
  }
};

// function delay(ms:any) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }