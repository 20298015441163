import _ from 'lodash';
import {
  useEffect, useReducer, useRef, useState
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup, Dropdown
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { getNDCCategory, getItemMasterCategory } from '../../../http/requests';

import styles from './item-master-category-modal.scss';
import './item-master-category-modal.css';
import globalStyle from '../../../common/styles/globalStyle.scss';

interface Category {
    id: number;
    category: string;
    tatSLA: number;
  }

export const ItemMasterCategoryModal = (props: any) => {

  const [ drugName, setDrugName ] = useState("");
  const [ ndc, setNDC ] = useState("");
  const [ categoryList, setCategoryList ] = useState([]);
  const [ category, setCategory ] = useState<Category | null>(null);
  const [ tat, setTat ] = useState('');
  const [ formError, setFormError] = useState({category: "",tat:""});

  useEffect(() => {
    if(props.show) {
      getCategory();
      setDrugName(props.row.itemName);
      setNDC(props.row.ndc);
      setTat(props.row.tat);
      setFormError({category: "",tat:""});
    } else setCategory(null);
  }, [props.show]);

  const getCategory = () => {
    let response1 = getItemMasterCategory(props.programid);
    let response2 = getNDCCategory(props.row.categoryId);
    Promise.all([response1, response2]).then((res) => {
        setCategoryList(res[0]);
        setCategory(res[1]);
    }).catch((err) => {
        console.log(err);
        props.onHide();
    });
  } 

  const settingError = (key: string, keyValue: string) => {
    setFormError((prev) => ({
      ...prev,
      [key]: keyValue
    }));
    if(keyValue) return true;
    return false;
  }

  const validateFields = () => {
    let status = false
    if(props.categoryExist=='true'){
      if(!category?.id) status = settingError("category", "This is required field");
      else settingError("category", "");
    }

    if(!tat.toString()) status= settingError("tat", "This is required field");
    else settingError("tat", "");

    
    return status;
  }

  const submit = () => {
    if(validateFields()) return;
    const payload: any = { id: props.row.id, categoryId: category?.id, tat:tat };
    props.onSubmit(payload);
    props.onHide();
  }

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          Edit NDC
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className={styles.editable_form}>
          <Row className="mt-3">
            <Col>
              <Form.Label className={styles.department_heading}>Drug Name
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className={styles.department_heading}>NDC
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>

          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group className="mb-3" controlId="formBasicItem">
              <InputGroup>
                  <Form.Control
                    type="text"
                    value={ drugName }
                    disabled={ true }
                    style={{ height: '30px' }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicItem">
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={ ndc }
                    disabled={ true }
                    style={{ height: '30px' }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Label className={styles.department_heading}>TAT
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>
          {
            props.categoryExist=='true' &&(
              <Col>
              <Form.Label className={styles.department_heading}>Category
                <span className={styles.astrix}>*</span>
              </Form.Label>
            </Col>
            )
          }
            

          </Row>
          <Row className="mt-3">
            <Col>
            <Form.Group className="mb-3" controlId="formBasicItem">
              <InputGroup>
                <Form.Control
                  // autoComplete="off"
                  // ref={}
                  className={styles.tatInput}
                  style={{ height: '30px' }}
                  type="text"
                  value={tat}
                  onChange={(e) => { 
                    const newValue = e.target.value;
                    if (newValue === '' || /^[0-9\b]+$/.test(newValue)) {
                      setTat(newValue);
                      setFormError(p => ({
                        ...p,
                        tat: '',
                      }));
                    }
                  }}
                />
              </InputGroup>
                  {
                    formError.tat ? <span className={styles.error}>{formError.tat}</span> : ''
                  }
            </Form.Group>
            </Col>

            {
              props.categoryExist=='true' ? (
                <Col>
                <Form.Group className="mb-3" controlId="formBasicItem">
                  <Form.Select
                      value={category?.category}
                      onChange={(e) => setCategory(categoryList[e.target.selectedIndex - 1])}
                      className={styles.category_select}
                  >
                      <option value={null} style={{display: 'none'}}>Select Category</option>
                      {categoryList.map((item, index) => (
                      <option key={index} value={item.category}>
                          {item.category}
                      </option>
                      ))}
                  </Form.Select>
                  {
                    formError.category ? <span className={styles.error}>{formError.category}</span> : ''
                  }
                </Form.Group>
              </Col>
              ):(<Col></Col>)
            }
              
            
          </Row>
        </Form>

      </Modal.Body>
      <Modal.Footer bsPrefix={globalStyle.footer}>
        <div>
          <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
            Update
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};