import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { CollaborationViewRoutes } from '@enums';

import styles from './page-not-found.scss';

// TODO: Refactor into a common design component with PageExceptions after DPGECLOF-1213,1215
export const PageNotFound = () =>
  (
    <div className={styles.wrapperPageNotFound}>
      <div className={styles.containerPageNotFound}>
        <Row>
          <div className={styles.containerPageNotFoundIconContainer}>
          <img src={require('../../common/icons/alert-circle.svg')} alt="" />
            {/* <div
              className={styles.containerPageNotFoundIconContainerIconWrapper}
            >
             
            </div> */}
          </div>
          <h2 className={styles.containerPageNotFoundTitle}>Page Not Found</h2>
          <p className={styles.containerPageNotFoundText}>
            The link you followed may be broken or the page <br/> may have been removed.
          </p>
          <div className={styles.containerPageNotFoundLinkContainer}>
            <NavLink
              to={CollaborationViewRoutes.home}
              className={styles.containerPageNotFoundLinkContainerLink}
            >
              Return to Dashboard
            </NavLink>
          </div>
        </Row>
      </div>
      
    </div>
  );
