import { Route, Switch } from 'react-router-dom';

import { AuthRoute } from '@components/auth-route';
import { PageNotFound } from '@pages/page-not-found';
import { cvRoutes } from '@routes';

import styles from './home.scss';

export const Home = () => 
  (
    <>
      <div className={styles.right}>
        <Switch>
          {cvRoutes.map(({ path, name, ...props }) =>
            (
              <AuthRoute
                key={path}
                path={path}
                name={name}
                exact
                {...props}
              />
            ))}
          {/* TODO comment: refactor within DPGECLOF-713 */}
          <Route component={PageNotFound}/>

        </Switch>
      </div>
    </>
  );
