import { UserAction, UserType } from './types';

export const setIsAuthenticated = (isAuthenticated: boolean): UserAction =>
  ({
    type: UserType.AUTHENTICATE_USER,
    payload: { isAuthenticated },
  });

export const initiateAuthentication =
  (isAuthenticationInitiated: boolean): UserAction =>
    ({
      type: UserType.INITIALIZE_AUTHENTICATION,
      payload: { isAuthenticationInitiated },
    });

export const initiateLogin =
  (isLoginInitiated: boolean): UserAction =>
    ({
      type: UserType.INITIALIZE_LOGIN,
      payload: { isLoginInitiated },
    });

export const setUserRole = (userRole: string | null): UserAction =>
  ({
    type: UserType.SET_USER_ROLE,
    payload: { userRole },
  });


export const showLogoutModal = (): UserAction =>
  ({
    type: UserType.SHOW_LOGOUT_MODAL,
    payload: { isLogoutModalShown: true },
  });

export const hideLogoutModal = (): UserAction =>
  ({
    type: UserType.HIDE_LOGOUT_MODAL,
    payload: { isLogoutModalShown: false },
  });


export const getActiveUser = (): UserAction =>
  ({ type: UserType.GET_ACTIVE_USER });
