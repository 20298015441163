import { HttpMethod } from '@enums';
import { BASE_URL } from '@http/api';
import { httpRequest } from '@utils';


export const genericRecordStatus = (id:string, data:any): Promise<any> =>
  httpRequest({
    url: `${BASE_URL}/GenericRecordStatus/${id}`,
    method: HttpMethod.PUT,
    data,
  });
