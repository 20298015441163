import {
  PublicClientApplication, Configuration, RedirectRequest,
  AuthenticationResult, EventMessage, EventType, AuthError
} from '@azure/msal-browser';
import {
  ACCESS_TOKEN, ACCOUNT, EXPIRES_ON, USER_ROLE 
} from '@constants';
import { loginRecord } from '@http/requests';
import { setIsAuthenticated, setUserRole, initiateLogin } from '@redux/actions';
import { store } from '@redux/store';
import { getLocalStorageItem, setLocalStorageItem } from '@utils';
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { setExpirationTimeout } from './msal-expiration';
import { useAzureADAuth } from '@hooks';

let USE_SILENT_MODE = false;

function abandonSilentMode() {
  USE_SILENT_MODE = false;
}

function resetSilentMode() {
  USE_SILENT_MODE = (window?.env?.SSO_SILENT_LOGIN_ACTIVE || '').toLowerCase() === 'true';
}

resetSilentMode();

export function msalUseSilentMode() {
  return USE_SILENT_MODE;
}

const msalConfig: Configuration = {
  auth: {
    clientId: window?.env?.CLIENT_ID || '',
    authority: window?.env?.AUTHORITY || '',
    redirectUri: window.location.origin,
    validateAuthority: false,
    knownAuthorities: [ window?.env?.AUTHORITYDOMAIN || '' ],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalRequest: RedirectRequest = {
  scopes: [
    'openid', 'offline_access', 'email', 'profile', window?.env?.SCOPE || '', 
  ],
};

export const msalSilentRequest: RedirectRequest = {
  ...msalRequest,
  redirectUri: window.location.origin,
};

export const msalClient = new PublicClientApplication(msalConfig);

msalClient.addEventCallback((message: EventMessage) => {
  const { eventType, payload, error } = message;

  const falureEvents = [
    EventType.LOGIN_FAILURE,
    EventType.ACQUIRE_TOKEN_FAILURE,
    EventType.SSO_SILENT_FAILURE,
  ];


  const successEvents = [
    EventType.ACQUIRE_TOKEN_SUCCESS,
    EventType.LOGIN_SUCCESS,
    EventType.SSO_SILENT_SUCCESS,
  ];

  if(falureEvents.includes(eventType) || successEvents.includes(eventType)) store.dispatch(initiateLogin(false));

  if (successEvents.includes(eventType)) {
    const data = payload as AuthenticationResult;
    if (data && data.account) {
      resetSilentMode();
      setLocalStorageItem('b2cLocalAccountId', data.account.localAccountId);
      const idpAccessToken = data.account.idTokenClaims?.idp_access_token;
      if(idpAccessToken){
        try {
          const decodedToken: JwtPayload = jwtDecode(idpAccessToken);
          if(decodedToken && window?.env?.IDP_TENANTID.includes(decodedToken.tid)){
            data.account.localAccountId = decodedToken?.oid;
          }
        } catch (err) {
          console.log('Error decoding JWT token:', err.message);
        }
      }
      const expiresOnFromResponse = String(data.expiresOn);
      setLocalStorageItem(ACCESS_TOKEN, data.accessToken);
      setLocalStorageItem(ACCOUNT, JSON.stringify(data.account));
      setLocalStorageItem(EXPIRES_ON, expiresOnFromResponse);
      setExpirationTimeout(msalClient, msalRequest, expiresOnFromResponse);
      store.dispatch(setIsAuthenticated(true));
      loginRecord({
        userId: data.account.localAccountId,
        isSuccessfullLogin: true,
      }).then((res:any)=>{
        store.dispatch(setUserRole(res.userRoleName));
        setLocalStorageItem(USER_ROLE, res.userRoleName);
      });
    }
  }

  if (eventType === EventType.SSO_SILENT_FAILURE) {
    // There is also can be not only InteractionRequiredAuthError but another type of error
    // after which is better to switch to normal flow
    abandonSilentMode();
  }
});

window.addEventListener('storage', ({ key, newValue, oldValue }) =>{
  if (key === ACCESS_TOKEN && !newValue && oldValue) {
    // removing ACCESS_TOKEN from storage means that user logged out from another tab
    // user should be redirected to the auth page
    location.reload();
  }
});

export function checkMsalExpiration() {
  const expiresOnFromStorage = getLocalStorageItem(EXPIRES_ON);
  expiresOnFromStorage && setExpirationTimeout(msalClient, msalRequest, expiresOnFromStorage);
}
