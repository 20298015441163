import _ from 'lodash';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import {
  useEffect, useReducer, useRef, useState 
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup 
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import {
  getDepartmentList, getWorkspace, getDepartment, getClientProgram, getReport, getReportList 
} from '../../../http/requests';

import styles from './report-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';

import './report.css';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

export const ReportModal = (props: any) => {
  const reportRef = useRef();
  const initialData = {
    // 'id' : '',
    'departmentID' : '',
    // 'clientProgram': [],    
    'reportList' : [],
    'workspace': [],
    'dateCreated' : '',
    'clientProgramIds':[],
  };

  const intialnameList:any = [];
  const initialFormError: {
    departmentId: string,
    clientProgram: string,
    workspace: any,
    report: any
  } = {
    departmentId: '',
    clientProgram: '',
    workspace:'',
    report:'',
  };

  const clientDataReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'Add_Client':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const [ hideOptions, setHideOptions ] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const initialClientName: any = null;
  const [ selectedClientName, setSelectedClientName ] = useState(initialClientName);

  const [ hideselectedValue, setSelectedValue ] = useState(true);

  const locationFilterInput = useRef<HTMLInputElement>(null);

  const [ ownerSelectedValue, setOwnerSelectedValue ] = useState([]);
  const [ workspaceSelectedValue, setWorkspaceSelectedValue ] = useState([]);
  const [ reportSelectedValue, setReportSelectedValue ] = useState([]);

  const [ clientProgram, setClientProgram ] = useState([]);
  const [ workspace, setWorkspace ] = useState([]);
  const [ report, setReport ] = useState([]);
  const [ departmentList, setDepartmentList ] = useState([]);
  const [ nameList, setNameList ] = useState(intialnameList);
  const [ _nameList, _setNameList ] = useState(intialnameList);
  const [ modalData, dispatchData ] = useReducer(clientDataReducer, initialData);
  const [ formError, setFormError ] = useState(initialFormError);
  const [ reportListApi, setReportListApi ] = useState([]);
  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(() => {
    if(props.data.header && props.data.header !='View Report'){
      getReportList(role).then((res)=>{
        setReportListApi(res);
      }).catch((err)=>{
        console.log(err);
      })
      
      fetchDepartmentList();
      getDepartment().then((res)=>{
        setNameList(res);
        _setNameList(res);
      })
      .catch((err) => {
        console.log(err);
      });

      getClientProgram().then((res)=>{
        const data:any = [];
        const programIdList:any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            name: r.clientName + ' - ' + r.programName,
          });
          programIdList.push(r.programID)
        });
        setClientProgram(data);
        if(props.data && props.data.currentRow && getModalType() == 'copy'){
          const clientProgramResponses = props.data.currentRow.clientProgramResponses;
          const filterdClientProgramResponses=clientProgramResponses.filter((clientProgram: any)=>{
            if(programIdList.includes(clientProgram.programID)){
              return clientProgram
            }
          });
          const clientProgramIds: any = [];
          const selected: any = [];

          filterdClientProgramResponses.forEach((d:any)=>{
            selected.push({
              ...d,
              name:d.clientName + ' - ' + d.programName,
            });
            const obj = { ...d };
            delete obj.clientName;
            delete obj.programName;
            clientProgramIds.push(obj);
          });
          setOwnerSelectedValue(selected);
          dispatchData({
            type: 'Add_Client',
            payload: {
              clientProgramIds
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
      getWorkspace().then((res)=>{
        const data:any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            name: r.workspaceName + ' - ' + r.workspaceID,
          });
        });
        setWorkspace(data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    

    if (props.data && props.data.currentRow && props.data.header !='View Report') {
      //const clientProgramResponses = props.data.currentRow.clientProgramResponses;
      const reportList = props.data.currentRow.reportList;
      
      

      const finalReportList:any = [];
      reportList.forEach((d:any)=>{
        const obj = { ...d };
        delete obj.departmentReportID;
        finalReportList.push(obj);
      });
      //setOwnerSelectedValue(selected);

      const l = props.data.currentRow.workspaceName.split(', ').length;
      const aws = props.data.currentRow.workspaceName.split(', ');
      const awid = props.data.currentRow.workspaceId.split(', ');

      const ws:any = [];
      const data:any = [];
      for(let i = 0;i < l;i++){
        ws.push({
          name: aws[i] + ' - ' + awid[i],
          workspaceID:awid[i],
          workspaceName:aws[i],
        });
        data.push({
          workspaceID:awid[i],
          workspaceName:aws[i],
        });
      }
      setWorkspaceSelectedValue(ws);

      getReport(data).then((res)=>{
        const data: any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            name: r.reportName + ' - ' + r.reportID,
          });
        });
        setReport(data);
      })
      .catch((err)=>{
        console.log(err);
        setReport([]);
      });

      const rp:any = [];
      finalReportList.forEach((r:any)=>{
        rp.push({
          ...r,
          name: r.reportName + ' - ' + r.reportID,
        });
      });
      setReportSelectedValue(rp);

      if(getModalType() == 'update'){
        const clientProgramResponses = props.data.currentRow.clientProgramResponses;
        const clientProgramIds: any = [];
        const selected: any = [];
        if(clientProgramResponses[0].programID !=0){
          clientProgramResponses.forEach((d:any)=>{
            selected.push({
              ...d,
              name:d.clientName + ' - ' + d.programName,
            });
            const obj = { ...d };
            delete obj.clientName;
            delete obj.programName;
            clientProgramIds.push(obj);
          });
        }
        setOwnerSelectedValue(selected);
        setSelectedClientName({
          departmentID: props.data.currentRow.departmentID,
          departmentName: props.data.currentRow.departmentName,
        });
        dispatchData({
          type: 'Add_Client',
          payload: {
            departmentID: props.data.currentRow.departmentID,
            clientProgramIds,
            dateCreated: moment().format('MM-DD-YYYY'),
            reportList: finalReportList,
            workspace: data,
          },
        });
        setSelectedValue(false);
      } else{
        dispatchData({
          type: 'Add_Client',
          payload: {
            departmentID: '',
            // clientProgramIds,
            dateCreated: moment().format('MM-DD-YYYY'),
            reportList: finalReportList,
            workspace: data,
          },
        });
        setSelectedValue(true);
      }
    } else {
      setOwnerSelectedValue([]);
      setWorkspaceSelectedValue([]);
      setReportSelectedValue([]);
      setReport([])
      dispatchData({
        type: 'Add_Client',
        payload: {
          departmentID: '',
          clientProgramIds: '',
          reportList: '',
          workspace: '',
          // clientProgram: '',
          dateCreated: moment().format('MM-DD-YYYY'),
        },
      });
    }
    return () => {
      removeSelectedClientName();
      setOwnerSelectedValue([]);
      setFormError((p: any) => 
        ({
          departmentId: '',
          clientProgram: '',
          workspace:'',
          report:'',
        }));
      dispatchData({
        type: 'Add_Client',
        payload: {
          departmentID: '',
          workspace: '',
          // clientProgram: '',
          clientProgramIds: '',
          reportList:'',
          dateCreated: moment().format('MM-DD-YYYY'),
        },
      });
    };
  }, [ props.data ]);

  function onSelect(selectedList: any, selectedItem: any) {
    
    const element = document.getElementsByClassName('searchWrapper')[0];

    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        clientProgram: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push({
        clientID: s.clientID,
        programID: s.programID,
      });
    });
    dispatchData({
      type: 'Add_Client',
      payload: { clientProgramIds: data },
    });
  }

  function onRemove(selectedList: any, removedItem: any) {
    
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          clientProgram: 'Client Program required',
        }));
      dispatchData({
        type: 'Add_Client',
        payload: { clientProgramIds: [] },
      });
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push({
          clientID: s.clientID,
          programID: s.programID,
        });
      });
      dispatchData({
        type: 'Add_Client',
        payload: { clientProgramIds: data },
      });
    }
  }

  function onSelectWS(selectedList: any, selectedItem: any) {
    
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        workspace: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      data.push({
        workspaceID:s.workspaceID,
        workspaceName:s.workspaceName,
      });
    });
    dispatchData({
      type: 'Add_Client',
      payload: { workspace: data },
    });
    getReport(data).then((res)=>{
      const data: any = [];
      res.forEach((r:any)=>{
        data.push({
          ...r,
          name: r.reportName + ' - ' + r.reportID,
        });
      });
      setReport(data);
    })
    .catch((err)=>{
      console.log(err);
      setReport([]);
    });
  }

  function onRemoveWS(selectedList: any, removedItem: any) {
    reportRef.current.resetSelectedValues();
    delete modalData.reportList[removedItem.workspaceID];
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          workspace: 'Workspace required',
        }));
      dispatchData({
        type: 'Add_Client',
        payload: { workspace: [] },
      });
      setReport([]);
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        data.push({
          workspaceID:s.workspaceID,
          workspaceName:s.workspaceName,
        });
      });
      dispatchData({
        type: 'Add_Client',
        payload: { workspace: data },
      });
      getReport(data).then((res)=>{
        const data: any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            name: r.reportName + ' - ' + r.reportID,
          });
        });
        setReport(data);
      })
      .catch((err)=>{
        console.log(err);
        setReport([]);
      });
    }
  }

  function onSelectR(selectedList: any, selectedItem: any) {
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        report: '',
      }));
    const data: any = [];
    selectedList.forEach((s: any) => {
      // delete s.name;
      const obj = { ...s };
      delete obj.name;
      data.push(obj);
    });
    dispatchData({
      type: 'Add_Client',
      payload: { reportList: data },
    });
  }

  function onRemoveR(selectedList: any, removedItem: any) {
    
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          report: 'Report required',
        }));
      dispatchData({
        type: 'Add_Client',
        payload: { reportList: [] },
      });
      // setReport([])
    } else {
      const data: any = [];
      selectedList.forEach((s: any) => {
        const obj = { ...s };
        delete obj.name;
        data.push(obj);
      });
      dispatchData({
        type: 'Add_Client',
        payload: { reportList: data },
      });
    }
  }

  const getModalType = () => {
    if (props.data.header === 'Add Report') {
      return 'add';
    } else if (props.data.header === 'Edit Report') {
      return 'update';
    } else if (props.data.header === 'Copy Report') {
      return 'copy';
    }
  };

  const fetchDepartmentList = () => {
    getDepartmentList(role).then((res) => {
      setDepartmentList(_.cloneDeep(res));
    }).catch((err)=>{
      console.log(err);
    });
  };

  const validateForm = (modalData: any) => {
    let isValid = true;

    if (!modalData.departmentID) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          departmentId: 'Department name required',
        }));
    }
    if (modalData.workspace.length <=0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          workspace: 'Workspace required',
        }));
    }
    
    if (modalData.clientProgramIds.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          clientProgram: 'Client Program required',
        }));
    }

    if(modalData.reportList.length <= 0){
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          report: 'Report required',
        }));
    }

    return isValid;
  };

  const submit = () => {
    if (validateForm(modalData)) {
      // delete modalData.id;
      delete modalData.workspace;
      
      if (props.data && props.data.currentRow) {
        props.data.submit({
          modalData,
          id: props.data.currentRow.departmentID,
          type: getModalType(),
          loading: false,
        });
      } else {
        props.data.submit({
          modalData,
          type: getModalType(),
          loading: false,
        });
      }
    }
  };

  function transformDate(date: any) {
    const a = date.split('-');
    return `${a[2].slice(0, 2)}/${a[1]}/${a[0]}`;
  }

  const getFullName = (user: any) => 
    user.firstName + ' ' + user.lastName;

  const selectedValueDecorator = (val: any) => 
    (
      <small>{val}</small>
    );
  

  const optionValueDecorator = (val: any) => 
    (
      <p>{val}</p>
    );
  
  const filterLocations = (search: string) => {
    if (search) {
      const copy = _.clone(nameList);
      const filtered = copy.filter((d: any) => {
        if (d && d.departmentName && d.departmentName.toLowerCase().includes(search.toLowerCase())) {
          return d;
        }
      });
      setNameList(filtered);
    } else {
      setNameList(_nameList);
    }
  };

  const removeSelectedClientName = () => {
    setSelectedClientName(null);
  };

  function customArrow() {
    return (
      <div>
        <img
          src={require('../../../common/icons/arrow-down.svg')}
        />
      </div>

    );
  }
  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.data.header == 'View Report' ?
            (
              <>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>Department Name</div>
                    <div className={styles.view_department}>{props.data.currentRow.departmentName}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Report Name</div>
                    <div className={styles.view_owners}>{
                      props.data.currentRow.reportName.split(', ').map((d:any)=>
                        <p key={d}>{d} &nbsp;</p>)
                    }</div>
                  </Col>
                </Row>
                <Row className="mt-5">
               
                  <Col>
                    <div className={styles.department_heading}>Client Name</div>
                    <div className={styles.view_owners}>{
                      props.data.currentRow.clientNames.map((d:any,index:any)=>
                        <p key={index} style={{ color: d.clientActive ? '' : 'rgba(114, 114, 114, 0.3)' }}>{d.clientName} &nbsp;</p>)
                    }</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Program Name</div>
                    <div className={styles.view_owners}>{
                      props.data.currentRow.programNames.map((d:any,index:any)=>
                        <p key={index} style={{ color: d.programActive ? '' : 'rgba(114, 114, 114, 0.3)' }}>{d.programName} &nbsp;</p>)
                    }</div>
                  </Col>
                  
                </Row>
              
                <Row className="mt-5">
                <Col>
                    <div className={styles.department_heading}>Workspace Name</div>
                    <div className={styles.view_owners}>{
                      props.data.currentRow.workspaceName.split(', ').map((d:any)=>
                        <p key={d}>{d} &nbsp;</p>)
                    }</div>
                  </Col>
                </Row>
              </>
            ) :
            (
              <Form>
                <Row className="mt-3">
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label className={styles.department_heading}>Department Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <div className={styles.LocationBar}>
                        {selectedClientName && !hideselectedValue ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedClientName?.departmentName}</div>
                            <div
                              className={styles.LocationBarRemoveSelected}
                              onClick={removeSelectedClientName}
                              role="none"
                            >x</div>
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput}
                          className={styles.LocationBarFormControl}
                          type="text"
                          placeholder={selectedClientName ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions(true); setSelectedValue(false); setIsInputFocused(false);}, 500); }}
                          onFocus={() => { setSelectedValue(true); setIsInputFocused(true);}}
                          onChange={(e) => {
                            setHideOptions(false);
                            filterLocations(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions)
                              setHideOptions(false);
                            // else
                            //   setHideOptions(true);
                          }}
                        />
                        {!hideOptions ? (
                          nameList.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                nameList.map((item, index) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      dispatchData({
                                        type: 'Add_Client',
                                        payload: { departmentID: item.departmentID },
                                      });
                                      setFormError(p => 
({
                                          ...p,
                                          departmentId: '',
                                        }));
                                      setSelectedClientName(item);
                                      setHideOptions(true);
                                      setSelectedValue(false);
                                      locationFilterInput.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.departmentName}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No department name found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                          (
                            <div className={styles.LocationBarRightIcon}>
                              <img
                                src={require('../../../common/icons/arrow-down.svg')}
                                role="none"
                                alt="dropdown icon"
                                onClick={() => {
                                  if (isInputFocused && hideOptions){
                                    setHideOptions(false);
                                  }
                                  else if( !isInputFocused && hideOptions){
                                    setHideOptions(false);
                                    locationFilterInput.current?.focus();
                                    setIsInputFocused(true);
                                  }
                                  else{
                                    setHideOptions(true);
                                    setIsInputFocused(false);
                                  }
                                }}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        formError.departmentId ? <span className={styles.error}>{formError.departmentId}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col >

                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label className={styles.department_heading}>Date Created</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className={styles.date_created}
                          disabled
                          type="text"
                          value={moment().format('MM/DD/YYYY')}
                        />
                        <InputGroup.Text className={styles.calendar}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label className={styles.department_heading}>Client / Program Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Multiselect
                        customArrow={customArrow()}
                        options={clientProgram} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={ownerSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                      {
                        formError.clientProgram ? <span className={styles.error}>{formError.clientProgram}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label className={styles.department_heading}>Workspace Name / Id
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Multiselect
                        customArrow={customArrow()}
                        options={workspace} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={workspaceSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelectWS} // Function will trigger on select event
                        onRemove={onRemoveWS} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                      {
                        formError.workspace ? <span className={styles.error}>{formError.workspace}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                      <Form.Label className={styles.department_heading}>Report Name / Id
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Multiselect
                        ref={reportRef}
                        customArrow={customArrow()}
                        options={report} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={reportSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelectR} // Function will trigger on select event
                        onRemove={onRemoveR} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.multi_select}
                        showArrow={true}
                        showCheckbox={true}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                        selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                        optionValueDecorator(val)}
                      />
                      {
                        formError.report ? <span className={styles.error}>{formError.report}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )
        }

      </Modal.Body>
      {
        (props.data.header != 'View Report') ?
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              </div>
              <div>
                <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                  {getModalType() == 'add' ? 'Create' : getModalType() == 'update' ? 'Update' : 'Copy'}
                </Button>
              </div>
            </Modal.Footer>
) :
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">
                  <img src={require('../../../common/images/back_arrow.png')} alt="" /> &nbsp;Back</Button>
              </div>
            </Modal.Footer>
          )
      }
    </Modal>
  );
};

