import _ from 'lodash';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import {
  useEffect, useReducer, useRef, useState 
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup 
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { getDepartmentList, getDepartmentName, getActiveUsersList } from '../../../http/requests';

import styles from './department-modal.scss';
import './department.css';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

 
export const DepartmentModal = (props: any) => {
  const [ nameList, setNameList ] = useState([]);
  const [ _nameList, _setNameList ] = useState([]);
  const [ hideOptions, setHideOptions ] = useState(true);
  const initialClientName: any = null;
  const [ selectedClientName, setSelectedClientName ] = useState(initialClientName);

  const [ hideselectedValue, setSelectedValue ] = useState(true);

  const locationFilterInput = useRef<HTMLInputElement>(null);

  const [ ownerSelectedValue, setOwnerSelectedValue ] = useState([]);

  const [ filteredUser, setFilteredUser ] = useState([]);
  const [ departmentList, setDepartmentList ] = useState([]);
  const [userKey, setUserKey] = useState(0);

  const departmentInitialData = {
    departmentName: '',
    departmentOwnerList: [],
    dateCreated: '',
  };
  const departmentDataReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'Add_Department':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  const [ departmentData, dispatchDepartmentData ] = useReducer(departmentDataReducer, departmentInitialData);
  const [ formError, setFormError ] = useState({
    departmentName: '',
    departmentOwnerList: '',
  });
  
  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(()=>{
      if(props.activeTab==='department'){
        fetchAndSetUserData();
      }
  },[props.activeTab]);

  useEffect(() => {
    if(props.data.header && props.data.header !='View Department'){
      getDepartmentName().then((res:any)=>{
        setNameList(res);
        _setNameList(res);
      })
      .catch((err) => {
        console.log(err);
      });
      fetchDepartmentList();
    }
    fetchAndSetUserData();
    if (props.data && props.data.department) {
      if(props.data.department.departmentOwnerList.length===1 && props.data.department.departmentOwnerList[0].azureID==='00000000-0000-0000-0000-000000000000'){
        setOwnerSelectedValue([]);
        if(getModalType() == 'update'){
          setSelectedClientName(
            {
              departmentID: props.data.department.id, 
              departmentName: props.data.department.departmentName,
            }
          );
          setSelectedValue(false);
          dispatchDepartmentData({
            type: 'Add_Department',
            payload: {
              departmentName: props.data.department.departmentName,
              departmentOwnerList: '',
              dateCreated: moment().format('MM-DD-YYYY'),
            },
          });
        }
        else{
          setSelectedClientName({ departmentID: null, departmentName: ""});
          setSelectedValue(true);
          dispatchDepartmentData({
            type: 'Add_Department',
            payload: {
              departmentName: '',
              departmentOwnerList: '',
              dateCreated: moment().format('MM-DD-YYYY'),
            },
          });
        }
      }
      else{
        const dep_owner = props.data.department.departmentOwnerList;
        
        const selected: any = [];
       
        dep_owner.forEach((d:any)=>{
          selected.push({
            ...d,
            name:d.ownerName,
            nameEmail: `${d.ownerName}|(${d.userEmail})`
          });
        });
        setOwnerSelectedValue(selected);
        if(getModalType() == 'update'){
          setSelectedClientName(
            {
              departmentID: props.data.department.id, 
              departmentName: props.data.department.departmentName,
            }
          );
          setSelectedValue(false);
          dispatchDepartmentData({
            type: 'Add_Department',
            payload: {
              departmentName: props.data.department.departmentName,
              departmentOwnerList: props.data.department.departmentOwnerList,
              dateCreated: moment().format('MM-DD-YYYY'),
            },
          });
        }
        else{
          setSelectedClientName({ departmentID: null, departmentName: ""});
          setSelectedValue(true);
          dispatchDepartmentData({
            type: 'Add_Department',
            payload: {
              departmentName: '',
              departmentOwnerList: props.data.department.departmentOwnerList,
              dateCreated: moment().format('MM-DD-YYYY'),
            },
          });
        }
      }
    } else {
      setSelectedClientName({ departmentID: null, departmentName: ""});
      setOwnerSelectedValue([]);
      dispatchDepartmentData({
        type: 'Add_Department',
        payload: {
          departmentName: '',
          departmentOwnerList: '',
          dateCreated: moment().format('MM-DD-YYYY'),
        },
      });
    }
    return () => {
      removeSelectedClientName();
      setFormError((p: any) => 
        ({
          departmentOwnerList: '',
          departmentName: '',
        }));
    };
  }, [ props.data]);

  const fetchAndSetUserData = () => {
    const filteredData: any = [];
    if(role=='Super Admin'){
      getActiveUsersList().then((res:any) => {
        if(filteredUser.length===0 && locationFilterInput.current){
          if (locationFilterInput.current.searchBox.current.value) {
            setUserKey(prevKey => prevKey + 1);
          }
        }
        res.map((user: any, id: any) => {
          if (user.firstName ) {
            if(user.lastName){
              filteredData.push({
                nameEmail: `${user.firstName} ${user.lastName}|(${user.email})`,
                ...user,
              });
            }
            else{
              filteredData.push({
                nameEmail: `${user.firstName} |(${user.email})`,
                ...user,
              });
            }
          }
        });
        setFilteredUser(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };


  function onSelect(selectedList: any, selectedItem: any) {
    
    const data: any = [];
    selectedList.forEach((s:any)=>{
      if(s && s.azureID){
        data.push(s);
      } else{
        data.push({
          azureID: s.id,
          ownerName: s.firstName + ' '+(s.lastName?s.lastName:''),
          userEmail: s.email,
          userType: s.userType,
        });
      }
    });
    const element = document.getElementsByClassName('searchWrapper')[0];
    element.scrollTop = element.scrollHeight + 50;
    setFormError((p: any) => 
      ({
        ...p,
        departmentOwnerList: '',
      }));

    dispatchDepartmentData({
      type: 'Add_Department',
      payload: { departmentOwnerList: data },
    });
  }

  function onRemove(selectedList: any, removedItem: any) {
    if (selectedList.length <= 0) {
      setFormError((p: any) => 
        ({
          ...p,
          departmentOwnerList: 'Department owner required',
        }));
      dispatchDepartmentData({
        type: 'Add_Department',
        payload: { departmentOwnerList: '' },
      });
    } else {
      dispatchDepartmentData({
        type: 'Add_Department',
        payload: { departmentOwnerList: selectedList },
      });
    }
  }

  const getModalType = () => {
    if (props.data.header === 'Add Department') {
      return 'add';
    } else if (props.data.header === 'Edit Department') {
      return 'update';
    } else if (props.data.header === 'Copy Department') {
      return 'copy';
    }
  };

  const fetchDepartmentList = () => {
    getDepartmentList(role).then((res: any) => {
      const data: any = [];
      res.forEach((r:any)=>{
        let name = '';
        r.departmentOwnerList.forEach((d:any)=>
          [
            name += d.ownerName + ', ',
          ]);
        if(name.length > 0){
          name = name.substring(0, name.length - 2);
        }
        data.push({
          ...r,
          departmentOwnerList: name,
        });
      });
      setDepartmentList(_.cloneDeep(res));
    }).catch((err)=>{
      console.log(err);
    });
  };

  const validateForm = (departmentData: any) => {
    let isValid = true;
    if (!departmentData.departmentName) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          departmentName: 'Department name required',
        }));
    } else {
      const modalType = getModalType();
      // if(modalType==='add'||modalType==='copy'){
      let exist = false;
      departmentList.forEach((d: any) => {
        if (d.departmentName.toLowerCase() === departmentData.departmentName.toLowerCase()) {
          exist = true;
          if (props.data && props.data.department && (props.data.department.departmentName.toLowerCase() === departmentData.departmentName.toLowerCase()) && modalType == 'update') {
            exist = false;
          }
        }
      });
      if (exist) {
        setFormError((p: any) => 
          ({
            ...p,
            departmentName: 'Department name already exist',
          }));
        isValid = false;
      } else {
        setFormError((p: any) => 
          ({
            ...p,
            departmentName: '',
          }));
      }
      if (departmentData.departmentName.length > 100) {
        setFormError((p: any) => 
          ({
            ...p,
            departmentName: 'Length should be less then 100',
          }));
        isValid = false;
      }
    }
    if (departmentData.departmentOwnerList.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          departmentOwnerList: 'Department owner required',
        }));
    }


    return isValid;
  };

  const submit = () => {

    const trimmedName = departmentData.departmentName.trim();
    const data=departmentData;
    data.departmentName=trimmedName;

    dispatchDepartmentData({
      type: 'Add_Department',
        payload: { departmentName: trimmedName },  
    })
    setSelectedClientName((p: any) => 
    ({
      ...p,
      departmentName: trimmedName,
    }));


    if (validateForm(data)) {
      if (props.data && props.data.department) {
        props.data.submit({
          departmentData:data,
          id: props.data.department.id,
          type: getModalType(),
          loading: false,
        });
      } else {
        
        props.data.submit({
          departmentData:data,
          type: getModalType(),
          loading: false,
        });
      }
    }
  };

  const changeValue = (input: any) => {
    if (input && input.departmentName) {
      setFormError((p: any) => 
        ({
          ...p,
          departmentName: '',
        }));
    }

    if (input && input.departmentOwnerList) {
      setFormError((p: any) => 
        ({
          ...p,
          departmentOwnerList: '',
        }));
    }

    dispatchDepartmentData({
      type: 'Add_Department',
      payload: { ...input },
    });
  };

  function transformDate(date: any) {
    const a = date.split('-');
    return `${a[2].slice(0, 2)}/${a[1]}/${a[0]}`;
  }

  const getFullName = (user: any) => 
    user.firstName + ' ' + user.lastName;

  const selectedValueDecorator = (val: any) => 
    (
      <small>{val.split('|')[0]}</small>
    );

  const optionValueDecorator = (val: any) => 
    (
      val?<p>{val.replace('|', ' ')}</p>:<p></p>
    );

  const removeSelectedClientName = () => {
    dispatchDepartmentData({
      type: 'Add_Department',
      payload: { departmentName: '' },
    });
    setSelectedClientName(null);
  };

  const filterLocations = (search: string) => {
    if (search) {
      const copy = _.clone(nameList);
      const filtered = copy.filter((d: any) => {
        if (d.departmentName.toLowerCase().includes(search.toLowerCase())) {
          return d;
        }
      });
      setNameList(filtered);
    } else {
      setNameList(_nameList);
    }
  };

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.data.header == 'View Department' ?
            (
              <>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>Department Name</div>
                    <div className={styles.view_department}>{props.data.department.departmentName}</div>
                  </Col>
                  <Col >
                    <div className={styles.department_heading}>Date Created</div>
                    <div className={styles.view_department}>{transformDate(props.data.department.dateCreated)}</div>
                  </Col>
                  
                </Row>
                <Row className="mt-5">
                  <Col>
                    <div className={styles.department_heading}>Department Owner/s</div>
                    <div className={styles.view_department_owners}>{
                      props.data.department.departmentOwnerList.map((d:any)=>
                        <p key={d.azureID}>{d.ownerName} &nbsp;</p>)
                    }</div>
                  </Col>
                </Row>
              </>
            ) :
            (
              <Form>
                <Row className="mt-3">
                    <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label className={styles.department_heading}>Department Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                     
                      <div className={styles.LocationBar}>
                        
                        <Form.Control
                          className={styles.LocationBarFormControl}
                          type="text"
                          value={selectedClientName?.departmentName}
                          onChange={(e) => {
                            setSelectedClientName({departmentID: null, departmentName: e.target.value});
                            dispatchDepartmentData({
                              type: 'Add_Department',
                              payload: { departmentName: e.target.value },
                            });
                            setFormError(p => 
                              ({
                                ...p,
                                departmentName: '',
                              }));
                          }}
                          
                        />
                       
                      </div>
                      {
                        formError.departmentName ? <span className={styles.error}>{formError.departmentName}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col >

                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label className={styles.department_heading}>Date Created</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className={styles.date_created}
                          disabled
                          type="text"
                          value={moment().format('MM/DD/YYYY')}
                        />
                        <InputGroup.Text className={styles.calendar}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label className={styles.department_heading}>Department Owner/s
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                      <Multiselect
                        key={userKey}
                        ref={locationFilterInput}
                        options={filteredUser} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        selectedValues={ownerSelectedValue} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="nameEmail" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.qwe}
                        showArrow={true}
                        showCheckbox={true}
                        loading={filteredUser.length===0?true:false}
                        placeholder="Select"
                        selectedValueDecorator={val => 
                          selectedValueDecorator(val)}
                        optionValueDecorator={val => 
                          optionValueDecorator(val)}
                      />
                      {
                        formError.departmentOwnerList ? <span className={styles.error}>{formError.departmentOwnerList}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )
        }

      </Modal.Body>
      {
        (props.data.header != 'View Department') ?
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              </div>
              <div>
                <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                  {getModalType() == 'add' ? 'Add Department' : getModalType() == 'update' ? 'Update Department' : 'Copy Department'}
                </Button>
              </div>
            </Modal.Footer>
) :
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">
                  <img src={require('../../../common/images/back_arrow.png')} alt="" /> &nbsp;Back</Button>
              </div>
            </Modal.Footer>
          )
      }
    </Modal>
  );
};

