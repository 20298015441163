import { Accordion, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import styles from './sftp.scss';
import { useEffect, useState } from 'react';
import SftpCredential from './sftp-credential/sftp-credential';
import SftpCredentialUser from './sftp-credential-user/sftp-credential-user';
import ReportScheduling from './reports-scheduling/report-scheduling';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/reducers';

function SFTP() {
  const [activeKey, setActiveKey] = useState<string | string[] | null | undefined>(null);
  const [ activeKeyTab, setActiveKeyTab ] = useState('admin');
  const [ activeEdit, setActiveEdit ] = useState(false);
  const [ sftpDataPresent, setSftpDataPresent ] = useState(false);
  const [activeKeyScheduling, setActiveKeyScheduling] = useState<string | string[] | null | undefined>(null);
  const [ activeKeyTabScheduling, setActiveKeyTabScheduling ] = useState('admin');

  const [ reportSheduleModalShow, setReportSheduleModalShow ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ isAdmin, setIsAdmin ] = useState(false);

  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(()=>{
    if( activeKey==null && activeKeyTab=="user"){
      setActiveKeyTab("admin");
    }
    if( activeKeyScheduling==null && activeKeyTabScheduling=="user"){
      setActiveKeyTabScheduling("admin");
    }
  },[activeKey, activeKeyScheduling]);

  useEffect(() => {
    setIsAdmin((role == 'Super Admin') ? true : false);
    },[activeKey, activeKeyScheduling,activeKeyTabScheduling,activeKeyTab]);
  
  useEffect(() => {
    if(sftpDataPresent){
      setActiveKeyScheduling('0');
    }
    },[sftpDataPresent]);


  const editSFTP = (e:any)=>{
    e.stopPropagation();
    setActiveEdit(p =>
      !p);
    if(activeKey==null){
      setActiveKey('0');
    }
  };

  const addReportSchedule = (e:any) => {
    e.stopPropagation();
    if(!sftpDataPresent && activeKeyTabScheduling=='admin'){
      return;
    }
    if(activeKeyScheduling==null){
      setActiveKeyScheduling('0');
    }
    setModalData({
      header: 'Add Report'
    });
    setReportSheduleModalShow(true);
  };

  return (
    <>
        <div className={`${styles.container}`}>
          <Accordion onSelect={(eventKey) => setActiveKey(eventKey === activeKey ? null : eventKey)} activeKey={activeKey} flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix={styles.head} >
                <span className={styles.header} >SFTP CREDENTIAL</span>
              <div className={styles.headerRight}>
                {activeKeyTab=="admin" && sftpDataPresent && ( 
                activeEdit? <img  onClick={editSFTP} className={styles.edit} src={require('../../common/images/edit_sftp_active.png')} alt="" /> :
                <img onClick={editSFTP} className={styles.edit} src={require('../../common/images/edit_sftp.png')} alt="" />
                )
                }
              </div>
              </Accordion.Header>
              <Accordion.Body style={{padding: '0px'}}>
              
              <Tab.Container id="left-tabs-example" defaultActiveKey="admin" activeKey={activeKeyTab} >
                <div className={styles.sections}>
                {isAdmin && (<Nav className={`${styles.sections}`}>
                    <Nav.Item className={`${styles.section} ${activeKeyTab === 'admin' ? styles.active : ''}`}>
                      <Nav.Link
                        className="element"
                        onClick={() =>
                          setActiveKeyTab('admin')}
                        eventKey="admin"
                      >Admin Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styles.section} ${activeKeyTab === 'user' ? styles.active : ''}`}>
                      <Nav.Link
                        onClick={() =>
                          setActiveKeyTab('user')}
                        eventKey="user"
                      >User Details</Nav.Link>
                    </Nav.Item>
                  </Nav>)}
                </div>
                <hr style={{margin: "0px auto"}}/>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="admin">
                      <SftpCredential activeKey={activeKey} setActiveKey={setActiveKey} activeKeyTab={activeKeyTab} activeEdit={activeEdit} setActiveEdit={setActiveEdit} setSftpDataPresent={setSftpDataPresent} sftpDataPresent={sftpDataPresent}/>
                      
                    </Tab.Pane>
                    <Tab.Pane eventKey="user">
                      <SftpCredentialUser activeKey={activeKey} setActiveKey={setActiveKey} activeKeyTab={activeKeyTab} isAdmin={isAdmin}/>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
              
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {(sftpDataPresent||isAdmin) && (<div className={`${styles.container}`}>
          <Accordion onSelect={(eventKey) => setActiveKeyScheduling(eventKey === activeKeyScheduling ? null : eventKey)} activeKey={activeKeyScheduling} flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix={styles.head} >
                <span className={styles.header} >REPORTS SCHEDULING</span>
              <div className={styles.headerRight}>
                <img className={styles.edit} onClick={addReportSchedule} src={require('../../common/images/plusButtonAdd.png')} alt="" />
              </div>
              </Accordion.Header>
              <Accordion.Body style={{padding: '0px'}}>
              
              <Tab.Container id="left-tabs-example" defaultActiveKey="admin" activeKey={activeKeyTabScheduling} >
                <div className={styles.sections}>
                  {isAdmin && (<Nav className={`${styles.sections}`}>
                    <Nav.Item className={`${styles.section} ${activeKeyTabScheduling === 'admin' ? styles.active : ''}`}>
                      <Nav.Link
                        className="element"
                        onClick={() =>
                          setActiveKeyTabScheduling('admin')}
                        eventKey="admin"
                      >Admin Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styles.section} ${activeKeyTabScheduling === 'user' ? styles.active : ''}`}>
                      <Nav.Link
                        onClick={() =>
                          setActiveKeyTabScheduling('user')}
                        eventKey="user"
                      >User Details</Nav.Link>
                    </Nav.Item>
                  </Nav>)}
                </div>
                <hr style={{margin: "0px auto"}}/>
                <div>
                  <Tab.Content>
                  <ReportScheduling activeKeyScheduling={activeKeyScheduling} setActiveKeyScheduling={setActiveKeyScheduling} activeKeyTabScheduling={activeKeyTabScheduling} 
                                    reportSheduleModalShow={reportSheduleModalShow} setReportSheduleModalShow={setReportSheduleModalShow} 
                                    modalData={modalData} setModalData={setModalData} isAdmin={isAdmin}/>
                  </Tab.Content>
                </div>
              </Tab.Container>
              
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>)}
    </>
  );
}

export default SFTP;
