import { HttpMethod } from '@enums';
import { BASE_URL, EncouterServices, Users } from '@http/api';
import { httpRequest } from '@utils';



export const getActiveUsersList = () =>
  httpRequest({
    url: `${Users.USERS}/active`,
    method: HttpMethod.GET,
  });

export const getActiveUsers = () =>
    httpRequest({
      url: `${Users.USERDETAILS}/getActiveUsers`,
      method: HttpMethod.GET,
    });

export const getLoginDetails = () =>
  httpRequest({
    url: `${Users.LoginDetails}`,
    method: HttpMethod.GET,
  });

export const addUser = (data:any) =>
  httpRequest({
    url: `${Users.USERDETAILS}/postUser`,
    method: HttpMethod.POST,
    data,
  });

export const updateUser = (data:any, id:string) =>
  httpRequest({
    url: `${Users.USERDETAILS}/updateUser/${id}`,
    method: HttpMethod.PUT,
    data,
  });

export const getUsers = () =>
  httpRequest({
    url: `${Users.USERDETAILS}/getUsers`,
    method: HttpMethod.GET,
  });


export const getUserById = (id:string) =>

  httpRequest({
    url: `${Users.USERDETAILS}/getUser/${id}`,
    method: HttpMethod.GET,
  });

export const deleteUser = (id:string) =>

  httpRequest({
    url: `${Users.USERDETAILS}/delete-user/${id}`,
    method: HttpMethod.DELETE,
  });

export const getReportDropdown = () =>
  httpRequest({
    url: `${BASE_URL}/GetReportDropdowns/getReportIdName`,
    method: HttpMethod.GET,
  });

export const loginRecord = (data:any) =>
  httpRequest({
    url: `${BASE_URL}/UserRecord/loginRecord`,
    method: HttpMethod.POST,
    data,
  });

export const getLoginRecord = (data:any) =>
  httpRequest({
    url: `${BASE_URL}/UserRecord/getLoginRecord`,
    method: HttpMethod.POST,
    data,
  });
