export enum Namespaces {
  LOCALE = 'LOCALE',
  ROUTER = 'router',
  USER = 'USER',
  UI = 'UI',
  APPSTATE = 'APPSTATE',
  SITE = 'SITE',
  CARE_PLAN = 'CARE_PLAN',
  ANNOUNCMENT = 'ANNOUNCMENT',
  ITEMMASTERPROGRAM = 'ITEMMASTERPROGRAM'
}
