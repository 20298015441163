// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main--n8m0tHJx{height:35px;background:#0057ed;display:flex;gap:1rem;align-items:center}.main2--qYDNfuoo{min-height:35px;background:#0057ed;display:flex;gap:1rem;align-items:center}.icon--JysiIl6X{height:20px;width:26px;margin-left:10px;color:#fff !important}.bold--B15OMVuh{font-weight:700;font-size:16px;line-height:1.4rem;color:#fff !important}.text--yu-aC-qm{font-weight:400;font-size:14px;margin-right:10px;color:#fff !important}.read_more--RKm07eu7{font-weight:700;font-size:14px;line-height:17px;text-decoration-line:underline;color:#fff !important;margin-right:20px}", "",{"version":3,"sources":["webpack://./src/components/announcement/announcement.scss"],"names":[],"mappings":"AAAA,gBACI,WAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CAGJ,iBACI,eAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CAGJ,gBACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,qBAAA,CAGJ,gBACI,eAAA,CACA,cAAA,CACA,kBAAA,CACA,qBAAA,CAGJ,gBACI,eAAA,CACA,cAAA,CACA,iBAAA,CACA,qBAAA,CAGJ,qBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,8BAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":[".main{\r\n    height: 35px;\r\n    background: #0057ED;\r\n    display: flex;\r\n    gap: 1rem;\r\n    align-items: center;\r\n}\r\n\r\n.main2{\r\n    min-height: 35px;\r\n    background: #0057ED;\r\n    display: flex;\r\n    gap: 1rem;\r\n    align-items: center;\r\n}\r\n\r\n.icon{\r\n    height: 20px;\r\n    width: 26px;\r\n    margin-left: 10px;\r\n    color: white !important;\r\n}\r\n\r\n.bold{\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    line-height: 1.4rem;\r\n    color: white !important;\r\n}\r\n\r\n.text{\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    margin-right: 10px;\r\n    color: white !important;\r\n}\r\n\r\n.read_more{\r\n    font-weight: 700;\r\n    font-size: 14px;\r\n    line-height: 17px;\r\n    text-decoration-line: underline;\r\n    color: white !important;\r\n    margin-right: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "main--n8m0tHJx",
	"main2": "main2--qYDNfuoo",
	"icon": "icon--JysiIl6X",
	"bold": "bold--B15OMVuh",
	"text": "text--yu-aC-qm",
	"read_more": "read_more--RKm07eu7"
};
export default ___CSS_LOADER_EXPORT___;
