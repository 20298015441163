export enum BaseRoutes {
  care_ally = '/care-ally',
  patient = '/patient',
  collaboration_view = '/',
}

export const CollaborationViewRoutes = {
  home: BaseRoutes.collaboration_view,
  welcome: `${BaseRoutes.collaboration_view}welcome-page`,
  appointment: `${BaseRoutes.collaboration_view}appointment`,
  startAppointment: (...params: string[]) =>
    `${BaseRoutes.collaboration_view}appointment/${params[0]}/${params[1]}/${params[2]}${AppointmentModulesRoutes.MODULE_OVERVIEW}`,
};

