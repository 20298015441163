import styles from './report-scheduling.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import { useEffect, useState } from 'react';
import { addReportSchedule, deleteReportSchedule, editReportSchedule, getAllReportSchedule, getAllSFTPLogs, getReportSchedule, getSFTPLogs, updateReportScheduleStatus } from '@http/requests/sftp';
import { InputGroup, Form, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { ConfirmationModal } from '@components/modals/confirmation-modal/confirmation-modal';
import { ReportSchedulingModal } from '@components/modals/report-scheduling-modal';


export default function ReportScheduling(props: any) {
    
  const [ allReportSchedule, setAllReportSchedule ] = useState([]);
  const [ ReportSchedule, setReportSchedule ] = useState([]);
  const [ allLogDetails, setAllLogDetails ] = useState([]);
  const [ logDetails, setLogDetails ] = useState([]);
  const [ _allLogDetails, set_AllLogDetails ] = useState([]);
  const [ _logDetails, set_LogDetails ] = useState([]);
  //const [ isAdmin, setIsAdmin ] = useState(true);
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(false);
  const [ showUpdateConfirmation, setShowUpdateConfirmation ] = useState(false);
  const [ deleteId, setDeleteId ] = useState('');
  const [ deleteUserId, setDeleteUserId ] = useState('');
  const [ updateData, setupdateData ] = useState(null);

  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ keySchedule, setkeySchedule ] = useState(0);
  const [ keyLog, setkeyLog ] = useState(0);


    useEffect(()=>{
      setGlobalSearch('');
      setSearchboxError('');
      if(props.activeKeyTabScheduling=="user"){
        fetchAndSetAllReportSchedule();
        fetchAndSetAllSFTPLogs();

      }
      else{
        fetchAndSetReportSchedule();
        fetchAndSetSFTPLogs();
      }

      setkeySchedule((p)=>p+1);
      setkeyLog((p)=>p+1);

    },[props.activeKeyTabScheduling]);

    useEffect(()=>{
      fetchAndSetAllReportSchedule();
      fetchAndSetAllSFTPLogs();
    },[]);

    useEffect(()=>{
      if(props.reportSheduleModalShow && props.modalData.header=='Add Report'){
        props.setModalData((p:any)=>(
          {
            ...p,
            activeTab: props.activeKeyTabScheduling,
            submit
          }
        ));
      }

    },[props.reportSheduleModalShow])


    function transformDate(date: any) {
      const a = date.split('-');
      return `${a[1]}/${a[2].slice(0, 2)}/${a[0]}`;
    }

    const fetchAndSetAllReportSchedule = () => {
      if(props.isAdmin)
        {
          getAllReportSchedule().then((res: any)=>{
            const data: any = [];
            res.forEach((r:any)=>{
              data.push({
                ...r,
                nextSchedule: transformDate(r.nextSchedule),
                //to-do
                id: `${r.user.azureID}-${r.reportID}`,
              });
            });
    
            setAllReportSchedule(_.cloneDeep(data));
          }).catch((err)=>{
            console.log(err);
          });
        }
    };

    const fetchAndSetReportSchedule = () => {
      getReportSchedule().then((res: any)=>{
        const data: any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            nextSchedule: transformDate(r.nextSchedule),
            id: r.reportID,
          });
        });

        setReportSchedule(_.cloneDeep(data));
      })
      .catch((err) => {
        console.log(err);
      });
    };


    const fetchAndSetAllSFTPLogs = () => {
      if(props.isAdmin){
        getAllSFTPLogs().then((res: any)=>{
          const data: any = [];
          res.forEach((r:any)=>{
            data.push({
              ...r,
              uploadDate: transformDate(r.uploadDate),
              id: `${r.azureID}-${r.reportID}-${r.reportPageID}-${r.uploadDate}`,
              userNameEmail: `${r.firstName} ${r.lastName}(${r.userEmail})`
            });
          });
  
          setAllLogDetails(_.cloneDeep(data));
          set_AllLogDetails(_.cloneDeep(data));
        }).catch((err)=>{
          console.log(err);
        });
      }
    };


    const fetchAndSetSFTPLogs = () => {
      getSFTPLogs().then((res: any)=>{
        const data: any = [];
        res.forEach((r:any)=>{
          data.push({
            ...r,
            uploadDate: transformDate(r.uploadDate),
            id: `${r.reportID}-${r.reportPageID}-${r.uploadDate}`,
          });
        });

        setLogDetails(_.cloneDeep(data));
        set_LogDetails(_.cloneDeep(data));
      })
      .catch((err) => {
        console.log(err);
      });
    };

    const submit = (data: any) => {
      props.setReportSheduleModalShow(false);
      if (data.type == 'add') {

        addReportSchedule(data.data,data.azureId).then((res) => {
          setShowSuccess(true);
          setShowSuccessMsg('Report Scheduled Successfully');
          setTimeout(() => {
            setShowSuccess(false);
            setShowSuccessMsg('');
          }, 1000);
          fetchAndSetReportSchedule();
          fetchAndSetAllReportSchedule();
        })
        .catch((error) => {
          // Handle the error here
          fetchAndSetReportSchedule();
          fetchAndSetAllReportSchedule();
        });
      } else if (data.type == 'update') {
        setupdateData(data);
        setShowUpdateConfirmation(true);
      }
    };
    
    const globalSearch = (input: string) => {
      setGlobalSearch(input);
      let sftpLogsCopy;
      if(props.activeKeyTabScheduling=='user'){
        sftpLogsCopy = _.cloneDeep(_allLogDetails);
      }
      else{
        sftpLogsCopy = _.cloneDeep(_logDetails);
      }


      if (input) {
        const list = sftpLogsCopy.filter((sftpLog: any) => {
          if (sftpLog.reportName.toLowerCase().includes(input.toLowerCase())
          || sftpLog.pageName.toLowerCase().includes(input.toLowerCase())
          || sftpLog.frequency.toLowerCase().includes(input.toLowerCase())
          || sftpLog.uploadDate.toLowerCase().includes(input.toLowerCase())
          || ('success'.includes(input.toLowerCase()) && sftpLog.uploadStatus==1)
          || ('fail'.includes(input.toLowerCase()) && sftpLog.uploadStatus==0)
          || (props.activeKeyTabScheduling=='user' && sftpLog.userNameEmail.toLowerCase().includes(input.toLowerCase()))) {
            return sftpLog;
          }
        });

        if(list.length == 0){
          setSearchboxError(input + ' does not exist.');
        } else{
          setSearchboxError('');
        }

        if(props.activeKeyTabScheduling=='user'){
          setAllLogDetails(list);
        }
        else{
          setLogDetails(list);
        }
      } else {
        setSearchboxError('');
        if(props.activeKeyTabScheduling=='user'){
          setAllLogDetails(sftpLogsCopy);
        }
        else{
          setLogDetails(sftpLogsCopy);
        }
      }
    };

    const onHideDeleteConfirmation = () =>
      setShowDeleteConfirmation(false);

    const onHideUpdateConfirmation = () =>
      setShowUpdateConfirmation(false);

    const handleSuccessClose = () =>
      setShowSuccess(false);

   

    const deleteReportScheduleConfirm = (id: string, userId: string="") => {
      setDeleteId(id);
      setDeleteUserId(userId);
      setShowDeleteConfirmation(true);
    };

    const deleteReportScheduleById = ()=>{
      if (deleteId) {
        setShowDeleteConfirmation(false);
        deleteReportSchedule(deleteId, deleteUserId).then((res) => {
          fetchAndSetAllReportSchedule();
          fetchAndSetReportSchedule();
        })
        .catch((error) => {
          // Handle the error here
          fetchAndSetAllReportSchedule();
          fetchAndSetReportSchedule();
        });
      }
    };

    const updateReportScheduleById = ()=>{
      setShowUpdateConfirmation(false);
      if(updateData){
        editReportSchedule(updateData.data, updateData.azureId).then((res) => {
          setShowSuccess(true);
          setShowSuccessMsg('Report Schedule Updated Successfully');
          setTimeout(() => {
            setShowSuccess(false);
            setShowSuccessMsg('');
          }, 1000);
          fetchAndSetAllReportSchedule();
          fetchAndSetReportSchedule();
        })
        .catch((error) => {
          // Handle the error here
          fetchAndSetAllReportSchedule();
          fetchAndSetReportSchedule();
        });
      } 
    };


    const scheduleStatus = (id: string ,value: any, userId: string="") => {
      const data = {
        status: value,
      };
      updateReportScheduleStatus(id,data,userId).then((res) => {
        fetchAndSetAllReportSchedule();
        fetchAndSetReportSchedule();
      })
      .catch((error) => {
        // Handle the error here
        fetchAndSetAllReportSchedule();
        fetchAndSetReportSchedule();
      });
    }

    const editReportScheduling = (currentRow: any) => {
      props.setModalData({
        header: 'Edit Report',
        activeTab: props.activeKeyTabScheduling,
        submit,
        currentRow
      });

      props.setReportSheduleModalShow(true);
    };

    const isEnable = (row: any) => {
      const style: any = {};
      if (!row.active) {
        style.pointerEvents = 'none';
      }
      return style;
    };

    const rowStyle2 = (row: any, rowIndex: any) => {
      const style: any = {};
      if (!row.active) {
        style.color = 'rgba(114, 114, 114, 0.3)';
      }
  
      return style;
    };

    const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) => 
      (
        <div className={styles.table_actions}>
            <div
              style={isEnable(row)}
              onClick={()=>
                editReportScheduling(row)}
            >
              {row.active ? <img src={require('../../../common/icons/pencil.svg')} alt="" /> :
              <img src={require('../../../common/icons/edit-dull.svg')} alt="" />}
            </div>

            <div
              style={isEnable(row)}
              onClick={()=>{
                  if(props.activeKeyTabScheduling=="user"){
                    deleteReportScheduleConfirm(row.reportID,row.user.azureID);
                  }
                  else{
                    deleteReportScheduleConfirm(row.reportID);
                  }
                }
              }
            >
              {row.active ? <img src={require('../../../common/icons/delete.svg')} alt="" /> :
              <img src={require('../../../common/icons/delete-dull.svg')} alt="" />}
            </div>

            <div
              onClick={()=>{
                  if(props.activeKeyTabScheduling=="user"){
                    scheduleStatus(row.reportID, !row.active,row.user.azureID);
                  }
                  else{
                    scheduleStatus(row.reportID, !row.active);
                  }
                }
              }
            >
              {row.active ? <img style={{height:'21px', width:'21px'}} src={require('../../../common/icons/pause.svg')} alt="" /> :
              <img style={{height:'21px'}} src={require('../../../common/icons/play-circle.svg')} alt="" />}
            </div>
        </div>
      );

    const columns = [
      {
        dataField: 'reportName',
        text: 'REPORTS',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
        //style: { width: '24rem' },
      },
      {
        dataField: 'frequency.frequency',
        text: 'FREQUENCY',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
      },
      {
        dataField: 'nextSchedule',
        text: 'NEXT SCHEDULE',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
      },
      ...(props.activeKeyTabScheduling=="user"?[{
        dataField: 'user',
        text: 'USER NAME',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
          formatter: (cell, row) =>(
            <>
                <span>
                  {row.user.firstName+" "+row.user.lastName+"("+row.user.userEmail+")"}
                </span>
            </>   
          )
      }]:[]
      ),
      {
        dataField: 'action',
        text: 'ACTION',
        formatter: linkFollow,
        sort: true,
        headerAlign: 'center',
        style: { width: '18rem' }
      }
    ];

    const logColumns = [
      {
        dataField: 'uploadDate',
        text: 'DATE',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
        style: { width: '24rem' },
      },
      {
        dataField: 'reportName',
        text: 'REPORT NAME',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
      },
      {
        dataField: 'pageName',
        text: 'REPORT PAGE',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
      },
      ...(props.activeKeyTabScheduling=="user"?[{
        dataField: 'user',
        text: 'USER NAME',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
          formatter: (cell, row) =>(
            <>
                <span>
                  {row.firstName+" "+row.lastName+"("+row.userEmail+")"}
                </span>
            </>
    
          )
      }]:[]
      ),
      {
        dataField: 'frequency',
        text: 'FREQUENCY',
        sort: true,
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
      },
      {
        dataField: 'uploadStatus',
        text: 'STATUS',
        sort: true,
        style: { width: '20rem' },
        sortCaret: (order: any, column: any) =>
          (
            <span> &nbsp;
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
              <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
            </span>
          ),
        formatter: (cell, row) =>(
          <>
              {
                row.uploadStatus?
                (<div className={styles.success}>Success</div>):
                (<div className={styles.fail}>Fail</div>)
              }
          </>
        ),
        classes: styles.uploadStatus,
      },
    ];

  return (
    <>
      <div id={styles.reportScheduling}>
        <ReportSchedulingModal
            data={props.modalData}
            show={props.reportSheduleModalShow}
            onHide={() =>
              props.setReportSheduleModalShow(false)}
        />
          <div className={styles.table}>
              <BootstrapTable
                  key={keySchedule}
                  rowStyle={rowStyle2}
                  bootstrap5
                  bordered={false}
                  trClassName={styles.customRow}
                  keyField="id"
                  data={props.activeKeyTabScheduling=="user"? allReportSchedule:ReportSchedule}
                  columns={columns}
                  headerClasses={styles.fixed_header}
              />
          </div>
          <div className={styles.heading}>
            LOGS DETAILS
          </div>
          <div className={styles.filter}>
              <div className={styles.search}>
                <InputGroup>
                  <InputGroup.Text className={styles.search_logo}>
                    <img src={require('../../../common/images/search.png')} alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    value={globalSearchField}
                    //id="global-search"
                    onChange={e =>
                      globalSearch(e.target.value)}
                    className={styles.search_field}
                    type="text"
                    placeholder="Search by Report Name, Page Name"
                  />
                </InputGroup>
                {
                  searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
                }
              </div>
          </div>
          <div className={styles.table}>
              <BootstrapTable
                  key={keyLog}
                  bootstrap5
                  bordered={false}
                  trClassName={styles.customRow}
                  keyField="id"
                  data={props.activeKeyTabScheduling=="user"? allLogDetails:logDetails}
                  columns={logColumns}
                  headerClasses={styles.fixed_header}
              />
          </div>
      </div>
      <ConfirmationModal submit={deleteReportScheduleById} show={showDeleteConfirmation} onHide={onHideDeleteConfirmation} message="You want to delete the Report Schedule?"/>
      <ConfirmationModal submit={updateReportScheduleById} show={showUpdateConfirmation} onHide={onHideUpdateConfirmation} message="You want to update the Report Schedule?"/>

      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>
        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
