import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import { Namespaces } from '../namespaces';

import announcmentReducer from './announcment';
import itemMasterReducer from './item-master';
import { appStateReducer } from './app-state';
import { localeReducer } from './locale';
import { userAuthenticationReducer } from './user';

export const rootReducer = combineReducers({
  [Namespaces.ROUTER]: connectRouter(createBrowserHistory()),
  [Namespaces.LOCALE]: localeReducer,
  [Namespaces.USER]: userAuthenticationReducer,
  [Namespaces.APPSTATE]: appStateReducer,
  [Namespaces.ANNOUNCMENT]: announcmentReducer,
  [Namespaces.ITEMMASTERPROGRAM]: itemMasterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
