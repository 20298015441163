import { FONT_MEDIUM } from '@components/font-size-setting/helper';
import { AppStateAction, AppStateType } from '@redux/actions';

type AppState = {
  isSpinnerShown: boolean;
  isExceptionsModalShown: boolean;
  errorDate: string | null;
	xCorrId: string | null;
  fontSize:string;
};

const appState: AppState = {
  isSpinnerShown: false,
  isExceptionsModalShown: false,
  errorDate: null,
  xCorrId: null,
  fontSize:FONT_MEDIUM,
};

export const appStateReducer = (
  state = appState,
  action: AppStateAction
) => {
  switch (action.type) {
    case AppStateType.SHOW_SPINNER:
      return {
        ...state,
        isSpinnerShown: true,
      };
    case AppStateType.HIDE_SPINNER:
      return {
        ...state,
        isSpinnerShown: false,
      };
    case AppStateType.SHOW_EXCEPTIONS_MODAL:
      return {
        ...state,
        ...action.payload,
        isExceptionsModalShown: true,
      };
    case AppStateType.HIDE_EXCEPTIONS_MODAL:
      return {
        ...state,
        isExceptionsModalShown: false,
      };
    case AppStateType.SET_FONT_SIZE:
      return {
        ...state,
        fontSize:action.payload,
      };
    default:
      return state;
  }
};
