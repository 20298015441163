import { HttpMethod } from '@enums';
import { REPORTSCHEDULE, SFTPDetails} from '@http/api';
import { httpRequest } from '@utils';


export const getSFTPDetail = (): Promise<any> =>
  httpRequest({
    url: `${SFTPDetails}/getSFTPDetails`,
    method: HttpMethod.GET,
  });

  export const postSFTPDetails = (data: any): Promise<any> =>
    httpRequest({
      url: `${SFTPDetails}/postSFTPDetails`,
      method: HttpMethod.POST,
      data,
    });

  export const postSFTPUsersDetails = (data: any): Promise<any> =>{
    return httpRequest({
      url: `${SFTPDetails}/postSFTPUsersDetails`,
      method: HttpMethod.POST,
      data,
    });
  }



export const getAllReportSchedule = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getAllReportSchedule`,
    method: HttpMethod.GET,
  });

export const getReportSchedule = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getReportSchedule`,
    method: HttpMethod.GET,
  });


export const getAllSFTPLogs = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getAllSFTPLogs`,
    method: HttpMethod.GET,
  });

export const getSFTPLogs = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getSFTPLogs`,
    method: HttpMethod.GET,
  });

export const getFrequency = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getFrequency`,
    method: HttpMethod.GET,
  });

export const getSFTPActiveUsers = () =>
  httpRequest({
    url: `${REPORTSCHEDULE}/getSFTPActiveUsers`,
    method: HttpMethod.GET,
  });

  export const getReportPages = (reportId: string): Promise<any> => {
    if(reportId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/getReportPages/${reportId}`,
        method: HttpMethod.GET,
      });
    }
  };


  export const getReports = (frequencyId:number,userId: string=""): Promise<any> => {
    const account = JSON.parse(localStorage.getItem('account'));

    if(userId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/getReports/${userId}/${frequencyId}`,
        method: HttpMethod.GET,
      });
    }
    else if(account && account.localAccountId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/getReports/${account.localAccountId}/${frequencyId}`,
        method: HttpMethod.GET,
      });
    }
    return new Promise((res, rej)=>{
      rej('not login');
    });
  };

  export const deleteReportSchedule = (id: string, userId: string=""): Promise<any> => {
    const account = JSON.parse(localStorage.getItem('account'));

    if(userId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/deleteReportSchedule/${userId}/${id}`,
        method: HttpMethod.DELETE,
      });
    }
    else if(account && account.localAccountId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/deleteReportSchedule/${account.localAccountId}/${id}`,
        method: HttpMethod.DELETE,
      });
    }

    return new Promise((res, rej)=>{
      rej('not login');
    });
  };


  export const updateReportScheduleStatus = (id: string, data:any, userId: string=""): Promise<any> => {
    const account = JSON.parse(localStorage.getItem('account'));

    if(userId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/updateReportScheduleStatus/${userId}/${id}`,
        method: HttpMethod.PUT,
        data
      });
    }
    else if(account && account.localAccountId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/updateReportScheduleStatus/${account.localAccountId}/${id}`,
        method: HttpMethod.PUT,
        data
      });
    }

    return new Promise((res, rej)=>{
      rej('not login');
    });
  };


  export const addReportSchedule = (data: any, userId: string=""): Promise<any> =>{
    const account = JSON.parse(localStorage.getItem('account'));
    if(userId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/postReportSchedule/${userId}`,
        method: HttpMethod.POST,
        data,
      });
    }
    else if(account && account.localAccountId){
      return httpRequest({
        url: `${REPORTSCHEDULE}/postReportSchedule/${account.localAccountId}`,
        method: HttpMethod.POST,
        data,
      });
    }

    return new Promise((res, rej)=>{
      rej('not login');
    });
  }
  
  
  
  export const editReportSchedule = (data: any, userId: string=""): Promise<any> =>{
    const account = JSON.parse(localStorage.getItem('account'));
      if(userId){
        return httpRequest({
          url: `${REPORTSCHEDULE}/postUserReportSchedule/${userId}`,
          method: HttpMethod.POST,
          data,
        });
      }
      else if(account && account.localAccountId){
        return httpRequest({
          url: `${REPORTSCHEDULE}/postUserReportSchedule/${account.localAccountId}`,
          method: HttpMethod.POST,
          data,
        });
      }

      return new Promise((res, rej)=>{
        rej('not login');
      });
  }