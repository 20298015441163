import React from 'react'
import styles from './bulk-upload-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';


export const BulkUploadModal = (props: any)=> {
    let rowIdCounter = 0;
    const itemMasterColumns = [
        {
          dataField: 'ndc',
          text: 'NDC',
          sort: true,
          sortCaret: (order: any, column: any) =>
            (
              <span> &nbsp;
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
              </span>
            ),
        },
        {
          dataField: 'program',
          text: 'Program',
          sort: true,
          sortCaret: (order: any, column: any) =>
            (
              <span> &nbsp;
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
              </span>
            ),
        },
        {
            dataField: 'tat',
            text: 'TAT'
        },
        {
            dataField: 'category',
            text: 'Category'
        },
      ];

      const exceptionsColumns = [
        {
          dataField: 'ndc',
          text: 'NDC',
          sort: true,
          sortCaret: (order: any, column: any) =>
            (
              <span> &nbsp;
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
              </span>
            ),
        },
        {
          dataField: 'program',
          text: 'Program',
          sort: true,
          sortCaret: (order: any, column: any) =>
            (
              <span> &nbsp;
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
                <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
              </span>
            ),
        },
        {
            dataField: 'destination',
            text: 'Destination',
        },
        {
            dataField: 'startDay',
            text: 'Start Day',
        },
        {
            dataField: 'startMonth',
            text: 'Start Month'
        },
        {
            dataField: 'endDay',
            text: 'End Day'
        },
        {
            dataField: 'endMonth',
            text: 'End Month'
        },
        {
            dataField: 'temperatureSetting',
            text: 'Temperature Setting'
        },
      ];

    const downloadCSV = () => {
      let header: string[] = [];
      if (props.type === 1) {
        header = ['ndc', 'program', 'destination', 'startMonth', 'startDay', 'endMonth','endDay','temperatureSetting'];
      } else if (props.type === 2) {
        header = ['ndc', 'program', 'tat', 'category'];
      }
  
      const rows = props.data.map(obj => header.map(fieldName => obj[fieldName] || '').join(','));
      const csvContent = [header.join(','), ...rows].join('\n');
  
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title style={{'display':'flex', 'justifyContent':'space-between', 'width':'100%','paddingRight':'15px'}}>
            Failed Item Master {props.type==1?'Exceptions':''} 
            <Button style={{'height':'fit-content'}} className={styles.download_Btn} onClick={downloadCSV}>
              Download CSV
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_table}>
          {props.type==1?
            <div className={styles.table}>
                    <BootstrapTable
                        bootstrap5
                        bordered={false}
                        trClassName={styles.customRow}
                        keyField="id"
                        data={props.data.map(row => ({...row, id: rowIdCounter++}))}
                        columns={exceptionsColumns}
                        headerClasses={styles.fixed_header}
                    />
              </div> :
              <div className={styles.table}>
              <BootstrapTable
                  bootstrap5
                  bordered={false}
                  trClassName={styles.customRow}
                  keyField="id"
                  data={props.data.map(row => ({...row, id: rowIdCounter++}))}
                  columns={itemMasterColumns}
                  headerClasses={styles.fixed_header}
              />
            </div>
          }
          </Modal.Body>
        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">Close</Button>
          </div>
        </Modal.Footer>
    </Modal>
  );
}
