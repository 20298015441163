import { HttpMethod } from '@enums';
import { Setting, ANNOUNCEMENT } from '@http/api';
import { httpRequest } from '@utils';

export const getAnnouncements = (): Promise<any> =>
  httpRequest({
    url: `${ANNOUNCEMENT}/getAnnouncements`,
    method: HttpMethod.GET,
  });

export const postAnnouncement = (data:any): Promise<any> =>
  httpRequest({
    url: `${ANNOUNCEMENT}/postAnnouncement`,
    method: HttpMethod.POST,
    data,
  });
