import moment from 'moment';
import {
  useEffect, useReducer, useRef, useState 
} from 'react';
import {
  Modal, Row, Col, Form, InputGroup 
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';

import { getDepartmentList, getAllClients, getProgramNameList, getAvailableProgram } from '../../../http/requests';

import styles from './program-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';


import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import './program.css';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

export const ProgramModal = (props: any) => {
  const initialData = {
    clientID: '',
    programName: [],
    dateCreated: '',
    startDate: '',
    endDate: '',
  };

  const intialnameList:any = [];

  const initialFormError: {
    clientName: string,
    programName: string,
    startDate: string,
    endDate: string,
  } = {
    clientName: '',
    programName: '',
    startDate: '',
    endDate: '',
  };

  const dataReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'Add_Data':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [ startDate, setStartDate ] = useState('');
  const [ uploadedFileName, setUploadedFileName ] = useState<string | null>(null);
  //const [ inputFile, setInputFile ] = useState<HTMLInputElement | null>(null);
  //const inputRef = useRef<HTMLInputElement>(null);

  const [ hideOptions, setHideOptions ] = useState(true);
  const initialClientName: any = null;
  const [ selectedClientName, setSelectedClientName ] = useState(initialClientName);

  const [ hideselectedValue, setSelectedValue ] = useState(true);

  const locationFilterInput = useRef<HTMLInputElement>(null);

  const [ hideOptions2, setHideOptions2 ] = useState(true);
  const [ selectedProgramName, setSelectedProgramName ] = useState(initialClientName);

  const [ hideselectedValue2, setSelectedValue2 ] = useState(true);

  const locationFilterInput2 = useRef<HTMLInputElement>(null);

  const [ ownerSelectedValue, setOwnerSelectedValue ] = useState([]);

  const [ departmentList, setDepartmentList ] = useState([]);
  const [ nameList, setNameList ] = useState(intialnameList);
  const [ _nameList, _setNameList ] = useState(intialnameList);
  const [ nameList2, setNameList2 ] = useState([]);
  const [ _nameList2, _setNameList2 ] = useState([]);
  const [ modalData, dispatchData ] = useReducer(dataReducer, initialData);
  const [ formError, setFormError ] = useState(initialFormError);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputFocused2, setIsInputFocused2] = useState(false);
  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole) || 'User';

  useEffect(() => {
    if(props.data.header && props.data.header !="View Program"){
      getAllClients().then((res)=>{
        setNameList(res);
        _setNameList(res);
      })
      .catch((err) => {
        console.log(err);
      });
      getAvailableProgram().then((res)=>{
        setNameList2(res);
        _setNameList2(res);
      })
      .catch((err) => {
        console.log(err);
      });
      //setInputFile(document.getElementById('input-file') as HTMLInputElement);
      //fetchDepartmentList();
    }


    if (props.data && props.data.currentRow) {
      setSelectedValue(false);
      setSelectedValue2(false);
      setSelectedClientName({
        clientName: props.data.currentRow.clientName,
        clientAzureID: props.data.currentRow.id,
      });
      if(getModalType() == 'update'){
        setSelectedProgramName({
          programName: props.data.currentRow.programName,
          programID: props.data.currentRow.programID,
        });
        dispatchData({
          type: 'Add_Data',
          payload: {
            clientName: props.data.currentRow.clientName,
            clientID: props.data.currentRow.clientID,
            programName: props.data.currentRow.programName,
            dateCreated: moment().format('MM-DD-YYYY'),
            startDate: props.data.currentRow.startDate,
            endDate: props.data.currentRow.endDate,
          },
        });
      } else{
        dispatchData({
          type: 'Add_Data',
          payload: {
            clientName: props.data.currentRow.clientName,
            clientID: props.data.currentRow.clientID,
            programName: '',
            dateCreated: moment().format('MM-DD-YYYY'),
            startDate: props.data.currentRow.startDate,
            endDate: props.data.currentRow.endDate,
          },
        });
      }
    } else {
      setOwnerSelectedValue([]);
      dispatchData({
        type: 'Add_Data',
        payload: {
          clientName: '',
          programName: '',
          dateCreated: moment().format('MM-DD-YYYY'),
          startDate: '',
          endDate: '',
        },
      });
    }
    return () => {
      setUploadedFileName('');
      removeSelectedClientName();
      removeSelectedProgramName();
      setFormError((p: any) => 
        ({
          programName: '',
          clientName: '',
          clientID: '',
          startDate:'',
          endDate:'',
        }));
      dispatchData({
        type: 'Add_Data',
        payload: {
          clientName: '',
          programName: '',
          dateCreated: moment().format('MM-DD-YYYY'),
          startDate:'',
          endDate:'',
        },
      });
    };
  }, [ props.data ]);

  // useEffect(() => {
  //   if(!hideOptions && !hideOptions2) setHideOptions2(true);
  // }, [hideOptions])

  // useEffect(() => {
  //   if(!hideOptions && !hideOptions2) setHideOptions(true);
  // }, [hideOptions2])

  const getModalType = () => {
    if (props.data.header === 'Add Program') {
      return 'add';
    } else if (props.data.header === 'Edit Program') {
      return 'update';
    } else if (props.data.header === 'Copy Program') {
      return 'copy';
    }
  };

  // const fetchDepartmentList = () => {
  //   getDepartmentList(role).then((res) => {
  //     setDepartmentList(_.cloneDeep(res));
  //   }).catch((err)=>{
  //     console.log(err);
  //   });
  // };

  const validateForm = (modalData: any) => {
    
    let isValid = true;

    if (!selectedClientName || !selectedClientName.clientName) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          clientName: 'Client name required',
        }));
    }
    
    if (!selectedProgramName) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          programName: 'Program Name required',
        }));
    }

    if (modalData.startDate.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          startDate: 'Start date required',
        }));
    }
    if (modalData.endDate.length <= 0) {
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          endDate: 'End date required',
        }));
    }

    const mStart = moment(modalData.startDate);
    const mEnd = moment(modalData.endDate);
    if(!mStart.isBefore(mEnd) && !mStart.isSame(mEnd)){
      isValid = false;
      setFormError((p: any) => 
        ({
          ...p,
          endDate: 'End date should be greater than start date.',
        }));
    }
    return isValid;
  };

  const submit = () => {
    if (validateForm(modalData)) {
      delete modalData.clientName;
      delete modalData.programName;
      if (props.data && props.data.currentRow) {
        if(getModalType() == 'update'){
          delete modalData.programID;
          props.data.submit({
            modalData,
            id: props.data.currentRow.programID,
            type: getModalType(),
            loading: false,
          });
        } else{
          props.data.submit({
            modalData,
            id: props.data.currentRow.programID,
            type: getModalType(),
            loading: false,
          });
        }
      } else {
        props.data.submit({
          modalData,
          type: getModalType(),
          loading: false,
        });
      }
    }
  };

  function transformDate(date: any) {
    const a = date.split('-');
    return `${a[2].slice(0, 2)}/${a[1]}/${a[0]}`;
  }

  const filterLocations = (search: string) => {
    if (search) {
      const copy = _.clone(nameList);
      const filtered = copy.filter((d: any) => {
        if (d.clientName.toLowerCase().includes(search.toLowerCase())) {
          return d;
        }
      });
      setNameList(filtered);
    } else {
      setNameList(_nameList);
    }
  };

  const filterLocations2 = (search: string) => {
    if (search) {
      const copy = _.clone(nameList2);
      const filtered = copy.filter((d: any) => {
        if (d.programName.toLowerCase().includes(search.toLowerCase())) {
          return d;
        }
      });
      setNameList2(filtered);
    } else {
      setNameList2(_nameList2);
    }
  };

  const changeValue = (input: any) => {
    if (input && input.programName) {
      setFormError((p: any) => 
        ({
          ...p,
          programName: '',
        }));
    }
    if (input && input.startDate) {
      setFormError((p: any) => 
        ({
          ...p,
          startDate: '',
        }));
    }
    if (input && input.endDate) {
      setFormError((p: any) => 
        ({
          ...p,
          endDate: '',
        }));
    }
    dispatchData({
      type: 'Add_Data',
      payload: { ...input },
    });
  };

  const removeSelectedClientName = () => {
    setSelectedClientName(null);
  };

  const removeSelectedProgramName = () => {
    setSelectedProgramName(null);
  };

  const dateTransformer = (date:Date): string=>
    moment(String(date)).format('MM/DD/YYYY');

  const showTransformedDate = (date = '')=>{    
    // date = date.replaceAll('/', '-');
    if(date){
      return moment(moment(date)).toDate();
    } else{
      return '';
    }
  };

  return (
    <Modal
      {...props}
      backdrop={false}
      size="lg"
      contentClassName={styles.modal_content}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.moo}
    >
      <Modal.Header className={styles.modal_dialog} closeButton>
        <Modal.Title className={styles.title} id="contained-modal-title-vcenter">
          {props.data.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.data.header == 'View Program' ?
            (
              <>
                <Row className="mt-3">
                  <Col>
                    <div className={styles.department_heading}>Client Name</div>
                    <div className={styles.view_department}>{props.data.currentRow.clientName}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>Program Name</div>
                    <div className={styles.view_department}>{props.data.currentRow.programName}</div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <div className={styles.department_heading}>Start Date</div>
                    <div className={styles.view_department}>{props.data.currentRow.startDate}</div>
                  </Col>
                  <Col>
                    <div className={styles.department_heading}>End Date</div>
                    <div className={styles.view_department}>{props.data.currentRow.endDate}</div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xs={6}>
                    <div className={styles.department_heading}>Date Created</div>
                    <div className={styles.view_department}>{transformDate(props.data.currentRow.dateCreated)}</div>
                  </Col>
                  <Col />
                </Row>
              </>
            ) :
            (
              <Form>
                <Row className="mt-3">
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label className={styles.department_heading}>Client Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                     
                      <div className={styles.LocationBar}>
                        {selectedClientName && !hideselectedValue ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedClientName?.clientName}</div>
                            <div
                              className={styles.LocationBarRemoveSelected}
                              onClick={removeSelectedClientName}
                              role="none"
                            >x</div>
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput}
                          className={styles.LocationBarFormControl}
                          type="text"
                          placeholder={selectedClientName ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions(true); setSelectedValue(false); setIsInputFocused(false);}, 500); }}
                          onFocus={() => { setSelectedValue(true);  setIsInputFocused(true);}}
                          onChange={(e) => {
                            setHideOptions(false);
                            filterLocations(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions)
                              setHideOptions(false);
                            // else
                            //     setHideOptions(true);
                            }}
                        />
                        {!hideOptions ? (
                          nameList.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                nameList.map((item, index) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      dispatchData({
                                        type: 'Add_Data',
                                        payload: {
                                          clientID: item.clientID,
                                          clientName: item.clientName,
                                        },
                                      });
                                      setFormError(p => 
                                        ({
                                          ...p,
                                          clientName: '',
                                        }));
                                      setSelectedClientName(item);
                                      setHideOptions(true);
                                      setSelectedValue(false);
                                      locationFilterInput.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.clientName}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No client name found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                          (
                            <div className={styles.LocationBarRightIcon}>
                              <img
                                src={require('../../../common/icons/arrow-down.svg')}
                                role="none"
                                alt="dropdown icon"
                                onClick={() => {
                                  if (isInputFocused && hideOptions){
                                    setHideOptions(false);
                                  }
                                  else if( !isInputFocused && hideOptions){
                                    setHideOptions(false);
                                    locationFilterInput.current?.focus();
                                    setIsInputFocused(true);
                                  }
                                  else{
                                    setHideOptions(true);
                                    setIsInputFocused(false);
                                  }
                                }}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        formError.clientName ? <span className={styles.error}>{formError.clientName}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                  <Col className={getModalType() == 'update' ? styles.disable : ''}>
                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label className={styles.department_heading}>Program Name
                        <span className={styles.astrix}>*</span>
                      </Form.Label>
                    
                      <div className={styles.LocationBar}>
                        {selectedProgramName && !hideselectedValue2 ? (
                          <div className={styles.LocationBarSelectedValue}>
                            <div className={styles.LocationBarName}>{selectedProgramName?.programName}</div>
                            <div
                              className={styles.LocationBarRemoveSelected}
                              onClick={removeSelectedProgramName}
                              role="none"
                            >x</div>
                          </div>
                        )
                          : null}
                        <Form.Control
                          autoComplete="off"
                          ref={locationFilterInput2}
                          className={styles.LocationBarFormControl}
                          type="text"
                          placeholder={selectedProgramName ? '' : 'Select'}
                          onBlur={() => { setTimeout(() => { setHideOptions2(true); setSelectedValue2(false); setIsInputFocused2(false);}, 500); }}
                          onFocus={() => { setSelectedValue2(true); setIsInputFocused2(true);}}
                          onChange={(e) => {
                            setHideOptions2(false);
                            filterLocations2(e.target.value);
                          }
                          }
                          onClick={() => {
                            if (hideOptions2)
                              setHideOptions2(false);
                          }}
                        />
                        {!hideOptions2 ? (
                          nameList2.length ? (
                            <div className={styles.LocationBarOptions}>
                              {
                                nameList2.map((item:any, index:any) =>
                                (
                                  <div
                                    key={index}
                                    role="none"
                                    onClick={() => {
                                      dispatchData({
                                        type: 'Add_Data',
                                        payload: { programID: item.programID },
                                      });
                                      setFormError(p => 
({
                                          ...p,
                                          programName: '',
                                        }));
                                      setSelectedProgramName(item);
                                      setHideOptions2(true);
                                      setSelectedValue2(false);
                                      locationFilterInput2.current.value = '';
                                    }}
                                    className={styles.LocationBarOption}
                                  >{item.programName}</div>
                                ))}
                            </div>
                          ) : <div className={styles.LocationBarError}>No program name found</div>
                        ) : null}
                        {
                          getModalType() != 'update' ?
                          (
                            <div className={styles.LocationBarRightIcon}>
                              <img
                                src={require('../../../common/icons/arrow-down.svg')}
                                role="none"
                                alt="dropdown icon"
                                onClick={() => {
                                  if (isInputFocused2 && hideOptions2){
                                    setHideOptions2(false);
                                  }
                                  else if( !isInputFocused2 && hideOptions2){
                                    setHideOptions2(false);
                                    locationFilterInput2.current?.focus();
                                    setIsInputFocused2(true);
                                  }
                                  else{
                                    setHideOptions2(true);
                                    setIsInputFocused2(false);
                                  }
                                }}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        formError.programName ? <span className={styles.error}>{formError.programName}</span> : ''
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col >
                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label className={styles.department_heading}>Start Date<span className={styles.astrix}>*</span></Form.Label>
                      <div className={styles.input_group}>
                        <div style={{ flex:1 }}>
                          <DatePicker
                            placeholderText="Enter Start Date"
                            className={styles.date_picker} 
                            selected={showTransformedDate(modalData.startDate)} 
                            onChange={(date:Date) => {changeValue({ startDate: dateTransformer(date) });}}
                          />
                        </div>
                        <div className={styles.calendar2}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </div>
                      </div>
                      {
                          formError.startDate ? <span className={styles.error}>{formError.startDate}</span> : ''
                        }
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label className={styles.department_heading}>End Date<span className={styles.astrix}>*</span></Form.Label>
                      <div className={styles.input_group}>
                        <div style={{ flex:1 }}>
                          <DatePicker
                            placeholderText="Enter End Date"
                            className={styles.date_picker} 
                            selected={showTransformedDate(modalData.endDate)}
                            onChange={(date:Date) => 
changeValue({ endDate: dateTransformer(date) })}
                          />
                        </div>
                        <div className={styles.calendar2}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </div>
                      </div>
                      {
                          formError.endDate ? <span className={styles.error}>{formError.endDate}</span> : ''
                        }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col >

                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                      <Form.Label className={styles.department_heading}>Date Created</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className={styles.date_created}
                          disabled
                          type="text"
                          value={moment().format('MM/DD/YYYY')}
                        />
                        <InputGroup.Text className={styles.calendar}>
                          <img src={require('../../../common/images/calendar.png')} alt="" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col />
                </Row>
              </Form>
            )
        }

      </Modal.Body>
      {
        (props.data.header != 'View Program') ?
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
              </div>
              <div>
                <Button onClick={submit} className={globalStyle.footerAdd_btn} variant="primary">
                  {getModalType() == 'add' ? 'Create' : getModalType() == 'update' ? 'Update' : 'Copy'}
                </Button>
              </div>
            </Modal.Footer>
) :
          (
            <Modal.Footer bsPrefix={globalStyle.footer}>
              <div>
                <Button onClick={props.onHide} className={globalStyle.footerAdd_btn} variant="primary">
                  <img src={require('../../../common/images/back_arrow.png')} alt="" /> &nbsp;Back</Button>
              </div>
            </Modal.Footer>
          )
      }
    </Modal>
  );
};

