import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

export const getHolidayList = (): Promise<any> =>


  httpRequest({
    url: `${Setting.PharmacyHolidays}/getPharmacyHoliday`,
    method: HttpMethod.GET,
  });

  export const getHolidayById = (id: bigint): Promise<any> =>
  
    httpRequest({
      url: `${Setting.PharmacyHolidays}/getPharmacyHoliday/`+id,
      method: HttpMethod.GET,
    });

    export const addPharmacyHoliday = (payload: any): Promise<any> =>
  
    httpRequest({
      url: `${Setting.PharmacyHolidays}/addPharmacyHoliday`,
      method: HttpMethod.POST,
      data: payload
    });

    export const editPharmacyHoliday = (payload: any): Promise<any> =>
  
    httpRequest({
      url: `${Setting.PharmacyHolidays}/updatePharmacyHoliday`,
      method: HttpMethod.PUT,
      data: payload
    });

    export const deletePharmacyHoliday = (payload: any): Promise<any> =>
  
    httpRequest({
      url: `${Setting.PharmacyHolidays}/deletePharmacyHoliday`,
      method: HttpMethod.DELETE,
      data: payload
    });