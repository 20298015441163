import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Form, Button, InputGroup, Modal
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { UserModal } from '@components/modals/user-modal';

import {
  getUsers, addUser,
  updateUser, deleteUser,
  getActiveUsersList, genericRecordStatus
} from '../../../http/requests';

import styles from './user.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';

function User({ activeTab, roleChange }) {
  const [ modalShow, setModalShow ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ departmentList, setDepartmentList ] = useState([]);
  const [ _departmentList, set_departmentList ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [ showExist, setShowExist ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ deleteId, setDeleteId ] = useState('');
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ showUpdatePopup, setShowUpdatePopup ] = useState(false);
  const [ updateData, setupdateData ] = useState(null);

  useEffect(() => {
    if(activeTab==='user'){
      fetchDepartmentList();
    }
    
  }, [activeTab, roleChange]);

  useEffect(()=>{
    fetchDepartmentList();
  },[]);
  useEffect(()=>{
    if(globalSearchField){
      globalSearch(globalSearchField);
    }
  },[_departmentList]);

  useEffect(() => {
    if (activeTab != 'user') {
      setGlobalSearch('');
      setSearchboxError('');
      globalSearch('');
    }
  }, [ activeTab ]);

  const customTotal = (from: any, to: any, size: any) =>
    (
      <span style={{ marginLeft: '20px' }} className="react-bootstrap-table-pagination-total">
        {from} - {to} of {size}
      </span>
    );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }: {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any
  }) =>
    (
      <span className={styles.listPage}>
        <span>Rows per page &nbsp;&nbsp;
          {
          (
            <Form.Select
              className={styles.perPageSelect}
              onChange={e =>
                onSizePerPageChange(e.target.value)}
              aria-label="Default select example"
            >
              {
                options.map((val: any) =>
                  <option key={val.text} value={val.page}>{val.text}</option>)
              }
            </Form.Select>
          )
        }

        </span>
      </span>
    );


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always deleteId next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    // firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    // lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    // pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageRenderer,
    sizePerPageList: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 } ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const isEnable = (row: any) => {
    const style: any = {};
    const account = JSON.parse(localStorage.getItem('account'));
    if (!row.active) {
      style.pointerEvents = 'none';
    }
    else if(account.localAccountId==row.azureID){
      style.pointerEvents = 'none';
    }
    return style;
  };

  const isEnableForm = (row: any) => {
    const style: any = {};
    const account = JSON.parse(localStorage.getItem('account'));
    if (account.localAccountId==row.azureID) {
      style.pointerEvents = 'none';
    }
    return style;
  };



  const isEnableView = (row: any) => {
    const style: any = {};
    if (!row.active) {
      style.pointerEvents = 'none';
    }
    return style;
  };

  const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) =>
    (
      <div className={styles.table_actions}>
        <div
          style={isEnableView(row)}
          onClick={() =>
          view(row)}
        >
          {row.active ? <img src={require('../../../common/icons/view.svg')} alt="" /> :
          <img src={require('../../../common/icons/view-dull.svg')} alt="" />}
        </div>
        <div
          style={isEnable(row)}
          onClick={() =>
          edit(row)}
        >
          {row.active ? <img src={require('../../../common/icons/pencil.svg')} alt="" /> :
          <img src={require('../../../common/icons/edit-dull.svg')} alt="" />}
        </div>
       
        <div
          style={isEnable(row)}
          onClick={() =>
          deleteClientItem(row.usersID)}
        >
          {row.active ? <img src={require('../../../common/icons/delete.svg')} alt="" /> :
          <img src={require('../../../common/icons/delete-dull.svg')} alt="" />}
        </div>
        <div style={isEnableForm(row)}>
          <Form.Check
            className={styles.table_actions_switch}
            checked={row.active}
            type="switch"
            id="custom-switch"
            onChange={e =>
            activeDepartment(e.target.checked, row)}
          />
        </div>
      </div>
    );

  const columns = [
    {
      dataField: 'firstName',
      text: 'FIRST NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'lastName',
      text: 'LAST NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '9rem' },
    },
    {
      dataField: 'userEmail',
      text: 'EMAIL ADDRESS',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '9rem' },
    },
    {
      dataField: 'userRoleName',
      text: 'ROLE',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '10rem' },
    },
    {
      dataField: 'loginAttempt',
      text: 'LAST LOGIN',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'action',
      text: 'ACTION',
      formatter: linkFollow,
      sort: true,
      headerAlign: 'center',
      style: { width: '10rem' },
    },
  ];

  const rowStyle2 = (row: any, rowIndex: any) => {
    const style: any = {};
    if (!row.active) {
      style.color = 'rgba(114, 114, 114, 0.3)';
      //   style.pointerEvents = 'none'
    }

    return style;
  };

  function transformDate(date: any) {
    if (date) {
      const a = date.split('-');
      return `${a[1]}/${a[2].slice(0, 2)}/${a[0]}`;
    } else {
      return 'No Logins';
    }
  }

  const fetchDepartmentList = () => {
    getUsers().then((res: any) => {
      const data: any = [];
      res.forEach((d: any) => {
        data.push({
          ...d,
          id: d.usersID,
          loginAttempt: transformDate(d.loginAttempt),
        });
      });
      set_departmentList(_.cloneDeep(data));
      setDepartmentList(_.cloneDeep(data));
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const submit = (data: any) => {
    setModalShow(false);
    if (data.type == 'add' || data.type == 'copy') {
      setGlobalSearch('');
      setSearchboxError('');
      addUser(data.modalData).then((res) => {
        setShowSuccess(true);
        data.type == 'add' ? setShowSuccessMsg('User Created Successfully') : setShowSuccessMsg('User Copied Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();     
      })
      .catch((error) => {
        // Handle the error here
        if (error.status === 409) {
          setShowExist(true);
        }
      });
    } else if (data.type == 'update') {
      setupdateData(data);
      setShowUpdatePopup(true);
    }
  };

  const view = (currentRow: any) => {

    setModalData({
      header: 'View User',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const activeDepartment = (value: any, department: any) => {
    const data = {
      recordType: 'Users',
      status: value,
    };
    genericRecordStatus(department.usersID, data).then(() => {
      getUsers().then((res: any) => {
        const data: any = [];
        res.forEach((d: any) => {
          data.push({
            ...d,
            id: d.usersID,
            loginAttempt: transformDate(d.loginAttempt),
          });
        });
        set_departmentList(_.cloneDeep(data));
        setDepartmentList(_.cloneDeep(data));
       
      })
      .catch((err) => {
        console.log(err);
      });
    })
    .catch((error) => {
      // Handle the error here
      fetchDepartmentList();
    });
  };

  const add = () => {
    setModalData({
      header: 'Add User',
      submit,
    });
    setModalShow(true);
  };
  const edit = (currentRow: any) => {
    setModalData({
      header: 'Edit User',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const deleteClientItem = (id: string) => {
    setDeleteId(id);
    setShow(true);
  };

  const deleteClientById = () => {
    if (deleteId) {
      setShow(false);
      setGlobalSearch('');
      setSearchboxError('');
      deleteUser(deleteId).then((res) => {
        
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }
  };

  const globalSearch = (input: string) => {
    setGlobalSearch(input);
    const dataListCopy = _.cloneDeep(_departmentList);
    if (input) {
      const list = dataListCopy.filter((data: any) => {
        if ((data.firstName && data.firstName.toLowerCase().includes(input.toLowerCase())) ||
          (data.lastName && data.lastName.toLowerCase().includes(input.toLowerCase())) ||
          (data.userEmail && data.userEmail.toLowerCase().includes(input.toLowerCase())) ||
          (data.userRoleName && data.userRoleName.toLowerCase().includes(input.toLowerCase())) ||
          (data.loginAttempt && data.loginAttempt.toLowerCase().includes(input.toLowerCase()))) {
          return data;
        }
      });
      if (list.length == 0) {
        setSearchboxError(input + ' does not exist.');
      } else {
        setSearchboxError('');
      }
      setDepartmentList(list);
    } else {
      setSearchboxError('');
      setDepartmentList(dataListCopy);
    }
  };

  const updateOnPopup = () => {
    setShowUpdatePopup(false);
    if (updateData && updateData.id) {
      setGlobalSearch('');
      setSearchboxError('');
      updateUser(updateData.modalData, updateData.id).then((res) => {
        setShowSuccess(true);
        setShowSuccessMsg('User Updated Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          
        }, 1000);
        fetchDepartmentList();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleClose = () =>
    setShow(false);
  const handleSuccessClose = () =>
    setShowSuccess(false);
  const handleUpdateClose = () =>
    setShowUpdatePopup(false);
  const handleExistClose = () =>
    setShowExist(false);
  return (
    <>
      <div id={styles.department}>
        <UserModal
          data={modalData}
          show={modalShow}
          activeTab={activeTab}
          onHide={() =>
            setModalShow(false)}
        />
        <div className={styles.filter}>
          <div className={styles.search}>
            <InputGroup>
              <InputGroup.Text className={styles.search_logo}>
                <img src={require('../../../common/images/search.png')} alt="" />
              </InputGroup.Text>
              <Form.Control
                value={globalSearchField}
                id="global-search"
                onChange={e =>
                  globalSearch(e.target.value)}
                className={styles.search_field}
                type="text"
                placeholder="Search by First Name, Last Name, Email, Role"
              />
            </InputGroup>
            {
              searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
            }
            {/* <Form.Control type="text" placeholder="Enter email" /> */}
          </div>
          <div className={styles.buttons}>
            
            <Button onClick={add} className={styles.add_btn} variant="primary">Add</Button>
          </div>
        </div>
        <div className={styles.table}>
          <BootstrapTable
            rowStyle={rowStyle2}
            bootstrap5
            bordered={false}
            trClassName={styles.customRow}
            keyField="id"
            data={departmentList}
            columns={columns}
            pagination={paginationFactory(options)}
          />
        </div>
      </div>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showExist} onHide={handleExistClose}>
        <Modal.Header closeButton>
          <Modal.Title>User</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
            User Already Exists.
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleExistClose} className={globalStyle.footerAdd_btn} variant="primary">OK</Button>
          </div>
        </Modal.Footer>
      </Modal>
      
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will delete the User from the application.
            </div>
            <div className={styles.modal_text}>
              Do you want to proceed
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={deleteClientById} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showUpdatePopup} onHide={handleUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will update the user. Are you sure you want to save the new changes ?
            </div>
           
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleUpdateClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={updateOnPopup} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default User;
