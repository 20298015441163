import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Form, Button, Dropdown, Modal
} from 'react-bootstrap';
import { useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { deleteException, addException, getAllException } from '../../http/requests';

import styles from './exception.scss';
import './exception.css';
import globalStyle from '../../common/styles/globalStyle.scss';

interface seasonalExceptionObject {
    destination: null;
    startingMonth: string;
    startingDay: number;
    endingMonth: string;
    endingDay: number;
    temperatureSetting: string;
    type: number;
}
interface destinationExceptionObject {
    destination: string;
    startingMonth: string;
    startingDay: number;
    endingMonth: string;
    endingDay: number;
    temperatureSetting: string;
    type: number;
}


function Exception() {

    const [ seasonalExceptionData, setSeasonalExceptionData ] = useState([]);
    const [ destinationExceptionData, setDestinationException ] = useState([]);
    const [ programId, setProgramId ] = useState("");
    const [ itemName, setItemName ] = useState("");
    const [ ndc, setNDC ] = useState("");
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const oddDaysMonths = ["Jan", "Mar", "May", "Jul", "Aug", "Oct", "Dec"];
    const evenDaysMonths = ["Apr", "Jun", "Sep", "Nov"];

    const destinations =[
        'AL', 'AK', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DE', 'DC', 
        'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 
        'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 
        'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 
        'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UM',
        'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY'
    ]
    
    const [temperatureSetting, setTempSetting] = useState(["Ambient", "Temperature Sensitive"]);
    const [seasonalExceptionObject, setSeasonalExceptionObject] = useState<seasonalExceptionObject>({
        destination: null,
        startingMonth: months[0],
        startingDay: 1,
        endingMonth: months[0],
        endingDay: 1,
        temperatureSetting: temperatureSetting[0],
        type: 0,
      });
    const [destinationExceptionObject, setDestinationExceptionObject] = useState<destinationExceptionObject>({
        destination: destinations[0],
        startingMonth: months[0],
        startingDay: 1,
        endingMonth: months[0],
        endingDay: 1,
        temperatureSetting: temperatureSetting[0],
        type: 1,
      });
    const history = useHistory();
    const [ showExist, setShowExist ] = useState(false);
    const [isLoginClicked, setIsLoginClicked] = useState(false);
    const state = useSelector(state=>state);

    useEffect(() => {
        if(state.ITEMMASTERPROGRAM.programId && state.ITEMMASTERPROGRAM.ndc && state.ITEMMASTERPROGRAM.itemName){
            setItemName(state.ITEMMASTERPROGRAM.itemName);
            setProgramId(state.ITEMMASTERPROGRAM.programId);
            setNDC(state.ITEMMASTERPROGRAM.ndc);
            getAllException(state.ITEMMASTERPROGRAM.programId, state.ITEMMASTERPROGRAM.ndc, 0).then((res:any) => {
                setSeasonalExceptionData(res);
            })
            .catch((err) => {
                console.log(err);
              });
            getAllException(state.ITEMMASTERPROGRAM.programId, state.ITEMMASTERPROGRAM.ndc, 1).then((res:any) => {
                setDestinationException(res);
            })
            .catch((err) => {
                console.log(err);
              });
        } else cancelAction();
    }, []);

    const cancelAction = () => {
        history.push('/item-master',{ from: '/item-master/exception' });
    }

    const handleMonthSelect = (selectedMonth:any, exceptionObj:any, setExceptionObj:any, monthFlag:string) => {
        let day = 31;
        if(monthFlag === "startingMonth") day = exceptionObj.startingDay;
        else if (monthFlag === "endingMonth") day = exceptionObj.endingDay;
        if(evenDaysMonths.includes(selectedMonth) && day > 30) day = 30;
        else if(selectedMonth === "Feb" && day > 28) day = 28;
        setExceptionObj({
            ...exceptionObj,
            [monthFlag]: selectedMonth,
            ...(monthFlag === "startingMonth" ? { startingDay: day } : { endingDay: day }),
          });
    }

    const handleSelect = (selectedOption:any, exceptionObj:any, setExceptionObj:any, objKey:string) => {
        setExceptionObj({
            ...exceptionObj,
            [objKey]: selectedOption,
          });
    }

    const removeData = (exception:string, id:any, index: number) => {
        deleteException(id).then((res: any) => {
            if(res.statusCode === "200"){
                let setExceptionData: any;
                if(exception === "seasonalException") setExceptionData = setSeasonalExceptionData;
                else if(exception === "destinationException") setExceptionData = setDestinationException;
                setExceptionData(prevItems => {
                    const updatedItems = [...prevItems];
                    updatedItems.splice(index, 1);
                    return updatedItems;
                });
            }
        })
        .catch((err) => {
            console.log(err);
          });
    }

    const addExceptionAction = (exception:string, exceptionObj:any) => {
        const payload = exceptionObj;
        payload.programId = programId;
        payload.ndc = ndc;
        setIsLoginClicked(true);
        addException(payload).then((res:any) => {
            setIsLoginClicked(false);
            if(exceptionObj.type === 0) setSeasonalExceptionData(res);
            else if(exceptionObj.type === 1) setDestinationException(res);
        })
        .catch((error) => {
            // Handle the error here
            if (error.status === 409) {
              setShowExist(true);
            }
          });;
    }

    const handleExistClose = () =>{
        setIsLoginClicked(false);
        setShowExist(false);
    }


    const abcFunc = (exception:string, exceptionObj:any, setExceptionObj:any) => {

        const columns = [
            {
              dataField: 'number',
              text: 'Number',
              formatter: (cell:any, row:any, rowIndex:number) => rowIndex + 1,
              style: { width: '8rem' },
            },
            {
              dataField: 'message',
              text: 'Exception',
              sort: true,
              sortCaret: (order: any, column: any) =>
                (
                  <span> &nbsp;
                    <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
                    <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
                  </span>
                ),
              style: { width: '24rem' },
            },
            {
                dataField: '',
                text: '',
                formatter: () => (
                    <img
                      style={{ cursor: 'pointer' }}
                      src={require('../../common/icons/removeIcon.svg')}
                      alt=""
                    />
                  ),
                events: {
                    onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:number) => {
                        removeData(exception, row.id, rowIndex);
                    },
                },  
                style: { width: '1rem' },
            },
          ];

        return(
            <>
                {exception === "seasonalException" && <h1 className={styles.exception_heading}>Seasonal Exception</h1>}
                {exception === "destinationException" && <h1 className={styles.exception_heading}>Destination Exception</h1>}
                <hr style={{margin: "2rem auto"}}/>
                <div className="d-flex justify-content-between" style={{gap: "3rem"}}>
                    <div className="d-flex flex-wrap" style={{gap: "3rem"}}>
                        { exception === "destinationException" && <div style={{minWidth: "180px"}}>
                            <div className={styles.dropdown_label}>Destination</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleSelect(destinations[parseInt(eventKey)], exceptionObj, setExceptionObj, "destination")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.destination}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {destinations.map((item, index) => (
                                        <Dropdown.Item key={index} eventKey={index} className={item === exceptionObj.destination ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>}
                        <div style={{minWidth: "180px"}}>
                            <div className={styles.dropdown_label}>Starting Month</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleMonthSelect(months[parseInt(eventKey)], exceptionObj, setExceptionObj, "startingMonth")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.startingMonth}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {months.map((item, index) => (
                                        <Dropdown.Item key={index} eventKey={index} className={item === exceptionObj.startingMonth ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div style={{minWidth: "100px"}}>
                            <div className={styles.dropdown_label}>Starting Day</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleSelect(parseInt(eventKey), exceptionObj, setExceptionObj, "startingDay")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.startingDay}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {Array.from({ length: oddDaysMonths.includes(exceptionObj.startingMonth) ? 31 : evenDaysMonths.includes(exceptionObj.startingMonth) ? 30 : 28}, (_, index) => index + 1).map((item) => (
                                        <Dropdown.Item key={item} eventKey={item} className={item === exceptionObj.startingDay ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div style={{minWidth: "180px"}}>
                            <div className={styles.dropdown_label}>Ending Month</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleMonthSelect(months[parseInt(eventKey)], exceptionObj, setExceptionObj, "endingMonth")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.endingMonth}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {months.map((item, index) => (
                                        <Dropdown.Item key={index} eventKey={index} className={item === exceptionObj.endingMonth ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div style={{minWidth: "100px"}}>
                            <div className={styles.dropdown_label}>Ending Day</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleSelect(parseInt(eventKey), exceptionObj, setExceptionObj, "endingDay")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.endingDay}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {Array.from({ length: oddDaysMonths.includes(exceptionObj.endingMonth) ? 31 : evenDaysMonths.includes(exceptionObj.endingMonth) ? 30 : 28}, (_, index) => index + 1).map((item) => (
                                        <Dropdown.Item key={item} eventKey={item} className={item === exceptionObj.endingDay ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div style={{minWidth: "180px"}}>
                            <div className={styles.dropdown_label}>Temperature Setting</div>
                            <div>
                                <Dropdown onSelect={(eventKey) => eventKey !== null && handleSelect(temperatureSetting[parseInt(eventKey)], exceptionObj, setExceptionObj, "temperatureSetting")}>
                                    <Dropdown.Toggle className={styles.dropdown_content}>
                                        {exceptionObj.temperatureSetting}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdown_menu}>
                                        {temperatureSetting.map((item, index) => (
                                        <Dropdown.Item key={index} eventKey={index} className={item === exceptionObj.temperatureSetting ? 'selectedOption' : ''}>
                                            {item}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-end '>
                        <img style={{ cursor: 'pointer' }} className={`${isLoginClicked ? styles.disabled : ''}`} onClick={() => addExceptionAction(exception, exceptionObj)} src={require('../../common/icons/fullAddIcon.svg')} alt="" />
                    </div>
                </div>
                {exception === "seasonalException" && <h2 className={styles.exception_subheading}>Seasonal Exception</h2>}
                {exception === "destinationException" && <h2 className={styles.exception_subheading}>Destination Exception</h2>}
                <div className={styles.table}>
                    <BootstrapTable
                        bootstrap5
                        bordered={false}
                        trClassName={styles.customRow}
                        keyField="id"
                        data={ exception === "seasonalException"? seasonalExceptionData : destinationExceptionData}
                        columns={columns}
                        headerClasses={styles.fixed_header}
                    />
                </div>

            </>
        )
    }

    return (
        <>
            <h4 style={{fontSize: "16px"}}>{itemName} ({ndc})</h4>
            <div id={styles.department}>
                <div>{abcFunc("seasonalException", seasonalExceptionObject, setSeasonalExceptionObject)}</div>
                <div>{abcFunc("destinationException", destinationExceptionObject, setDestinationExceptionObject)}</div>
            </div>
            <div className="d-flex justify-content-end" style={{margin: "24px auto"}}>
                <Button className={styles.cancel_btn} onClick={cancelAction}>Back</Button>
            </div>

            <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showExist} onHide={handleExistClose}>
                <Modal.Header closeButton>
                <Modal.Title>Exception</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modal_body}>
                <div className={styles.modal_body}>
                    <div>
                    <img src={require('../../common/icons/warning.svg')} alt="" />
                    </div>
                    <div className={styles.modal_text}>
                    Exception Already Exists.
                    </div>
                </div>
                </Modal.Body>

                <Modal.Footer bsPrefix={globalStyle.footerDelete}>
                <div>
                    <Button onClick={handleExistClose} className={globalStyle.footerAdd_btn} variant="primary">OK</Button>
                </div>
                </Modal.Footer>
            </Modal>
        </>
      );
}

export default Exception;