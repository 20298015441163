import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useRef, useState } from 'react';
import {
  Form, Button, InputGroup, Modal, Row, Col
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';

import { UserModal } from '@components/modals/user-modal';
import { getDashboardSections } from '@http/requests/dashboard';
import { RootState } from '@redux/reducers';
import {
  getReportsList, addUser,
  updateUser, deleteUser,
  getActiveUsersList, genericRecordStatus, getSections, postSection
} from '../../http/requests';

import styles from './reports.scss';
import './reports.css';
import { useSelector } from 'react-redux';

function Reports() {
  const history = useHistory();
  const sectionRef = useRef();
  const [ selectedRows, setSelectedRows ] = useState({});
  const [ selectedRow, setSelectedRow ] = useState({});
  const [ selectedIds, setSelectedIds ] = useState([]);
  const [ matchingReport, setMatchingReport ] = useState([]);
  const [ modalShow, setModalShow ] = useState(false);
  //const [ activeUserList, setActiceUserList ] = useState([]);
  const [ modalData, setModalData ] = useState({});
  const [ reportList, setReportList ] = useState([]);
  const [ _reportList, set_ReportList ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ sections, setSections ] = useState([]);
  const [ section, setSection ] = useState({ sectionName: '' });
  const [ sectionList, setSectionList ] = useState([]);
  const [ sectionType, setSectionType ] = useState('');
  const [ pinType, setPinType ] = useState('');
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ newSectionError, setNewSectionError ] = useState('');

  const userRole: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(() => {
    fetchReportList();
    // getActiveUsersList().then((res: any) => {
    //   setActiceUserList(res);
    // });
    getDashboardSections().then((res: any) => {
      const data: any = [];
      res.forEach((r: any) => {
        data.push({
          ...r,
          name: r.sectionName,
        });
      });
      setSections(data);
    })
    .catch((err) => {
      console.log(err);
    });
    return () => {
     
    };
  }, [ selectedRows, userRole]);

  useEffect(()=>{
    if(globalSearchField){
      globalSearch(globalSearchField);
    }
  },[_reportList]);

  const selectRow = {
    selected: selectedIds,
    mode: 'checkbox',
    clickToSelect: false,
    onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
      if (isSelect) {
        const data = [ ...selectedIds ];
        data.push(row.departmentReportID);
        setSelectedIds(data);
        setSelectedRows(p => 
          ({
            ...p,
            [row.departmentReportID]: true,
          }));
      } else {
        setSelectedIds(selectedIds.filter(x => 
          x !== row.departmentReportID));
        const obj: any = { ...selectedRows };
        delete obj[row.departmentReportID];
        setSelectedRows(obj);
      }
    },
    onSelectAll: (isSelect: any, rows: any, e: any) => {
      if (isSelect) {
        rows.forEach((r: any) => {
          setSelectedRows(p => 
            ({
              ...p,
              [r.departmentReportID]: true,
            }));
        });
        const ids = rows.map(r => 
          r.departmentReportID);
        setSelectedIds(ids);
      } else {
        setSelectedRows({});
        setSelectedIds([]);
      }
    },
    style: { background: '#EBF2FD' },
  };

  const customTotal = (from: any, to: any, size: any) =>
    (
      <span style={{ marginLeft: '20px' }} className="react-bootstrap-table-pagination-total">
        {from} - {to} of {size}
      </span>
    );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }: {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any
  }) =>
    (
      <span className={styles.listPage}>
        <span>Rows per page &nbsp;&nbsp;
          {
          (
            <Form.Select
              className={styles.perPageSelect}
              onChange={e =>
                onSizePerPageChange(e.target.value)}
              aria-label="Default select example"
            >
              {
                options.map((val: any, idx:any) =>
                  <option key={idx} value={val.page}>{val.text}</option>)
              }
            </Form.Select>
          )
        }

        </span>
      </span>
    );


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always deleteId next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    // firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    // lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    // pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageRenderer,
    sizePerPageList: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 } ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const isEnable = (row: any) => {
    const style: any = {};
    if (selectedRows[row.departmentReportID]) {
      style.pointerEvents = 'none';
    }
    return style;
  };

  const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) =>
    (
      <div className={styles.table_actions}>
       
        <div
          style={isEnable(row)}
          onClick={() =>
          pinPopup(row)}
        >
          {!row.currentlySelected ? <img src={require('../../common/icons/pin.svg')} alt="" /> :
          <img src={require('../../common/icons/pin-dull.svg')} alt="" />}
        </div>
      </div>
    );

  const columns = [
    {
      dataField: 'reportName',
      text: 'REPORT NAME',
      sort: true,
      formatter: (cell, row) => (
        <>
        <span style={{cursor: 'pointer', color: '#0057ED'}} onClick={() => view(row)}>{row.reportName}</span>
        </>
      ),
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '18rem' },
    },
    
    {
      dataField: 'department',
      text: 'DEPARTMENT',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'dateCreated',
      text: 'DATE CREATED',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'pinToDashboard',
      text: 'PIN SECTION',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
   
  ];

  const rowStyle2 = (row: any, rowIndex: any) => {
    const style: any = {};
    if (!row.active) {
      style.color = '#9BBCF7';
      //   style.pointerEvents = 'none'
    }

    return style;
  };

  function transformDate(date: any) {
    if (date) {
      const a = date.split('-');
      return `${a[1]}/${a[2].slice(0, 2)}/${a[0]}`;
    } else {
      return 'No Logins';
    }
  }

  const fetchReportList = () => {
    getReportsList(userRole).then((res: any) => {      
      const data: any = [];
      res.forEach((d: any) => {
        data.push({
          ...d,
          id: d.departmentReportID,
          currentlySelected: selectedRows[d.departmentReportID] ? true : false,
          dateCreated: transformDate(d.dateCreated),
          pinToDashboard: d.sectionList.length > 0 ? d.sectionList.map(r=>
            r.sectionName).join(', ') : '-',
        });
      });
      set_ReportList(_.cloneDeep(data));
      setReportList(_.cloneDeep(data));
    }).catch((err)=>{
      console.log(err);
    });
  };

  function onSelect(selectedList: any, selectedItem: any) {
    setSectionType('dropdown');
    setNewSectionError('');
    setSection({ sectionName: '' });
    setSectionList(selectedList);
  }

  function onRemove(selectedList: any, removedItem: any) {
    if (selectedList.length == 0) {
      setSectionType('');
    }
    setSectionList(selectedList);
    
  }

  const submit = (data: any) => {
    setModalShow(false);
    if (data.type == 'add' || data.type == 'copy') {
      addUser(data.modalData).then((res) => {
        setShowSuccess(true);
        data.type == 'add' ? setShowSuccessMsg('Reports Created Successfully') : setShowSuccessMsg('Reports Copied Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
        }, 1000);
        fetchReportList();
      })
      .catch((err) => {
        console.log(err);
      });
    } else if (data.type == 'update') {
      updateUser(data.modalData, data.id).then((res) => {
        setShowSuccess(true);
        setShowSuccessMsg('Reports Updated Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
        }, 1000);
        fetchReportList();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const view = (currentRow: any) => {
    history.push('/report/' + currentRow.departmentReportID);
  };

  const add = () => {
    setModalData({
      header: 'Add Reports',
      submit,
    });
    setModalShow(true);
  };

  const pinPopup = (row:any) => {
    setPinType('single');
    setSelectedRow({ [row.departmentReportID]: true });
    setShow(true);
  };

  const globalSearch = (input: string) => {
    setGlobalSearch(input);
    const dataListCopy = _.cloneDeep(_reportList);
    if (input) {
      const list = dataListCopy.filter((data: any) => {
        if ((data.reportName && data.reportName.toLowerCase().includes(input.toLowerCase())) ||
          // (data.reportID && data.reportID.toLowerCase().includes(input.toLowerCase())) ||
          (data.department && data.department.toLowerCase().includes(input.toLowerCase())) ||
          (data.pinToDashboard && data.pinToDashboard.toLowerCase().includes(input.toLowerCase())) ||
          (data.dateCreated && data.dateCreated.toLowerCase().includes(input.toLowerCase()))) {
          return data;
        }
      });
      if(list.length == 0){
        setSearchboxError(input + ' does not exist.');
      } else{
        setSearchboxError('');
      }
      setReportList(list);
    } else {
      setReportList(dataListCopy);
      setSearchboxError('');
    }
  };

  const handleClose = () => {
    setMatchingReport([]);
    setShow(false);
  };
    
  const handleSuccessClose = () => {
    setMatchingReport([]);
    setShowSuccess(false);
  };
    

  const pinAll = () => {
    setPinType('multiple');
    setShow(true);
  };

  const selectedValueDecorator = (val: any) =>
    (
      <small>{val}</small>
    );


  const optionValueDecorator = (val: any) =>
    (
      <p>{val}</p>
    );

  function customArrow() {
    return (
      <div>
        <img
          src={require('../../common/icons/arrow-down.svg')}
        />
      </div>

    );
  }

  const validateData = ()=>{
    setMatchingReport([]);
    let isValid = true;
    if (!sectionType) {
      isValid = false;
      if (sections.length !== 0) {
        setNewSectionError('Section is missing');
      } else {
        setNewSectionError('This is a required field');
      }
    } else if (sectionType == 'text') {
      const l = sections.filter(s=>
        s.sectionName.toLowerCase() == section.sectionName.toLowerCase()).length;
      if(l != 0){
        isValid = false;
        setNewSectionError('Section with this name already exists');
      }
      if(section.sectionName.length > 25){
        isValid = false;
        setNewSectionError('Lenght should be less than 25');
      }
    } else{
      const matching:any = [];
      let data:any = [];
      if(pinType == 'single'){
        data = selectedRow;
      } else{
        data = selectedRows;
      }
      Object.keys(data).forEach((s)=>{
        reportList.forEach((r:any)=>{
          if(r.id == s){
            r.sectionList.forEach((t)=>{
              sectionList.forEach((l)=>{
                if(l.sectionID == t.sectionID){
                  matching.push(`${r.reportName} already exist in ${t.sectionName}`);
                }
              });
            });
          }
        });
      });
      setMatchingReport(matching);
      if(matching.length > 0){
        isValid = false;
      }
    }
    return isValid;
  };

  const pinToDashboard = () => {
    
    if(validateData()){
      const account = JSON.parse(localStorage.getItem('account'));
      if (sectionType == 'text') {
        if(newSectionError){
          return;
        }
        const data = {
          sectionName : section.sectionName.trim(),
          userAzureID : account.localAccountId,
          departmentReportID : pinType == 'single' ? Object.keys(selectedRow).map(id=>
            +id) : Object.keys(selectedRows).map(id=>
            +id),
        };
        postSection(data).then(()=>{
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
        setShow(false);
        getSections(userRole).then((res: any) => {
          const data: any = [];
          res.forEach((r: any) => {
            data.push({
              ...r,
              name: r.sectionName,
            });
          });
          setSections(data);
        }).catch((err)=>{
          console.log(err);
        });
      } else {
        const ids:any = [];
        sectionList.forEach((id)=>{
          ids.push(+id.sectionID);
        });
        const data = {
          sectionID : ids,
          userAzureID : account.localAccountId,
          departmentReportID : pinType == 'single' ? Object.keys(selectedRow).map(id=>
            +id) : Object.keys(selectedRows).map(id=>
            +id),
        };

        postSection(data).then(()=>{
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
        setShow(false);
        getSections(userRole).then((res: any) => {
          const data: any = [];
          res.forEach((r: any) => {
            data.push({
              ...r,
              name: r.sectionName,
            });
          });
          setSections(data);
        }).catch((err)=>{
          console.log(err);
        });
      }
      setSelectedIds([]);
      setSelectedRow({});
      setSelectedRows({});
      fetchReportList();
      setMatchingReport([]);
    }
  };

  const pinModalExit = () => {
    setSection({ sectionName: '' });
    setNewSectionError('');
    sectionRef.current?.resetSelectedValues();
    setSectionList([]);
    setSectionType('');
  };

  const newSection = (e:any)=>{
    setSectionType('text');
    let name= e.target.value.trim();
    if(!name.length){
      setNewSectionError('Please enter a name');
    } else if(/^\s|\/.*$/.test(name)) {
      setNewSectionError('Please enter a valid name');
    } else{
      setNewSectionError('');
    }
    sectionRef.current?.resetSelectedValues();
    setSectionList([]);
    setMatchingReport([]);
    setSection({ 'sectionName': e.target.value });
  };

  return (
    <>
      <div id={styles.department}>
        <div className={styles.filter}>
          <div className={styles.search}>
            <InputGroup>
              <InputGroup.Text className={styles.search_logo}>
                <img src={require('../../common/images/search.png')} alt="" />
              </InputGroup.Text>
              <Form.Control
                value={globalSearchField}
                onChange={e =>
                  globalSearch(e.target.value)}
                className={styles.search_field}
                type="text"
                placeholder="Search by Report Name, Department Name, Pin Section Name"
              />
            </InputGroup>
            {
              searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
            }
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              {
                Object.keys(selectedRows).length > 0 ? <img style={{ cursor: 'pointer' }} onClick={pinAll} src={require('../../common/icons/pinall-light.svg')} alt="" /> :
                <img src={require('../../common/icons/pinall.svg')} alt="" />
              }
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <BootstrapTable
            bootstrap5
            bordered={false}
            trClassName={styles.customRow}
            keyField="id"
            data={reportList}
            columns={columns}
            selectRow={selectRow}
          />
        </div>
      </div>
      <Modal onExit={pinModalExit} contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.modal_heading}>Pin to My Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modal_body}>
            { sections.length !== 0 && 
            <>
              <div style={{ width: '100%' }}>
                <Row className="mt-4">
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Multiselect
                        ref={sectionRef}
                        customArrow={customArrow()}
                        options={sections} // Options to display in the dropdown
                        avoidHighlightFirstOption={true}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        // singleSelect={true}
                        className={styles.multi_select}
                        selectedValues={sectionList}
                        showArrow={true}
                        showCheckbox={true}
                        placeholder="Select a report section"
                        selectedValueDecorator={val =>
                          selectedValueDecorator(val)}
                        optionValueDecorator={val =>
                          optionValueDecorator(val)}
                      />
                  
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {
                    matchingReport.map(i=>
                      (
                      <ul key={i}>
                        <li className={styles.matching_reports}>{i}</li>
                      </ul>
                      ))
                  }
                </Row>
              </div>
              <div className={styles.modal_text}>
                Or
              </div>
            </>}
            <div style={{ width: '100%' }}>
              <Row>
                <Col>
                  <Form.Label className={styles.create_section} htmlFor="inputPassword5">Create New Section</Form.Label>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    value={section.sectionName}
                    onChange={(e: any) => 
newSection(e)}
                    className={styles.new_section}
                    aria-describedby="passwordHelpBlock"
                    placeholder="Section Title"
                  />
                  {
                    newSectionError ? <span>{newSectionError}</span> : null
                  }
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={styles.footer}>
          <div>
            <Button onClick={handleClose} className={styles.cancel} variant="secondary">Cancel</Button>
          </div>
          <div>
            <Button onClick={pinToDashboard} className={styles.add_btn} variant="primary">
              Pin
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Reports;
