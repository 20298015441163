import { AppStateAction, AppStateType } from '@redux/actions';

export const showSpinner = (): AppStateAction =>
  ({ type: AppStateType.SHOW_SPINNER });

export const hideSpinner = (): AppStateAction =>
  ({ type: AppStateType.HIDE_SPINNER });

export type ExceptionDataType = {
	errorDate: string;
	xCorrId: string | null;
}
export const showExceptionsModal = ({ errorDate, xCorrId }: ExceptionDataType): AppStateAction =>
  ({
    type: AppStateType.SHOW_EXCEPTIONS_MODAL,
    payload: { errorDate, xCorrId },
  });

export const hideExceptionsModal = (): AppStateAction =>
  ({ type: AppStateType.HIDE_EXCEPTIONS_MODAL });
export const setFontSize = (fontSize:string):AppStateAction=>
  ({
    type:AppStateType.SET_FONT_SIZE,
    payload:fontSize, 
  });
