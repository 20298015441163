import { AuthenticationResult, EventType, EventMessage, AuthError } from '@azure/msal-browser';
import { useMsal, Account } from '@azure/msal-react';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ACCOUNT } from '@constants';
import { CollaborationViewRoutes } from '@enums';
import { invalidateToken } from '@http/requests';
import { RootState } from '@redux/reducers';
import {
  msalRequest, msalUseSilentMode, msalSilentRequest
} from '@utils';

export const useAzureADAuth = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useSelector(({ USER }: RootState) =>
  !!USER?.isAuthenticated);

  const loginAzureADRedirect = useCallback(() => {
    if (msalUseSilentMode()) {
      instance.ssoSilent(msalSilentRequest).catch(console.error);
    } else {
      if(localStorage.getItem('signUp')) msalRequest.authority = window?.env?.AUTHORITY_SIGN_UP || '';
      instance.loginRedirect(msalRequest).catch(console.error);
    }
  }, [ instance ]);

  const loginAzureADPopup = useCallback((callback: () => void = noop) => {
    let loginPromise: Promise<AuthenticationResult>;
    if (msalUseSilentMode()) {
      loginPromise = instance.ssoSilent(msalSilentRequest)
        .catch((error) => {
          // There is also can be not only InteractionRequiredAuthError but another type of error
          // after which is better to switch to normal flow
          console.error(error);
          return instance.loginPopup(msalSilentRequest);
        });
    } else {
      loginPromise = instance.loginPopup(msalSilentRequest);
    }

    loginPromise
      .then ((response) => {
        response && callback();
      })
      .catch(console.error);
  }, [ instance ]);

  const logoutAzureADRedirect = useCallback((redirectAfterLogout?: string) => {
    const logoutRequest: { account: Account, authority?: string } = {
      account: instance.getAllAccounts()[0], // Assuming only one account is logged in
    };
    if(localStorage.getItem('signUp')) logoutRequest.authority = window?.env?.AUTHORITY_SIGN_UP || '';
    instance.logoutRedirect(logoutRequest);
  }, [ instance ]);

  return {
    isAuthenticated,
    inProgress,
    loginAzureADPopup,
    loginAzureADRedirect,
    logoutAzureADRedirect,
  };
};
