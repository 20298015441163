interface ItemMasterProgramObj {
    programId: number| null;
    itemName: string;
    ndc: string;
  }
  
  interface SetItemMasterProgramAction {
    type: "SET_ITEMMASTERPROGRAM";
    payload: ItemMasterProgramObj;
  }
  
  const initialState: ItemMasterProgramObj = {
    programId: null,
    itemName: '',
    ndc: '',
  };
  
  const itemMasterReducer = (state = initialState, action: SetItemMasterProgramAction) => {
    switch (action.type) {
      case "SET_ITEMMASTERPROGRAM":
        return {
          ...state,
          programId: action.payload.programId,
          itemName: action.payload.itemName,
          ndc: action.payload.ndc,
        };
      default:
        return state;
    }
  };
  
  export default itemMasterReducer;