import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

export const getItemsMaster = (programId:number|null): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/getItemsMaster/`+programId,
    method: HttpMethod.GET,
  });

export const getItemMasterPrograms = (): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/getAllPrograms`,
    method: HttpMethod.GET,
  });

export const uploadExceptions = (payload:any): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/uploadExceptions`,
    method: HttpMethod.POST,
    data:payload
  });

export const getItemMasterCategory = (programId: number): Promise<any> =>
  
  httpRequest({
    url: `${Setting.ItemMaster}/getAllCategory/`+ programId,
    method: HttpMethod.GET,
  });


export const getNDCCategory = (categoryId: number | null): Promise<any> => {

  if (categoryId === null || categoryId === 0) return Promise.resolve({});
  else {
    return httpRequest({
      url: `${Setting.ItemMaster}/getCategoryById/` + categoryId,
      method: HttpMethod.GET,
    });
  }
};

export const updateNDCCategory = (payload:any): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/updateNDCCategory`,
    method: HttpMethod.PUT,
    data:payload
  });

export const getAllManagePrograms = (): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/getAllManagePrograms`,
    method: HttpMethod.GET,
  });

export const updateProgramStatus = (payload:any): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/updateProgramStatus`,
    method: HttpMethod.POST,
    data: payload
  });
  
export const getManagePrograms = (programId: number): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/getManagePrograms/` + programId,
    method: HttpMethod.GET,
  });

export const updateProgram = (payload: any): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/UpdateProgram`,
    method: HttpMethod.POST,
    data: payload
  });

  export const addManageProgram = (payload: any): Promise<any> =>

  httpRequest({
    url: `${Setting.ItemMaster}/AddProgram`,
    method: HttpMethod.POST,
    data: payload
  });
