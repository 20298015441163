import { HttpMethod } from '@enums';
import { Setting } from '@http/api';
import { httpRequest } from '@utils';

export const getDepartmentList = (role:string|null, active: boolean= false): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${Setting.DEPARTMENT}/getDepartmentList/${account.localAccountId}/${role}/${active}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};
  

export const getDepartmentName = (): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENT}/getDepartmentNames`,
    method: HttpMethod.GET,
  });


export const addDepartment = (data: any): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENT}/postDepartment`,
    method: HttpMethod.POST,
    data,
  });

export const editDepartment = (data: any, id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENT}/updateDepartment/${id}`,
    method: HttpMethod.PUT,
    data,
  });

export const deleteDepartment = (id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENT}/deleteDepartment/${id}`,
    method: HttpMethod.DELETE,
  });

