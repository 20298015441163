import { HttpMethod } from '@enums';
import { Reports, BASE_URL } from '@http/api';
import { httpRequest } from '@utils';

// const BASE_URL = window?.env?.BASE_URL || '';

export const getPowerBiReport = (): Promise<any> => 
  httpRequest({
    url: `${Reports.POWERBI}/reports`,
    method: HttpMethod.GET,
  });

export const getReportsList = (role:string | null): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${BASE_URL}/SectionReports/getSectionReport/${account.localAccountId}/${role}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};

export const getReportEmbedUrl = (id:any): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));
  if(account && account.localAccountId){
    return httpRequest({
      url: `${BASE_URL}/SectionReports/getReportEmbedUrl/${id}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login');
  });
};

export const getSections = (role:string | null): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${BASE_URL}/Section/getSection/${account.localAccountId}/${role}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};
  
export const postSection = (data:any): Promise<any> =>
  httpRequest({
    url: `${BASE_URL}/SectionReports/postSectionReport`,
    method: HttpMethod.POST,
    data,
  });
