import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Form, Button, ButtonGroup, InputGroup, Dropdown, Popover, OverlayTrigger, DropdownButton, Tooltip, Modal
} from 'react-bootstrap';
import globalStyle from '../../common/styles/globalStyle.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory,useLocation } from 'react-router-dom';
import { ItemMasterCategoryModal } from '@components/modals/item-master-category-modal';
import { useDispatch } from 'react-redux';
import { setItemMasterProgram } from '@redux/actions'
import { USER_ROLE } from '@constants';
import { useSelector } from "react-redux";

import {
  getItemMasterPrograms, getItemsMaster, uploadExceptions, updateNDCCategory
} from '../../http/requests';

import styles from './item-master.scss';
import './item-master.css';
import { BulkUploadModal } from '@components/modals/bulk-upload-modal/bulk-upload-modal';
import { RootState } from '@redux/reducers';

interface itemMasterProgramObj {
  programId: number | null;
  itemName: string;
  ndc: string;
}

function ItemMaster() {
  const history = useHistory();
  const [ programNameList, setProgramNameList ] = useState([]);
  const [ reportList, setItenListTable ] = useState([]);
  const [ _reportList, set_ItemList ] = useState([]);
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ programId, setProgramId ] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [modalShow, setModalShow] = useState(false);
  const [ showExceptionPop , setShowExceptionPop ] = useState(false);
  const [ succesfullAddition , setSuccesfullAddition ] = useState(false);
  const [ showPopup , setShowPopup ] = useState(false);
  const [ notInsertedExceptions, setNotInsertedExceptions ] = useState([]);
  const [ bulkUploadType , setBulkUploadType ] = useState(0);
  const [categoryExist, setCategoryExist] = useState(false);
  const [ selectedProgram, setSelectedProgram ] = useState("");
  const [ isAdmin, setIsAdmin ] = useState(false);
  const location = useLocation();
  const state = useSelector(state=>state);
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ showLoader , setshowLoader ] = useState(false);

  const dispatch = useDispatch();

  const role: string = useSelector(({ USER }: RootState) =>
    USER.userRole) || 'User';
  
  useEffect(() => {
    //const role: string = localStorage.getItem(USER_ROLE) || 'User';
    
    const from = location.state?.from 
    if(role == 'Super Admin') {
      setIsAdmin(true);
      if(from === '/item-master/exception'){
        fetchFilteredItemList();
      }
      else{
        fetchItemList();
      }
    } else setIsAdmin(false);
  }, [role]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMenuOpen && !e.target.closest('.category_custom_menu') && !e.target.closest('.action_item_master')) {
        handleCloseMenu();
      }
    };
    if(isMenuOpen) document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const renderTooltip = (props: any) =>
  (
    <Tooltip className={styles.tooltip} {...props}>
      <div>
        Bulk Upload
      </div>
    </Tooltip>
  );

  const handleOpenMenu = (row, event) => {
    handleCloseMenu();
    const rect = event.target.getBoundingClientRect();
    const top = rect.bottom + window.scrollY;
    const left = rect.left + window.scrollX - 100;
    setMenuPosition({ top, left });
    setIsMenuOpen(true);
    setActiveRow(row);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const fetchItemsMaster =(Id:any)=>{
    getItemsMaster(Id).then((response: any) => {
      setGlobalSearch('');
      setSearchboxError('');
      set_ItemList(_.cloneDeep(response));
      setItenListTable(_.cloneDeep(response));
    }).catch((err) => {
      setGlobalSearch('');
      setSearchboxError('');
      console.log(err)
    });
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = '';
    if (file) {
      setshowLoader(true);
      const formData = new FormData();
      formData.append('File', file);
      uploadExceptions(formData).then((res: any) => {
        setshowLoader(false);
        fetchItemsMaster(programId);

        if(res.notInsertedRows.length==0){
          setSuccesfullAddition(true);
          setShowPopup(true);
          setBulkUploadType(res.type);
        }
        else{
          setNotInsertedExceptions(res.notInsertedRows);
          setShowExceptionPop(true);
          setBulkUploadType(res.type);
        }
      }).catch((error) => {
        setshowLoader(false);
        setSuccesfullAddition(false);
        setShowPopup(true);
        console.log(error);
      });
    }
  };

  const columns = [
    {
      dataField: 'itemName',
      text: 'ITEM',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '24rem' },
    },
    {
      dataField: 'ndc',
      text: 'NDC',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'grx_Temp',
      text: 'GRX TEMP',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'ship_Temp',
      text: 'SHIP TEMP EXCEPTION',
      sort: true,
      formatter: (cell: any, row: any) => {
        if (row.ship_Temp==='Exists') {return <img src={require('../../common/icons/tick.svg')} alt="" />;} 
        else {return '';}
      },
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
        
    {
      dataField: 'tat',
      text: 'TAT',
      sort: true,
     
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    ...(categoryExist == 'true'?
       [
        {
          dataField: 'category',
          text: 'CATEGORY',
          sort: true,
          formatter: (cell: any, row: any) => {
            if (row.category) {return cell;} 
            else {return '';}
          },
          sortCaret: (order: any, column: any) =>
            (
              <span> &nbsp;
                <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_asc.png')} alt="" />
                <img style={{ cursor: 'pointer' }} src={require('../../common/images/sort_desc.png')} alt="" />
              </span>
            ),
          style: { width: '12rem' },
        },
      ] : []
    ),
    {
      dataField: '',
      text: 'ACTIONS',
      formatter: (cell, row) => (
        <img
          className='action_item_master'
          style={{ cursor: 'pointer', marginLeft: '1rem', width: '22px' }}
          src={require('../../common/images/actionsIcon.png')}
          alt=""
          onClick={(e) => handleOpenMenu(row, e)}
        />
      ),  
      style: { width: '3rem' },
    },
    
  ];

  const fetchFilteredItemList = () => {
    if(state.ITEMMASTERPROGRAM.programId){
      const programID= state.ITEMMASTERPROGRAM.programId;
      getItemMasterPrograms().then((res: any) => {
        if(res.length){
          setProgramNameList(_.cloneDeep(res));
          for (let program of res) {
            if (program.id == programID) {
              setProgramId(program.id);
              setSelectedProgram(program.programName);
              setCategoryExist(program.category? "true":"false");
              fetchItemsMaster(program.id);
            }
          }
        } else {
          setProgramNameList([]);
          setProgramId(null);
          setSelectedProgram("");
          setCategoryExist("false");
          set_ItemList([]);
          setItenListTable([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else{
      fetchItemList();
    }
    
  };
  const fetchItemList = () => {
    getItemMasterPrograms().then((res: any) => {
      if(res.length){
        setProgramNameList(_.cloneDeep(res));
        setProgramId(res[0].id);
        setSelectedProgram(res[0].programName);
        setCategoryExist(res[0].category? "true":"false");
        fetchItemsMaster(res[0].id);
      } else {
        setProgramNameList([]);
        setProgramId(null);
        setSelectedProgram("");
        setCategoryExist("false");
        set_ItemList([]);
        setItenListTable([]);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const editException = () => {
    const itemMasterProgram: itemMasterProgramObj = {
      programId: programId,
      itemName: activeRow.itemName,
      ndc: activeRow.ndc,
    };
    handleCloseMenu();
    dispatch(setItemMasterProgram(itemMasterProgram));
    history.push('/item-master/exception');
  }

  const editCategory = () => {
    setModalShow(true);
    handleCloseMenu();
    // setIsMenuOpen(false);
  }

  const onHideModal = () => {
    setModalShow(false);
  }

  const handleExceptionClose = () => {
    setNotInsertedExceptions([]);
    setShowExceptionPop(false);
  }

    const handleExistClose = () =>{
      //setSuccesfullAddition(false);
      setShowPopup(false);
    } 
    

  const globalSearch = (input: string) => {
    const dataListCopy = _.cloneDeep(_reportList);
    setGlobalSearch(input);
    if (input) {
      const list = dataListCopy.filter((data: any) => {
        if ((data.itemName && data.itemName.toLowerCase().includes(input.toLowerCase())) ||
          (data.ndc && data.ndc.toString().includes(input.toLowerCase())) ||
          (data.grx_Temp && data.grx_Temp.toLowerCase().includes(input.toLowerCase())) ||
          (data.ship_Temp && data.ship_Temp.toLowerCase().includes(input.toLowerCase())) ||
          (data.category && data.category.toLowerCase().includes(input.toLowerCase())) ||
          (data.tat && data.tat.toString().includes(input.toLowerCase()))
          ) {
          return data;
        }
      });
      if(list.length == 0){
        setSearchboxError(input + ' does not exist.');
      } else{
        setSearchboxError('');
      }
      setItenListTable(list);
    } else {
      setItenListTable(dataListCopy);
      setSearchboxError('');
    }
  };

  function programOnChange(e:any){
    const selectedIndex = e.target.selectedIndex;
    if (selectedIndex >= 0) {
      const selectedOption = e.target.options[selectedIndex];
      const tempProgramId = selectedOption.getAttribute("data-programid");
      setProgramId(tempProgramId);
      setSelectedProgram(e.target.value);
      setCategoryExist(selectedOption.getAttribute("data-category"));
      fetchItemsMaster(tempProgramId);
    }
  };

  const onSubmitCategory = (payload: any) => {
    updateNDCCategory(payload).then((res) => { 
      fetchItemsMaster(programId);
    }).catch((err) => {
      setGlobalSearch('');
      setSearchboxError('');
      console.log(err)
    });
  };

  return (
    <>
    {isAdmin? 
      (<>
        <ItemMasterCategoryModal
          programid = {programId}
          row = {activeRow}
          show = {modalShow}
          onHide = {onHideModal}
          categoryExist={categoryExist}
          onSubmit = {(payload: any) => {onSubmitCategory(payload)}}
        />
          <div id={styles.department}>
            <div className={styles.filter}>
              <div style={{ display: 'flex', gap: '1rem', flexGrow: '1', flexWrap: 'wrap'}}>
                <div style={{width: '200px', height:'48px'}}>
                  {/* <Form> */}
                  <Form.Select onChange={programOnChange} value={selectedProgram} className={styles.program_select} aria-label="Default select example">
                    {
                      programNameList.length?
                      programNameList.map((program:any)=>{
                        return <option key={program.programName} value={program.programName} data-programid={program.id} data-category={program.category}>{program.programName}</option>
                      }):null
                    }
                  </Form.Select>
                  {/* </Form> */}
                </div>
                <div style={{width: '350px'}}>
                  <InputGroup>
                    <InputGroup.Text className={styles.search_logo}>
                      <img src={require('../../common/images/search.png')} alt="" />
                    </InputGroup.Text>
                    <Form.Control
                      value={globalSearchField}
                      onChange={e =>
                        globalSearch(e.target.value)}
                      className={styles.search_field}
                      type="text"
                      placeholder="Search"
                    />
                  </InputGroup>
                  {
                    searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
                  }
                </div>

              </div>
              <div style={{ display: 'flex', gap: '1rem'}}>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  // container={tooltip}
                  overlay={renderTooltip}
                >
                  <label htmlFor="uploadCSV">
                    <input type="file" accept=".csv" name="uploadCSV" id="uploadCSV" style={{ display: 'none' }} onChange={handleFileUpload}/>
                    {/* <input type="file" accept=".csv" name="uploadCSV" id="uploadCSV" style={{ display: 'none' }}/> */}
                    <img style={{ cursor: 'pointer' }} src={require('../../common/icons/fullUploadIcon.svg')} alt="" />
                  </label>
                </OverlayTrigger>
              </div>
            </div>
            <div className={styles.table}>
              <BootstrapTable
                bootstrap5
                bordered={false}
                trClassName={styles.customRow}
                keyField="id"
                data={reportList}
                columns={columns}
                headerClasses={styles.fixed_header}
              />
            </div>
          </div>
          {isMenuOpen && activeRow && (
            <div
              className={`${styles.category_custom_menu} category_custom_menu`}
              style={{
                position: 'absolute',
                top: menuPosition.top,
                left: menuPosition.left,
                zIndex: '100',
              }}
            >
              {/* Custom menu content */}
              <div className={styles.custom_menu_item} onClick={editException}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={require('../../common/images/edit-primary.png')}
                  alt=""
                />
                <span style={{marginLeft: '6px'}}>Exception</span>
              </div>
              <div className={styles.custom_menu_item} onClick={editCategory}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={require('../../common/images/edit-primary.png')}
                  alt=""
                />
                <span style={{marginLeft: '6px'}}>NDC</span>
              </div>
             
            </div>
          )}

          <BulkUploadModal show={showExceptionPop} onHide={handleExceptionClose} data={notInsertedExceptions} type={bulkUploadType}/>

          <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showPopup} onHide={handleExistClose}>
            <Modal.Header closeButton>
              <Modal.Title>Item Master {bulkUploadType==1?'Exceptions':''}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modal_body}>
              <div className={styles.modal_body}>
                <div>
                  {
                    succesfullAddition ? <img className={styles.success_img} src={require('../../common/images/success.png')} alt="" />:
                    <img src={require('../../common/icons/warning.svg')} alt="" />
                  }
                  
                </div>
                <div className={styles.modal_text}>
                {succesfullAddition ? `All Item Master ${bulkUploadType==1?'Exceptions':''} are added successfully` :"OOPS! \n Please check your csv file structure and try again"}
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer bsPrefix={globalStyle.footerDelete}>
              <div>
                <Button onClick={handleExistClose} className={globalStyle.footerAdd_btn} variant="primary">OK</Button>
              </div>
            </Modal.Footer>
          </Modal>
          
          <Modal contentClassName={styles.modal_content1} show={showLoader}>
            <Modal.Body className={styles.modal_body}>
              <div className="spinner" />
            </Modal.Body>
          </Modal>
      </>):(<></>)}
    </>
  );
}

export default ItemMaster;
