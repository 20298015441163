import { HttpMethod } from '@enums';
import { Setting, Reports } from '@http/api';
import { httpRequest } from '@utils';

export const getReportList = (role:string|null): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(role){
    role = role.replace(' ', '_');
  }
  if(account && account.localAccountId && role){
    return httpRequest({
      url: `${Setting.DEPARTMENTREPORT}/getReportList/${account.localAccountId}/${role}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login or role not found');
  });
};

export const getDepartment = (): Promise<any> =>
  httpRequest({
    url: `${Setting.REPORTDROPDOWN}/getDepartmentIdName`,
    method: HttpMethod.GET,
  });

export const getClientProgram = (): Promise<any> =>
  httpRequest({
    url: `${Setting.REPORTDROPDOWN}/getClientProgram`,
    method: HttpMethod.GET,
  });

export const getWorkspace = (): Promise<any> =>
  httpRequest({
    url: `${Reports.POWERBI}/getAllWorkspaces`,
    method: HttpMethod.GET,
  });

export const getReport = (data:any): Promise<any> =>
  httpRequest({
    url: `${Reports.POWERBI}/getAllReports`,
    method: HttpMethod.POST,
    data,
  });



export const addReport = (data: any): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENTREPORT}/postDepartmentReport`,
    method: HttpMethod.POST,
    data,
  });



export const editReport = (data: any, id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENTREPORT}/updateDepartmentReport/${id}`,
    method: HttpMethod.PUT,
    data,
  });

export const deleteReport = (id: string): Promise<any> =>
  httpRequest({
    url: `${Setting.DEPARTMENTREPORT}/deleteDepartmentReport/${id}`,
    method: HttpMethod.DELETE,
  });

