

import {
  ReactChild, ReactFragment, ReactPortal, useEffect, useReducer, useState
} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { getLoginDetails, getReportEmbedUrl } from '../../http/requests';

import styles from './header.scss';

const USER = {
  name: '',
  avatar: '',
};

const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'Set_User':
      return action.payload;
    default:
      return state;
  }
};

export const Header = (props: { heading: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
  const location = useLocation();
  const history = useHistory();
  const [ user, userDispatch ] = useReducer(userReducer, USER);
  const [ reportDetail, setReportDetail ] = useState({
    embedUrl: '',
    reportId: '',
    reportName: '',
  });
  useEffect(() => {
    const path = location.pathname.split('/');
    if (path[1] == 'report' && path[2]) {
      getReportEmbedUrl(+path[2]).then((res) => {
        const data = res.embedReports;

        setReportDetail(data[0]);
      })
        .catch((err) => {
          console.log(err);
        });
    }
    getLoginDetails().then((res: any) => {
      const last = res.lastName ? res.lastName : '';
      userDispatch({
        type: 'Set_User',
        payload: {
          name: res.firstName + ' ' + last,
          avatar: res.photo,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const [ accordionOpen, setAccordionOpen ] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[] | null | undefined>(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (accordionOpen && !e.target.closest('.avatar')) {
        setAccordionOpen(false);
        setActiveKey(null);
      }
    };
    if(accordionOpen) document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [accordionOpen]);

  const openAccordion = () => {
    setAccordionOpen(p =>
      !p);
  };

  const logoutAction = () => {
    history.push("/logout");
  }

  const backToReports = ()=>{
    history.goBack();
  };
  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          {
            reportDetail.reportName ? (
              <div>
                <div className={styles.report_section}>
                  <div style={{ cursor:'pointer' }} onClick={backToReports}>
                 
                    <p className={styles.back}> <img src={require('../../common/icons/back-black.svg')} alt="" /> &nbsp;Back</p>
                  </div>
                  <div>
                    <p className={styles.heading}>{reportDetail.reportName}
                    </p>
                  </div>
                </div>
              </div>
            )
            : <h2 className={styles.heading}>{props.heading}</h2>
          }
          
        </div>
        <div className={`${styles.avatar}`}>
          <Accordion style={{ height: '60px' }} onSelect={(eventKey) => setActiveKey(eventKey === activeKey ? null : eventKey)} activeKey={activeKey} className={accordionOpen ? `${styles.accordion_open} avatar` : `${styles.accordion} avatar`} flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix={styles.head} onClick={openAccordion}>
                <div>
                  {
                    user.avatar ? <img className={styles.avatar_img} src={'data:image/png;base64,' + user.avatar} alt="" /> :
                    <img src={require('../../common/icons/avatar.svg')} alt="" />
                  }
                </div> &nbsp;&nbsp;
                <div>
                  <span style={{ fontSize: '12px' }}>{user.name}</span>
                </div> &nbsp;&nbsp;
                <div>{accordionOpen ? <img className={styles.arrow_down} src={require('../../common/icons/arrow-up.svg')} alt="" /> :
                <img className={styles.arrow_down} src={require('../../common/icons/arrow-downn.svg')} alt="" />}</div>
              </Accordion.Header>
              <Accordion.Body style={{padding: '0px'}}>
                <div onClick={logoutAction} className={styles.logout}>Logout</div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};
