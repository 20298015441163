const initialState = { announcment: '' };

const announcmentReducer = (state:any = initialState, action:any)=>{
  switch(action.type){
    case 'SET_ANNOUNCMENT':
      return {
        ...state,
        announcment: action.payload,
      };
    default:
      return state;
  }
};

export default announcmentReducer;
