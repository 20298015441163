import { HttpMethod } from '@enums';
import { BASE_URL } from '@http/api';
import { httpRequest } from '@utils';

export const getDashboardSections = (): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));
  
  if(account && account.localAccountId){
    return httpRequest({
      url: `${BASE_URL}/Dashboard/getDashboardSections/${account.localAccountId}`,
      method: HttpMethod.GET,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login');
  });
};

export const getSectionEmbedUrls = (id:any): Promise<any> => {
  const account = JSON.parse(localStorage.getItem('account'));

  if(account && account.localAccountId){
    return httpRequest({
      url: `${BASE_URL}/Dashboard/${id}/getSectionEmbedUrl/${account.localAccountId}`,
      method: HttpMethod.POST,
    });
  }
  return new Promise((res, rej)=>{
    rej('not login');
  });
};

export const unPinSectionReport = (id:any): Promise<any> => 
  httpRequest({
    url: `${BASE_URL}/Dashboard/unpinSectionReport/${id}`,
    method: HttpMethod.POST,
  });
