// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".powerbi--O36kEAb4{margin-top:20px;height:80vh;width:86%;z-index:50}.moo--AEb3w\\+DR{margin:auto !important;top:30% !important;width:514px !important;height:300px !important;background:#fff !important;box-shadow:0px 4px 27px rgba(7,48,68,.2) !important;border-radius:6px !important}.modal_content--7JRHC04t{height:100% !important}.modal_body--bapZQjIj{display:flex;flex-direction:column;justify-content:center;align-items:center}.modal_text--KoEgVHXa{font-weight:500;font-size:16px;line-height:20px;text-align:center;color:#1b212d}", "",{"version":3,"sources":["webpack://./src/pages/reports/reports.scss"],"names":[],"mappings":"AAAA,mBACI,eAAA,CACA,WAAA,CACA,SAAA,CACA,UAAA,CAGJ,gBACI,sBAAA,CAGA,kBAAA,CACA,sBAAA,CACA,uBAAA,CACA,0BAAA,CACA,mDAAA,CACA,4BAAA,CAGJ,yBACI,sBAAA,CAIJ,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":[".powerbi{\r\n    margin-top: 20px;\r\n    height: 80vh;\r\n    width: 86%;\r\n    z-index: 50;\r\n}\r\n\r\n.moo{\r\n    margin: auto !important;\r\n    // right: 30% !important;\r\n    // position: absolute !important;\r\n    top: 30% !important;\r\n    width: 514px !important;\r\n    height: 300px !important;\r\n    background: #FFFFFF !important;\r\n    box-shadow: 0px 4px 27px rgb(7 48 68 / 20%) !important;\r\n    border-radius: 6px !important;\r\n}\r\n\r\n.modal_content{\r\n    height: 100% !important;\r\n}\r\n\r\n\r\n.modal_body{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.modal_text{\r\n    font-weight: 500;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    text-align: center;\r\n    color: #1B212D;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"powerbi": "powerbi--O36kEAb4",
	"moo": "moo--AEb3w+DR",
	"modal_content": "modal_content--7JRHC04t",
	"modal_body": "modal_body--bapZQjIj",
	"modal_text": "modal_text--KoEgVHXa"
};
export default ___CSS_LOADER_EXPORT___;
