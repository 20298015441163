import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Form, Button, InputGroup, Modal
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { ReportModal } from '@components/modals/report-modal';
import { USER_ROLE } from '@constants';

import {
  getReportList, addReport,
  editReport, deleteReport,
  getActiveUsersList, genericRecordStatus
} from '../../../http/requests';

import styles from './report.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';
import { RootState } from '@redux/reducers';
import { useSelector } from 'react-redux';

function Report({ activeTab, roleChange }) {
  const [ modalShow, setModalShow ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ departmentList, setDepartmentList ] = useState([]);
  const [ _departmentList, set_departmentList ] = useState([]);
  const [ show, setShow ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showSuccessMsg, setShowSuccessMsg ] = useState('');
  const [ deleteId, setDeleteId ] = useState('');
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ searchboxError, setSearchboxError ] = useState('');
  const [ globalSearchField, setGlobalSearch ] = useState('');
  const [ showUpdatePopup, setShowUpdatePopup ] = useState(false);
  const [ updateData, setupdateData ] = useState(null);

  const role: string|null = useSelector(({ USER }: RootState) =>
    USER.userRole);

  useEffect(() => {
      //const role: string = localStorage.getItem(USER_ROLE) || 'User';
      
      setIsAdmin((role == 'Super Admin') ? true : false);
      if(activeTab==='report'){
        fetchDepartmentList();
      }
      
  }, [activeTab, roleChange]);

  useEffect(()=>{
    fetchDepartmentList();
  },[]);

  useEffect(()=>{
    if(globalSearchField){
      globalSearch(globalSearchField);
    }
  },[_departmentList]);

  useEffect(()=>{
    if(activeTab != 'report'){
      setGlobalSearch('');
      setSearchboxError('');
      globalSearch('');
    }
  }, [ activeTab ]);

  const customTotal = (from: any, to: any, size: any) =>
    (
      <span style={{ marginLeft: '20px' }} className="react-bootstrap-table-pagination-total">
        {from} - {to} of {size}
      </span>
    );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }: {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any
  }) =>
    (
      <span className={styles.listPage}>
        <span>Rows per page &nbsp;&nbsp;
          {
          (
            <Form.Select
              className={styles.perPageSelect}
              onChange={e =>
                onSizePerPageChange(e.target.value)}
              aria-label="Default select example"
            >
              {
                options.map((val: any) =>
                  <option key={val.text} value={val.page}>{val.text}</option>)
              }
            </Form.Select>
          )
        }

        </span>
      </span>
    );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always deleteId next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    // firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    // lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    // pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageRenderer,
    sizePerPageList: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 } ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const isEnable = (row: any) => {
    const style: any = {};
    if (!row.active) {
      style.pointerEvents = 'none';
    }
    return style;
  };

  const linkFollow = (cell: any, row: any, rowIndex: any, formatExtraData: any) =>
    (
      <div className={isAdmin ? styles.table_actions : styles.table_actions2}>
        <div
          style={isEnable(row)}
          onClick={() =>
          view(row)}
        >
          {row.active ? <img src={require('../../../common/icons/view.svg')} alt="" /> :
          <img src={require('../../../common/icons/view-dull.svg')} alt="" />}
        </div>
        {
        isAdmin ? (
          <>
            <div
              style={isEnable(row)}
              onClick={() =>
                edit(row)}
            >
              {row.active ? <img src={require('../../../common/icons/pencil.svg')} alt="" /> :
              <img src={require('../../../common/icons/edit-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
                copyDepartment(row)}
            >
              {row.active ? <img src={require('../../../common/icons/copy.svg')} alt="" /> :
              <img src={require('../../../common/icons/copy-dull.svg')} alt="" />}
            </div>
            <div
              style={isEnable(row)}
              onClick={() =>
                deleteClientItem(row.departmentID)}
            >
              {row.active ? <img src={require('../../../common/icons/delete.svg')} alt="" /> :
              <img src={require('../../../common/icons/delete-dull.svg')} alt="" />}
            </div>
            <div>
              <Form.Check
                className={styles.table_actions_switch}
                checked={row.active}
                type="switch"
                id="custom-switch"
                onChange={e =>
                  activeDepartment(e.target.checked, row)}
              />
            </div>
          </>
        ) : null
      }
      </div>
    );

  const columns = [
    {
      dataField: 'reportName',
      text: 'REPORT NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'departmentName',
      text: 'DEPARTMENT',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '9rem' },
    },
    {
      dataField: 'workspaceName',
      text: 'WORKSPACE NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      style: { width: '12rem' },
    },
    {
      dataField: 'clientNames',
      text: 'CLIENT NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      formatter: (cell, row) =>(
        <>
          {row.clientNames.map((nameObj, index) => (
            <span
              key={index}
              style={{ color: nameObj.clientActive ? '' : 'rgba(114, 114, 114, 0.3)' }}
            >
              {nameObj.clientName}
              {index < row.clientNames.length - 1 && ', '}
            </span>
          ))}
        </>

      ),
      style: { width: '12rem' },
    },
    {
      dataField: 'programNames',
      text: 'PROGRAM NAME',
      sort: true,
      sortCaret: (order: any, column: any) =>
        (
          <span> &nbsp;
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_asc.png')} alt="" />
            <img style={{ cursor: 'pointer' }} src={require('../../../common/images/sort_desc.png')} alt="" />
          </span>
        ),
      formatter: (cell, row) =>(
        <>
          {row.programNames.map((nameObj, index) => (
            <span
              key={index}
              style={{ color: nameObj.programActive ? '' : 'rgba(114, 114, 114, 0.3)' }}
            >
              {nameObj.programName}
              {index < row.programNames.length - 1 && ', '}
            </span>
          ))}
        </>
      ),
      style: { width: '12rem' },
    },
    {
      dataField: 'action',
      text: 'ACTION',
      formatter: linkFollow,
      sort: true,
      headerAlign: 'center',
      style: { width: '10rem' },
    },
  ];

  const rowStyle2 = (row: any, rowIndex: any) => {
    const style: any = {};
    if (!row.active) {
      style.color = 'rgba(114, 114, 114, 0.3)';
      //   style.pointerEvents = 'none'
    }

    return style;
  };

  const fetchDepartmentList = () => {
    getReportList(role).then(((res: any) => {
      const data: any = [];
      res.forEach((r: any, idx: any) => {
        const reportName: any = [];
        let workspaceName: any = [];
        const reportId: any = [];
        let workspaceId: any = [];
        r.reportList.forEach((d: any) => {
          reportName.push(d.reportName);
          workspaceName.push(d.workspaceName);
          reportId.push(d.reportID);
          workspaceId.push(d.workspaceID);
        });
        const clientId: any = [];
        const clientNames: any = [];
        const clientName: any = [];
        const programId: any = [];
        const programNames: any = [];
        const programName: any = [];
        r.clientProgramResponses.forEach((d: any) => {
          clientId.push(d.clientID);
          clientName.push(d.clientName);
          clientNames.push({'clientName':d.clientName,'clientActive':d.clientActive});
          programId.push(d.programID);
          programName.push(d.programName);
          programNames.push({'programName':d.programName,'programActive':d.programActive});
        });
        workspaceId = workspaceId.filter(function (item: any, pos: any) {
          return workspaceId.indexOf(item) == pos;
        });
        workspaceName = workspaceName.filter(function (item: any, pos: any) {
          return workspaceName.indexOf(item) == pos;
        });
        data.push({
          ...r,
          id: idx,
          reportName: reportName.join(', '),
          workspaceName: workspaceName.join(', '),
          reportId: reportId.join(', '),
          workspaceId: workspaceId.join(', '),
          clientId: clientId.join(', '),
          clientNames: clientNames,
          clientName: clientName.join(', '),
          programId: programId.join(', '),
          programName: programName.join(', '),
          programNames: programNames,
        });
      });
      set_departmentList(_.cloneDeep(data));
      setDepartmentList(_.cloneDeep(data));
    })).catch((err)=>{
      console.log(err);
    });
  };

  const submit = (data: any) => {
    setModalShow(false);
    if (data.type == 'add' || data.type == 'copy') {
      setGlobalSearch('');
      setSearchboxError('');
      addReport(data.modalData).then((res) => {
        setShowSuccess(true);
        data.type == 'add' ? setShowSuccessMsg('Report Created Successfully') : setShowSuccessMsg('Report Copied Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    } else if (data.type == 'update') {
      setupdateData(data);
      setShowUpdatePopup(true);
    }
  };

  const view = (currentRow: any) => {
    setModalData({
      header: 'View Report',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const activeDepartment = (value: any, department: any) => {
    const data = {
      recordType: 'DepartmentReport',
      status: value,
    };
    genericRecordStatus(department.departmentID, data).then(() => {
      getReportList(role).then(((res: any) => {
        const data: any = [];
        res.forEach((r: any, idx: any) => {
          const reportName: any = [];
          let workspaceName: any = [];
          const reportId: any = [];
          let workspaceId: any = [];
          r.reportList.forEach((d: any) => {
            reportName.push(d.reportName);
            workspaceName.push(d.workspaceName);
            reportId.push(d.reportID);
            workspaceId.push(d.workspaceID);
          });
          const clientId: any = [];
          const clientNames: any = [];
          const clientName: any = [];
          const programId: any = [];
          const programNames: any = [];
          const programName: any = [];
          r.clientProgramResponses.forEach((d: any) => {
            clientId.push(d.clientID);
            clientName.push(d.clientName);
            clientNames.push({'clientName':d.clientName,'clientActive':d.clientActive});
            programId.push(d.programID);
            programName.push(d.programName);
            programNames.push({'programName':d.programName,'programActive':d.programActive});
          });
          workspaceId = workspaceId.filter(function (item: any, pos: any) {
            return workspaceId.indexOf(item) == pos;
          });
          workspaceName = workspaceName.filter(function (item: any, pos: any) {
            return workspaceName.indexOf(item) == pos;
          });
          data.push({
            ...r,
            id: idx,
            reportName: reportName.join(', '),
            workspaceName: workspaceName.join(', '),
            reportId: reportId.join(', '),
            workspaceId: workspaceId.join(', '),
            clientId: clientId.join(', '),
            clientNames: clientNames,
            clientName: clientName.join(', '),
            programId: programId.join(', '),
            programName: programName.join(', '),
            programNames: programNames,
          });
        });
  
        set_departmentList(_.cloneDeep(data));
        setDepartmentList(_.cloneDeep(data));
        
      })).catch((err)=>{
        console.log(err);
      });
    })
    .catch((error) => {
      // Handle the error here
      fetchDepartmentList();
    });
  };

  const copyDepartment = (currentRow: any) => {
    setModalData({
      header: 'Copy Report',
      currentRow,
      submit,
    });
    setModalShow(true);
  };
  const add = () => {
    setModalData({
      header: 'Add Report',
      submit,
    });
    setModalShow(true);
  };
  const edit = (currentRow: any) => {
    setModalData({
      header: 'Edit Report',
      currentRow,
      submit,
    });
    setModalShow(true);
  };

  const deleteClientItem = (id: string) => {
    setDeleteId(id);
    setShow(true);
  };

  const deleteClientById = () => {
    if (deleteId) {
      setShow(false);
      setGlobalSearch('');
      setSearchboxError('');
      deleteReport(deleteId).then((res) => {
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }
  };

  const globalSearch = (input: string) => {
    setGlobalSearch(input);
    const departmentListCopy = _.cloneDeep(_departmentList);

    if (input) {
      const list = departmentListCopy.filter((report: any) => {
        if (report.clientName.toLowerCase().includes(input.toLowerCase()) ||
          report.reportId.toLowerCase().includes(input.toLowerCase()) ||
          report.reportName.toLowerCase().includes(input.toLowerCase()) ||
          report.workspaceName.toLowerCase().includes(input.toLowerCase()) ||
          report.workspaceId.toLowerCase().includes(input.toLowerCase()) ||
          report.departmentName.toLowerCase().includes(input.toLowerCase()) ||
          report.programName.toLowerCase().includes(input.toLowerCase())) {
          return report;
        }
      });      
      if(list.length == 0){
        setSearchboxError(input + ' does not exist.');
      } else{
        setSearchboxError('');
      }
      setDepartmentList(list);
    } else {
      setSearchboxError('');
      setDepartmentList(departmentListCopy);
    }
  };
  const updateOnPopup = ()=>{
    setShowUpdatePopup(false);
    if(updateData && updateData.id){
      setGlobalSearch('');
      setSearchboxError('');
      editReport(updateData.modalData, updateData.id).then((res) => {
        setShowSuccess(true);
        setShowSuccessMsg('Report Updated Successfully');
        setTimeout(() => {
          setShowSuccess(false);
          setShowSuccessMsg('');
          // window.location.reload();
        }, 1000);
        fetchDepartmentList();
      })
      .catch((error) => {
        // Handle the error here
        fetchDepartmentList();
      });
    }    
  };

  const handleClose = () =>
    setShow(false);
  const handleSuccessClose = () =>
    setShowSuccess(false);
  const handleUpdateClose = () => 
    setShowUpdatePopup(false);

  return (
    <>
      <div id={styles.department}>
        <ReportModal
          data={modalData}
          show={modalShow}
          onHide={() =>
            setModalShow(false)}
        />
        <div className={styles.filter}>
          <div className={styles.search}>
            <InputGroup>
              <InputGroup.Text className={styles.search_logo}>
                <img src={require('../../../common/images/search.png')} alt="" />
              </InputGroup.Text>
              <Form.Control
                value={globalSearchField}
                id="global-search"
                onChange={e =>
                  globalSearch(e.target.value)}
                className={styles.search_field}
                type="text"
                placeholder="Search by Report Name, Program Name, Workspace Name, Department"
              />
            </InputGroup>
            {
              searchboxError ? <span className={styles.searchboxError}>{searchboxError}</span> : null
            }
            {/* <Form.Control type="text" placeholder="Enter email" /> */}
          </div>
          <div className={styles.buttons}>
           
            {
              isAdmin ?
                <Button onClick={add} className={styles.add_btn} variant="primary">Add</Button>
                : null
            }
          </div>
        </div>
        <div className={styles.table}>
          <BootstrapTable
            rowStyle={rowStyle2}
            bootstrap5
            bordered={false}
            trClassName={styles.customRow}
            keyField="id"
            data={departmentList}
            columns={columns}
            pagination={paginationFactory(options)}
          />
        </div>
      </div>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will delete the Report from the application.
            </div>
            <div className={styles.modal_text}>
              Do you want to proceed
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={deleteClientById} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showUpdatePopup} onHide={handleUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              This will update the report. Are you sure you want to save the new changes ?
            </div>
           
          </div>
        </Modal.Body>

        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={handleUpdateClose} className={globalStyle.footerCancel} variant="secondary">No</Button>
          </div>
          <div>
            <Button onClick={updateOnPopup} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showSuccess} onHide={handleSuccessClose}>

        <Modal.Body className={styles.modal_body}>
          <div>
            <img className={styles.success_img} src={require('../../../common/images/success.png')} alt="" />
          </div>
          <br />
          <div >
            <h3>{showSuccessMsg}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Report;
