import { Button, Modal } from "react-bootstrap";
import styles from './confirmation-modal.scss';
import globalStyle from '../../../common/styles/globalStyle.scss';


//title
//show
//onhide
//message
//submit

export const ConfirmationModal = (props: any)=> {
    
  return (
    <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title?props.title:'Confirmation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <div className={styles.modal_body}>
            <div>
              <img src={require('../../../common/icons/warning.svg')} alt="" />
            </div>
            <div className={styles.modal_text}>
              Are you sure
            </div>
            <div className={styles.modal_message}>
              {props.message}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer bsPrefix={globalStyle.footerDelete}>
          <div>
            <Button onClick={props.onHide} className={globalStyle.footerCancel} variant="secondary">Cancel</Button>
          </div>
          <div>
            <Button onClick={props.submit} className={globalStyle.footerAdd_btn} variant="primary">
              Yes
            </Button>
          </div>
        </Modal.Footer>
    </Modal>
  );
}
