import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Modal
} from 'react-bootstrap';

import { getPowerBiReport, getReportEmbedUrl } from '@http/requests';
import globalStyle from '../../common/styles/globalStyle.scss';

import styles from './reports.scss';


export const Report = () => {
  const [ config, setConfig ] = useState({});
  const [ loading, setLoading ] = useState(true);
  const { reportId } = useParams();
  const [ showExist, setShowExist ] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const history = useHistory();

  useEffect(() => {
    getReportEmbedUrl(reportId).then((res)=>{
      setConfig({
        type: 'report', // Supported types: report, dashboard, tile, visual and qna
        id: res.embedReports[0].reportId, 
        embedUrl: res.embedReports[0].embedUrl,
        accessToken: res.embedToken.token, // Keep as empty string, null or undefined
        tokenType: models.TokenType.Embed,
      });
      setLoading(false);
    })
      .catch((err)=>{
      console.log(err);
      if (err.data.errorMessage === "Permission Denied") {
        setShowMsg("Access Denied");
      }
      else{
        setShowMsg("Something went wrong!");
      }
      setShowExist(true);
      });
  }, []);

  const handleExistClose = () =>{
    setShowExist(false);
    history.push('/reports');
  }

  return (
    <>
      {
        !loading ? (
          <div>
            <PowerBIEmbed
              embedConfig = {config}
              cssClassName = { styles.powerbi }
            />
          </div>
        )
          : <div>
             <Modal contentClassName={styles.modal_content} dialogClassName={styles.moo} show={showExist} onHide={handleExistClose}>
              <Modal.Header closeButton>
                <Modal.Title>Report</Modal.Title>
              </Modal.Header>
              <Modal.Body className={styles.modal_body}>
                <div className={styles.modal_body}>
                  <div>
                    <img src={require('../../common/icons/warning.svg')} alt="" />
                  </div>
                  <div className={styles.modal_text}>
                  {showMsg}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer bsPrefix={globalStyle.footerDelete}>
                <div>
                  <Button onClick={handleExistClose} className={globalStyle.footerAdd_btn} variant="primary">OK</Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
      }
      
    </>
  );
};
  
