import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Login } from '@components/login';
import { CollaborationViewRoutes } from '@enums';
import { Dashboard } from '@pages/dashboard/dashboard';
import Logout from '@pages/Logout/Logout';
import Reports from '@pages/reports-section/reports';
import { Report } from '@pages/reports/reports';
import ItemMaster from '@pages/item-master/item-master';
import ItemMasterException from '@pages/exception/exception';
import PharmacyHolidays from '@pages/pharmacy-holidays/pharmacy-holidays';
import { Settings } from '@pages/settings/settings';
import { Exception } from '@microsoft/applicationinsights-web';
import SFTP from '@pages/sftp/sftp';

export type AuthRoute = {
  path: string;
  Component: FC<RouteComponentProps>;
  name: string
};

export const cvRoutes: AuthRoute[] = [
  {
    path: '/',
    Component: Dashboard,
    name: 'Dashboard',
  },
  {
    path: '/dashboard/:sectionID',
    Component: Dashboard,
    name: 'Dashboard',
  },
  {
    path: '/report/:reportId',
    Component: Report,
    name: 'Report',
  }, 
  {
    path: '/reports',
    Component: Reports,
    name: 'Reports',
  },
  {
    path: '/item-master',
    Component: ItemMaster,
    name: 'Item Master',
  },
  {
    path: '/automated-file-transfer',
    Component: SFTP,
    name: 'Automated File Transfer',
  },
  {
    path: '/item-master/exception',
    Component: ItemMasterException,
    name: 'Configure Shipping Temperature',
  },
  {
    path: '/pharmacy-holidays',
    Component: PharmacyHolidays,
    name: 'Pharmacy Holidays',
  }, 
  {
    path: '/settings',
    Component: Settings,
    name: 'Settings',
  },
  {
    path: '/logout',
    Component: Logout,
    name: '',
  },
  {
    path: '/login',
    Component: Login,
    name: '',
  },
];
