import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Announcement } from '@components/announcement';
import { useAzureADAuth } from '@hooks';
import { clearAllCookies, isCookiePresent } from '@utils'
import { AppDispatch } from '@redux/store';
import { initiateLogin } from '@redux/actions';

import styles from './login.scss';

export const Login = () => {
  const { isAuthenticated, loginAzureADRedirect, loginAzureADPopup, inProgress } = useAzureADAuth();
  const [ show, setShow ] = useState(false);
  const [ role, setRole ] = useState('');
  const [ showMsg, setShowMsg ] = useState('');
  const store = useSelector(USER=>
    USER);
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const clearStorage = (path: any) => {
      clearAllCookies();
      sessionStorage.clear();
      localStorage.clear();
      if(path[1]) history.push('/');
      window.location.reload();
  }
  const loginUser = () => {
    dispatch(initiateLogin(true));
    const path = location.pathname.split('/');
    if(path[1] || isCookiePresent('msal.interaction.status')){
      clearStorage(path);
    } else {
      if(localStorage.getItem('signUp')) localStorage.removeItem('signUp');
      loginAzureADRedirect();
    }
  }; 
  const registerUser = () => {
    dispatch(initiateLogin(true));
    const path = location.pathname.split('/');
    if(path[1] || isCookiePresent('msal.interaction.status')){
      clearStorage(path);
    } else {
      localStorage.setItem('signUp', 'true');
      loginAzureADRedirect();
    }
  }

  useEffect(()=>{
    if(store.USER.userRole=='true') dispatch(initiateLogin(false));
    setTimeout(()=>{
      setShow(true);
    }, 2000);
    return ()=>{
      setShowMsg('');
    };
  }, []);
  useEffect(()=>{
    if(location.pathname.split('/')[1] == 'logout'){
      history.push('/');
    }
    
    const role = store.USER.userRole;
    if(role && role == 'NA'){
      setShowMsg('Please contact Admin...');
      setRole(role);
      history.push('/');
    } else if(localStorage.getItem('PortalLogout')){
      setShowMsg('Please contact Admin...');
      localStorage.removeItem('PortalLogout');
    } else if(!role){
      setShowMsg('');
    }
  }, [ store.USER.userRole ]);

  const handleBackClick = () => {
    setShowMsg('');
  }

  if(show){
    return (
      <>
        <Announcement charLength={130} />
        <div className={styles.app}>
          <div className={styles.appHeader}>
            <div className={styles.logo}>
              <img src={require('../../common/icons/Medvantx_Logo_DarkNavy.svg')} alt="" />
            </div >        
           
            <div className={styles.loginRegister}>
              <span onClick={registerUser} className={`${styles.registerButton} ${store.USER.isLoginInitiated ? styles.disabled : ''}`}><strong>Register</strong></span>
              <button onClick={loginUser} className={`${styles.loginButton} ${store.USER.isLoginInitiated ? styles.disabled : ''}`}>Login</button>
            </div>
          </div>
          
          <div className={styles.mainContent}>
            <div className={styles.welcomeSection}>
              <div className={styles.welcome1}>
                <div>Welcome to Medvantx Insights</div>
                <p>Our platform offers users access to integrate and normalize data from a variety of sources then apply our algorithms to generate clinical patient access data.</p>
                <p>New to Medvantx? <span className={`${styles.regiterLink} ${store.USER.isLoginInitiated ? styles.disabled : ''}`} onClick={registerUser}> Register</span></p>
                <div>
                <button onClick={loginUser} className={`${styles.loginButton} ${store.USER.isLoginInitiated ? styles.disabled : ''}`} style={{margin:0}}>Login</button>
                </div>
              </div>
              <div className={styles.welcome2}>
              </div>
            </div>
            
            <div className={styles.descriptionSection}>
              { showMsg?
                <>
                  <div className={styles.noPermission}>
                    <div>Access Denied</div>
                    <p>Please contact your Medvantx admin (InsightsSupport@medvantx.com) to assign permissions to your account</p>
                  </div>
                </>
                :
                <>
                  <div>Why Medvantx as Your Analytics Partner?</div>
                  <p>Our INSIGHTS Platform's Analytics Module enables trending, monitoring, and reporting on various program aspects such as inventory (qty received, on-hand, and reconciliation if applicable), enrollment activity (applications received/approved/denied, reasons for denial), active patients (new/continuing), fulfillment (shipments and returns), 
                    call center SLAs, compliance (adverse events (AE), product quality complaints (PQC)), and key financial data.</p>
                  <p>The platform enhances scalability and consistency in delivering personalized care by leveraging data analytics to gain insights into patient needs and medication trends, ensuring on-time medication delivery.</p>
                </>
              }
          
            </div>
          </div>
          
          <div className={styles.appFooter}>
            <div className={styles.footerLogo}>
              <img src={require('../../common/icons/Medvantx_Logo_DarkNavy.svg')} alt="" />
              <div><strong>Advancing Access to Healthcare</strong></div>
            </div > 
            <div className={styles.contact}>
              <div className={styles.socialLink}><a className={styles.link} href="https://medvantx.com/careers/" target="_blank">Careers</a></div>
              <div className={styles.socialLink}><a className={styles.link} href="https://medvantx.com/terms-of-use/" target="_blank">Terms of Use</a></div>
              <div className={styles.socialLink}><a className={styles.link} href="https://medvantx.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
            </div>
            <div className={styles.social}>
              <div className={styles.socialLink}><a className={styles.link} href="https://www.linkedin.com/company/medvantxinc/" target="_blank">LinkedIn</a></div>
              <div className={styles.socialLink}><a className={styles.link} href="https://www.instagram.com/medvantx/" target="_blank">Instagram</a></div>
              <div className={styles.socialLink}><a className={styles.link} href="https://twitter.com/medvantx" target="_blank">Twitter</a></div>
            </div>
            <div  className={styles.outerCopyright}> <div className={styles.innerCopyright}> &#169; 2024 Medvantx</div></div>
          </div>
      </div>
      </>
    );
  } else{
    return null;
  }
};
