// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_content--y1aLm68P{height:100% !important}.modal_body--u3kCFD66{display:flex;flex-direction:column;justify-content:center;align-items:center}.modal_text--wX0yNzx2{font-weight:700;font-size:16px;line-height:20px;text-align:center;color:#1b212d}.modal_message--q-AcNFrS{font-weight:500;font-size:16px;line-height:20px;text-align:center;color:#1b212d}.moo--QdTea8ea{margin:auto !important;top:30% !important;width:514px !important;height:300px !important;background:#fff !important;box-shadow:0px 4px 27px rgba(7,48,68,.2) !important;border-radius:6px !important}", "",{"version":3,"sources":["webpack://./src/components/modals/confirmation-modal/confirmation-modal.scss"],"names":[],"mappings":"AAAA,yBACI,sBAAA,CAIJ,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAIJ,sBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA,CAGJ,yBACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA,CAGJ,eACI,sBAAA,CACA,kBAAA,CACA,sBAAA,CACA,uBAAA,CACA,0BAAA,CACA,mDAAA,CACA,4BAAA","sourcesContent":[".modal_content{\n    height: 100% !important;\n}\n\n\n.modal_body{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.modal_text{\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 20px;\n    text-align: center;\n    color: #1B212D;\n}\n\n.modal_message{\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 20px;\n    text-align: center;\n    color: #1B212D;\n}\n\n.moo{\n    margin: auto !important;\n    top: 30% !important;\n    width: 514px !important;\n    height: 300px !important;\n    background: #FFFFFF !important;\n    box-shadow: 0px 4px 27px rgb(7 48 68 / 20%) !important;\n    border-radius: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_content": "modal_content--y1aLm68P",
	"modal_body": "modal_body--u3kCFD66",
	"modal_text": "modal_text--wX0yNzx2",
	"modal_message": "modal_message--q-AcNFrS",
	"moo": "moo--QdTea8ea"
};
export default ___CSS_LOADER_EXPORT___;
